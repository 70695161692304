import React, {useEffect, useRef} from 'react';
import DMNLogic from "./DMN.logic";
import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import ActionButtons from "./SubComponent/ActionButtons";
import {styles} from "../components/style";

export const DMN = () => {
    const {
        mainContainerRef,
        propertiesContainerRef,
        deploy,
        loadDecisionDefinitionByKey,
    } = DMNLogic(undefined, undefined);

    return (
        <>
            <ActionButtons
                deploy={deploy}
                loadDecisionDefinitionByKey={loadDecisionDefinitionByKey}
            />
            <div className={"modeler"} style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <div style={styles.mainContainer} ref={mainContainerRef}/>
                <div style={styles.panelProperties} ref={propertiesContainerRef}/>
            </div>
        </>
    )
}
export default DMN;