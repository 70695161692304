import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import { numberLib, objLib } from '@xc-core/lib';
import config from '@xc-core/config';
// eslint-disable-next-line import/no-cycle
import Field from '@containers/getQuote/wizard/field';


interface IPropsRenderBlock {
 properties: any, status: string, model: any,
  collections?: any,
  handleChange?: any,
  endorseMode?: boolean,
  errorMessages?: IObject[],
  isEndorsementAllowed?: Function,
  isModal?: any
  priceChanged ?: any
}
const RenderBlock = (props: IPropsRenderBlock) => {
  const {
    properties,
    status,
    model,
    collections,
    handleChange,
    endorseMode,
    errorMessages = [],
    isEndorsementAllowed,
    isModal,
    priceChanged
  } = props;

  return (
    <div key={model.id} className='item'>
      <span className='plan-title d-block mb-3 mt-2'>{model.name}</span>
      {
        model.items.map((item: any) => {
          const errorMessage: IObject | undefined = errorMessages
            .find((error) => error.referral.includes(item.current.id));
          return (
            <RenderBlockItem
              key={item.current.id}
              current={item.current}
              prev={item.prev}
              conf={item.config}
              item={properties}
              isCanceled={String(status).toLowerCase() === 'cancelled'}
              collections={collections}
              handleChange={handleChange}
              endorseMode={endorseMode && model.config && model.config.isEditable}
              errorMessage={errorMessage || {}}
              isEndorsementAllowed={isEndorsementAllowed}
              isModal={isModal}
              priceChanged={priceChanged}
            />
          );
        })
      }
    </div>
  );
};

interface IPropsRenderItem {
  current: any, prev: any, conf: any, item: any, isCanceled:boolean, collections: any, handleChange: any,
  endorseMode: boolean, errorMessage: IObject, isEndorsementAllowed?: Function,isModal?: any, priceChanged?:any
}
const RenderBlockItem = (props: IPropsRenderItem) => {
  const {
    current, prev, item, conf,
    // isCanceled,
    collections, handleChange,
    endorseMode = false, errorMessage, isEndorsementAllowed, isModal, priceChanged
  } = props;
  let value = objLib.getValueFromPath(item, current.id);
  let prevValue = objLib.getValueFromPath(item, prev.id);
  let formatedValue = value;
  let formatedPrev = prevValue;

  if (current.config.currency) {
    value = parseFloat(value || 0);
    formatedValue = value === 0 ? 0 : numberLib.applyFormat(value, config.currencyFormat);
  }

  if (prev.config.currency && prevValue !== null && prevValue !== undefined) {
    prevValue = parseFloat(prevValue || 0);
    formatedPrev = prevValue === 0 ? 0 : numberLib.applyFormat(prevValue, config.currencyFormat);
  }

  if(conf.hideZero && value == 0) return null;

  return (
    <Row className='pt-1 pb-3' key={current.id}>
      <Col
        sm={6}
        className={current.config.className}
      >
        {current.name}
      </Col>
      {
        endorseMode && isEndorsementAllowed && isEndorsementAllowed('financial') ? (
          <Col>
            <Field
              item={item}
              collections={collections}
              model={current}
              onChange={handleChange}
              validationError={{ message: errorMessage.message, referral: current.id }}
            />
          </Col>
        ) : (
          <Col
            sm={6}
            className={`text-right summary-label-3 ${current.config.className}`}
          >
            {
            prevValue !== null && prevValue !== undefined && prevValue !== value && (item.archiveOperation === 'FINANCIAL_ENDORSE' || item.archiveOperation === 'CANCEL' || isModal) ? (
              <span className={`mr-1 ${prev.config.className} ${isModal && !priceChanged ? 'd-none' : ''}`}>
                {prev.config.currency ? `${config.currencySymbol} ` : ''}
                {formatedPrev}
              </span>
            ) : <></>
          }
            <span>
              {current.config.currency ? `${config.currencySymbol} ` : ''}
              {formatedValue}
            </span>
            { ((prev.config.currency || prev.config.change) && numberLib.compare(value, prevValue)) > 0  && (item.archiveOperation === 'FINANCIAL_ENDORSE' || item.archiveOperation === 'CANCEL' || isModal) ? <i className={`icon-up-arrow-line text-success small ${isModal && !priceChanged ? 'd-none' : ''}`} /> : <></>}
            { ((prev.config.currency || prev.config.change) && numberLib.compare(value, prevValue)) < 0  && (item.archiveOperation === 'FINANCIAL_ENDORSE' || item.archiveOperation === 'CANCEL' || isModal) ? <i className={`icon-down-arrow-line text-danger small ${isModal && !priceChanged ? 'd-none' : ''}`} /> : <></>}
          </Col>
        )
    }
    </Row>
  );
};

interface IPropsSidebar {
  model: any,
  status: string,
  item: any,
  collections?: any,
  validator?: any,
  endorseMode?: boolean,
  handleChange?: any,
  showTitle?: boolean,
  errorMessages?: IObject[],
  isEndorsementAllowed?: Function,
  isModal?: any
  priceChanged ?: any
}
const PolicySidebarCard = (props: IPropsSidebar) => {
  const {
    model, status, item, collections, handleChange, endorseMode,
    showTitle = true, errorMessages, isEndorsementAllowed, isModal, priceChanged
  } = props;
  let prevTotal = objLib.getValueFromPath(item, model.header.items[0].prev.id);
  let total = objLib.getValueFromPath(item, model.header.items[0].current.id);
  let formatedPrev = prevTotal;
  let formatedTotal = total;
  total = parseFloat(total || 0);
  formatedTotal = total === 0 ? 0 : numberLib.applyFormat(total, config.currencyFormat);
  if (prevTotal !== null && prevTotal !== undefined) {
    prevTotal = parseFloat(prevTotal || 0);
    formatedPrev = prevTotal === 0 ? 0 : numberLib.applyFormat(prevTotal, config.currencyFormat);
  }
  return (
    <div className='sidebar-card'>
      <Card style={{ borderRadius: '10px' }} className={`${showTitle ? 'shadow-md' : 'shadow-none'} border-0 overflow-visible`}>
        <CardBody className='px-3'>
          {showTitle === true && (
            <div className='pt-2 text-primary pr-3'>
              <Row>
                <Col sm={10} className='text-secondary mt-2'>
                  {`${objLib.getValueFromPath(item, model.header.productName.id)} - ${objLib.getValueFromPath(item, model.header.plan.id)}`}
                </Col>
                <Col sm={2} className='text-right pr-0 mt-2 customCircularbutton'>
                  {/* TODO it should be dynamic */}
                  {
                    item.productName == 'Flexi PA'
                      ?
                        <i className='header-icon icon-flexi-pa' />
                      :
                        <i className='header-icon icon-planeProduct' />
                  }
                </Col>
              </Row>
            </div>
          )}
          <div className='pt-3'>
            <span className='plan-title'>
              Total Price
            </span>
            <h2 className='py-2'>
              {
               prevTotal !== null && total !== prevTotal && (item.archiveOperation === 'FINANCIAL_ENDORSE' || item.archiveOperation === 'CANCEL' || isModal)
                 ? (
                   <span className={`cross-line gray bold total-pre mr-1 ${isModal && !priceChanged ? 'd-none' : ''}`}>
                     {`${config.currencySymbol} ${formatedPrev}`}
                   </span>
                 )
                 : <></>
              }
              <span className='total-current'>{`${config.currencySymbol} ${formatedTotal}`}</span>
              { numberLib.compare(total, prevTotal) > 0 && (item.archiveOperation === 'FINANCIAL_ENDORSE' || item.archiveOperation === 'CANCEL' || isModal) ? <i className={`icon-up-arrow-line text-success ${isModal && !priceChanged ? 'd-none' : ''}`} /> : <></>}
              { numberLib.compare(total, prevTotal) < 0 && (item.archiveOperation === 'FINANCIAL_ENDORSE' || item.archiveOperation === 'CANCEL' || isModal) ? <i className={`icon-down-arrow-line text-danger ${isModal && !priceChanged ? 'd-none' : ''}`} /> : <></>}

            </h2>
          </div>
          {
            model.body.items.map((m:any) => (
              <RenderBlock
                key={m.id}
                model={m}
                properties={item}
                status={status}
                collections={collections}
                handleChange={handleChange}
                endorseMode={endorseMode}
                errorMessages={errorMessages}
                isEndorsementAllowed={isEndorsementAllowed}
                isModal={isModal}
                priceChanged={priceChanged}
              />
            ))
          }
        </CardBody>
      </Card>
    </div>
  );
};

export default PolicySidebarCard;
