import React, {FC,useState, useRef} from 'react';
//@ts-ignore
import Modal from 'react-modal';
import {
    SelectMulti, Input, Table,
} from '@xc-core/components/core';
import {ModalUi} from "../../../_components";
import AssignProcessToProductLogic from "./AssignProcessToProduct.logic";
import {Button, Col, Row, Input as RInput} from "reactstrap";
import {useHistory} from 'react-router-dom'
import {styleListOfProcess} from './style'
import { getCodeSnippet } from '../util/util';

type Props = {
    product: any,
    visible: boolean,
    requestToClose: any
}
export const AssignProcessToProduct: FC<Props> = ({product, visible, requestToClose}) => {
    const {process, selectedProcess, handleChangeSelectedProcess, setTypeOfProcess, typeOfProcess, addProcessToProduct,
        processListFields, productInfo,removeProcessItem, showCodeSnippet, currentCodeSnippet,copyCodeSnippet, downloadTemplate,uploadNewTemplate, updateTemplateInputs,
        updateTemplate} = AssignProcessToProductLogic(product);
    const [openDeleteConfirmationModal , setOpenDeleteConfirmationModal]= useState<boolean>(false)
    const [selectedProductId, setSelectedProductId]= useState<number>()
    const history = useHistory();
    const ref: any= useRef()
    if (!visible || productInfo === undefined) {
        return null;
    }
    const deleteModalHandler=()=>{
        if(openDeleteConfirmationModal){
            setSelectedProductId(undefined)
        }
        setOpenDeleteConfirmationModal(!openDeleteConfirmationModal)
    }

    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={true}
                onRequestClose={() => {
                    if (requestToClose && typeof requestToClose === "function") {
                        requestToClose()
                    }
                }}
                style={styleListOfProcess}
                contentLabel="AssignProcessToProduct">
                <div className='container-fluid px-0'>
                    <div className='row d-flex justify-content-center my_30'>
                        <div className='col-sm-12'>
                            <h5 className='sub-title'>{productInfo?.name} Product</h5>
                            <div className="row">
                                <div className="col-sm-6">
                                    Process:
                                    <SelectMulti
                                        id={"ProcessList"}
                                        name={"processList"}
                                        handler={(item) => {
                                            handleChangeSelectedProcess(item)
                                        }}
                                        collections={process}
                                        value={selectedProcess}
                                        inputHandler={() => {

                                        }}
                                        inputValue={""}
                                        isMulti={false}/>
                                </div>
                                <div className="col-sm-6">
                                    Type:
                                    <Input value={typeOfProcess}
                                           id={"type"}
                                           name={"type"} handler={(e: IPair) => {
                                        setTypeOfProcess(e.value)
                                    }}/>
                                </div>
                            </div>
                            <Button
                                color='primary'
                                className='my-4 customPrimarybtn'
                                onClick={addProcessToProduct}
                                disabled={false}>Add To Product</Button>
                        </div>
                    </div>

                    <div className="row my_30">

                        <h5 className='sub-title' style={{
                            marginLeft: 15
                        }}>Process List Of {productInfo?.name}</h5>
                        <Table
                            titles={processListFields}
                            records={productInfo?.processes || []}
                            image={""}
                            hasRecords={true}
                            onRowClicked={(row: IObject) => {}}
                            onRowButtonClicked={(row: IObject) => {
                                history.push(`/process-modeller/${row.processId}`)
                            }}
                            handleSearchKeyword={(pair: IPair) => {
                            }}
                            searchKeyword={""}
                            handlePageClick={(pair: IPair) => {
                            }}
                            handlePageSizeChange={(pair: IPair) => {
                            }}
                            pagination={{}}
                            buttonIcon={'rightArrow'}
                            onDeleteRow={(record)=>{
                                setSelectedProductId(record.id)
                                deleteModalHandler()
                            }}
                            onExportRow={showCodeSnippet}
                        />
                    </div>
                </div>
                <Row>
                    <div className="my-3 col-sm-12">
                        Certificate PDF Template :
                        <div style={{
                            height: 35,
                            border: '1px solid #DEE2E4',
                            borderRadius: 5,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingLeft: 20,
                            display: 'flex'
                        }}>
                            <span onClick={()=>{
                                
                                downloadTemplate(productInfo?.templateInfo?.productSlug,productInfo?.templateInfo?.policy?.path )
                            }} style={{
                                flex:1
                            }}>
                                {productInfo?.templateInfo?.policy?.path}
                            </span>
                            
                        <button id="5" className="xc-button-circular mx-1  table-row-button btn">
                            <i className="icon-garbage"></i>
                        </button>
                        </div>
                            <input style={{display: 'none'}} type='file' onChange={uploadNewTemplate} ref={ref}/>
                    <Button onClick={()=>{
                        ref.current.click()
                    }}className="btn-primary mt-3">
                        Upload Template
                    </Button>
                    </div>
                    <div className="my-3 col-sm-12">
                        Email Template:
                        <RInput
                                type='textarea'
                                id={"email-template"}
                                value={productInfo?.templateInfo?.new_policy_email?.content|| ''}
                                name={"email-template"} onChange={(e: any) => {
                                    updateTemplateInputs('new_policy_email', e)
                        }}/>
                    </div>
                    <div className="my-3 col-sm-12">
                        SMS Template:
                        <RInput
                                id={"sms-template"}
                                value={productInfo?.templateInfo?.new_policy_sms?.content|| ''}
                                name={"sms-template"} onChange={(e: any) => {
                                    updateTemplateInputs('new_policy_sms', e)
                        }}/>
                    </div>
                </Row>
                <Button
                    onClick={updateTemplate}
                    className="btn-primary mt-3"
                >
                    Save Template
                </Button>

            </Modal>
            <div>
                <ModalUi
                    isOpen={openDeleteConfirmationModal}
                    toggle={deleteModalHandler}
                    className="my-auto"
                >
                    <p>Are you sure you want to delete this process?</p>
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Button onClick={deleteModalHandler} className="mr-2 bg-transparent text-dark border-0">No</Button>
                            <Button
                                onClick={()=>{
                                    if(selectedProductId) {
                                        removeProcessItem(selectedProductId)
                                        deleteModalHandler();
                                    }
                                }}
                                className="btn-primary"
                            >
                                Delete
                            </Button>
                        </Col>
                    </Row>
                </ModalUi>
            </div>
            <div>
            <ModalUi
                    isOpen={currentCodeSnippet}
                    toggle={showCodeSnippet}
                    className="my-auto"
                >
                    <p>Code Snippet</p>
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <pre>
                                {currentCodeSnippet ? getCodeSnippet(currentCodeSnippet):  null}
                            </pre>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button
                                onClick={copyCodeSnippet}
                                className="btn-primary"
                            >
                                Copy
                            </Button>
                        <Button
                                onClick={showCodeSnippet}
                                className="btn-warning ml-2"
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                </ModalUi>
            </div>
        </>
    )
}
AssignProcessToProduct.defaultProps = {
    visible: false,
    product: undefined
}
export default AssignProcessToProduct