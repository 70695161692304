import React from 'react';
import { connect } from 'react-redux';
import {
  objLib, fieldLib,
  dateLib, stringLib,
} from '@xc-core/lib';
import { userMessages } from '@xc-core/constants';
import { Message, Loader } from '@xc-core/components/core';
import BaseWizard from '@containers/getQuote/wizard/baseWizard';
import RenderWizard from '@containers/getQuote/wizard/renderWizard';
import { ComponentLoader } from '@xc-core/components';
import { GetQuoteActions, PolicyActions } from '@redux/actions';
import config from '@xc-core/config';
import lib from 'utils/lib';
import moment from 'moment';
import { sidebarModel } from 'models';
import * as repo from './components';
import { model, defaultValues } from './config';
import PaymentForm from './components/paymentForm';

class PersonalAccidentWizard extends BaseWizard<IBaseWizardProps, IBaseWizardState> {
  protected defaultCountry = defaultValues.country;

  protected stateListParam = defaultValues.stateListParam;

  protected model = model;

  private CNICLength = defaultValues.cnicLength;

  private PostCodeLength = defaultValues.postcodeLength;

  private DebitCreditNoLength = defaultValues.debitCreditNoLength;

  constructor(props: IBaseWizardProps) {
    super(props);
    this.state = {
      sendingRequest: false,
      invalidForm: false,
      item: {},
      validationErrors: [],
      redirectToGateway: false,
      skipAutoNextOnPlanSelection: false,
      skipAutoNextOnAdditionalDivers: false,
    };
  }

  protected prepareSteps = (steps: IStep[]): IStep[] => (steps.map((step: IStep) => {
    const metadata = this.getStepMetadata(step.order);
    return {
      ...step,
      componentType: metadata.componentType,
      formContainer: metadata.formContainer,
    };
  }))

  protected getStepMetadata = (order: number): { componentType: string,
    formContainer: IFormContainer } => {
    switch (order) {
      case 1:
        return {
          componentType: 'BasicDetails',
          formContainer: {
            title: 'Please enter basic details',
            mainDetail: this.createBasicDetailFormModel(),
            fields: this.model.fieldsModel,
          },
        };
      case 2:
        return {
          componentType: 'PlanSelection',
          formContainer: {
            title: 'Please Select Plan',
            fields: this.model.fieldsModel,
            forms: this.createPlanSelectionForm(),
          },
        };
      case 3:
        return {
          componentType: 'InsuredDetails',
          formContainer: {
            title: 'Please enter customer details',
            mainDetail: this.createMainInsuredFormModel(),
            contactDetail: this.createContactDetailFormModel(),
            spouseDetail: this.createSpouseDetailsFormModel(),
            fields: this.model.fieldsModel,
          },
        };
      case 4:
        return {
          componentType: 'Summary',
          formContainer: {
            title: '',
            mainModel: model.mainDetailModel,
            spouseDetailModel: model.spouseDetailModel,
          },
        };
      case 5:
        return {
          componentType: 'PaymentDetail',
          formContainer: {
            title: '',
            paymentDetail: this.createPaymentFormModel(),
          },
        };
      case 6:
        return {
          componentType: 'ThankyouPage',
          formContainer: {
            title: '',
          },
        };
      default:
        return {
          componentType: '',
          formContainer: {
            title: '',
          },
        };
    }
  }

  protected handleMainIdType = (pair: IPair, item: IObject): IPair[] => {
    let idNumber = objLib.getValueWithLodash(item, model.fieldsModel.idNumber.id);
    const idType = objLib.getValueWithLodash(item, pair.id);
    if (idType !== pair.value) idNumber = ''; // reset dependentId if idType has changed

    return [pair, { id: model.fieldsModel.idNumber.id, value: idNumber }];
  }

  private
      = (date1: string, date2: string) => dateLib.getDiff(date1, date2, 'days') + 1;

  private getMaxDob = (): string => {
    const { minAge } = defaultValues;
    return dateLib.subtractFromNow(minAge.years, minAge.months, minAge.days);
  }

  private getMinDob = (): string => {
    const { maxAge } = defaultValues;
    return dateLib.subtractFromNow(maxAge.years, maxAge.months, maxAge.days);
  }

  private isEndDateDisabled = (item: IObject): boolean => {
    const tripTypeId = model.fieldsModel.tripType.id;
    return objLib.getValueWithLodash(item, tripTypeId) === 'Annual';
  };

  private handlePaymentMode = (pair: IPair, item: IObject): IPair[] => {
    let receivedByValue = objLib.getValueWithLodash(item, model.fieldsModel.paymentReceivedBy.id);
    if (!receivedByValue) {
      const { user } = config;
      receivedByValue = user.name;
    }
    return [pair, { id: model.fieldsModel.paymentReceivedBy.id, value: receivedByValue }];
  }

  private getMinEndDate = (item: IObject): string => {
    const startDate = objLib.getValueWithLodash(item, model.fieldsModel.startDate.id) || '';
    const currentDate = dateLib.getCurrentDate(config.dateMomentFormat);
    return startDate || currentDate;
  }

  private handleMainIdNumber = (pair: IPair, item: IObject): IPair[] => {
    let { value } = pair;
    let gender = objLib.getValueWithLodash(item, model.fieldsModel.gender.id) || '';
    const preValue = objLib.getValueWithLodash(item, pair.id);
    const idType = objLib.getValueWithLodash(item, model.fieldsModel.idType.id);
    if (idType === 'CNIC') {
      value = value.length > this.CNICLength ? value.slice(0, this.CNICLength) : value;
      value = this.restrictToNumber(value, preValue);
      gender = value && value.length === this.CNICLength
        ? this.getGenderFromValue(value[this.CNICLength - 1]) : gender;
    } else {
      value = this.restrictToAlphanumeric(value, preValue);
    }
    return [{ id: pair.id, value }, { id: model.fieldsModel.gender.id, value: gender }];
  }

  private handleBeneficiaryIdNumber = (pair: IPair, item: IObject): IPair[] => {
    let { value } = pair;
    const preValue = objLib.getValueWithLodash(item, pair.id);
    const idTypeId = fieldLib.applyIndexToRawId(fieldLib.getIndex(pair.id),
      model.fieldsModel.beneficiaryIdType.id);
    const idType = objLib.getValueWithLodash(item, idTypeId);
    if (idType === 'CNIC') {
      value = value.length > this.CNICLength ? value.slice(0, this.CNICLength) : value;
      value = this.restrictToNumber(value, preValue);
    } else {
      value = this.restrictToAlphanumeric(value, preValue);
    }
    return [{ id: pair.id, value }];
  }

  protected handlePostcode = (pair: IPair, item: IObject): IPair[] => {
    const pre = objLib.getValueWithLodash(item, pair.id);
    let { value } = pair;
    // value = value.length > this.PostCodeLength ? value.slice(0, this.PostCodeLength) : value;
    value = this.restrictToNumber(value, pre);
    return [{ id: pair.id, value }];
  }

  protected handleDebitCreditNo = (pair: IPair, item: IObject): IPair[] => {
    const pre = objLib.getValueWithLodash(item, pair.id);
    let { value } = pair;
    value = value.length > this.DebitCreditNoLength
      ? value.slice(0, this.DebitCreditNoLength) : value;
    value = this.restrictToNumber(value, pre);
    return [{ id: pair.id, value }];
  }

  private createBasicDetailFormModel = (): IForm => {
    const title = 'Basic Details Information';
    const insuredType: IField = {
      id: model.fieldsModel.insuredType.id,
      label: model.fieldsModel.insuredType.label,
      ext: model.fieldsModel.insuredType.ext,
      type: model.fieldsModel.insuredType.type,
      style: this.defaultFieldStyle,
      handler: this.handleInsuredType,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.insuredType.collectionId,
      placeholder: model.fieldsModel.insuredType.placeholder,
    };

    const individualOccupation: IField = {
      id: model.fieldsModel.individualOccupation.id,
      label: model.fieldsModel.individualOccupation.label,
      ext: model.fieldsModel.individualOccupation.ext,
      type: model.fieldsModel.individualOccupation.type,
      style: this.defaultFieldStyle,
      handler: this.occupationHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.individualOccupation.collectionId,
      placeholder: model.fieldsModel.individualOccupation.placeholder,
    };

    const spouseOccupation: IField = {
      id: model.fieldsModel.spouseOccupation.id,
      label: model.fieldsModel.spouseOccupation.label,
      ext: model.fieldsModel.spouseOccupation.ext,
      type: model.fieldsModel.spouseOccupation.type,
      style: this.defaultFieldStyle,
      handler: this.occupationHandler,
      disabled: (item: IObject) => item?.properties['Insured Type'] !== 'Individual & Spouse',
      collectionId: model.fieldsModel.spouseOccupation.collectionId,
      placeholder: model.fieldsModel.spouseOccupation.placeholder,
      hidden: ({ properties: { 'Insured Type': insuredTypeValue } }: IObject) => (insuredTypeValue !== 'Individual & Spouse'),
    };

    return {
      getTitle: () => title,
      fields: [insuredType, individualOccupation, spouseOccupation],
    };
  }

  private createPlanSelectionForm = (): IForm => {
    const title = '';
    const plan: ICBPlanField = {
      id: model.fieldsModel.plan.id,
      label: model.fieldsModel.plan.label,
      // ext: model.fieldsModel.plan.ext,
      type: model.fieldsModel.plan.type,
      style: this.defaultFieldStyle,
      handler: this.handlePlan,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.plan.collectionId,
    };
    return { getTitle: () => title, fields: [plan] };
  }

  private createMainInsuredFormModel = (): IForm => {
    const title = 'Main Insured Details';
    const fullName: IField = {
      id: model.fieldsModel.fullName.id,
      label: model.fieldsModel.fullName.label,
      ext: model.fieldsModel.fullName.ext,
      type: model.fieldsModel.fullName.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.fullName.placeholder,
    };
    const idType: IField = {
      id: model.fieldsModel.idType.id,
      label: model.fieldsModel.idType.label,
      ext: model.fieldsModel.idType.ext,
      type: model.fieldsModel.idType.type,
      style: this.defaultFieldStyle,
      handler: this.handleMainIdType,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.idType.collectionId,
      placeholder: model.fieldsModel.idType.placeholder,
    };
    const idNumber: IField = {
      id: model.fieldsModel.idNumber.id,
      label: model.fieldsModel.idNumber.label,
      ext: model.fieldsModel.idNumber.ext,
      type: model.fieldsModel.idNumber.type,
      style: this.defaultFieldStyle,
      handler: this.handleMainIdNumber,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.idNumber.placeholder,
    };
    const dob: IField = {
      id: model.fieldsModel.dob.id,
      label: model.fieldsModel.dob.label,
      ext: model.fieldsModel.dob.ext,
      type: model.fieldsModel.dob.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      getMax: () => this.getMaxDob(),
      getMin: () => this.getMinDob(),
      // current: this.getMaxDob(),
      placeholder: model.fieldsModel.dob.placeholder,
    };
    const gender: IField = {
      id: model.fieldsModel.gender.id,
      label: model.fieldsModel.gender.label,
      ext: model.fieldsModel.gender.ext,
      type: model.fieldsModel.gender.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.gender.collectionId,
      placeholder: model.fieldsModel.gender.placeholder,
    };
    return {
      getTitle: () => title,
      fields: [fullName, idType, idNumber, dob, gender],
    };
  }

  private createContactDetailFormModel = (): IForm => {
    const title = 'Contact Details';
    const address: IField = {
      id: model.fieldsModel.address.id,
      label: model.fieldsModel.address.label,
      ext: model.fieldsModel.address.ext,
      type: model.fieldsModel.address.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.address.placeholder,
    };
    const country: IField = {
      id: model.fieldsModel.country.id,
      label: model.fieldsModel.country.label,
      ext: model.fieldsModel.country.ext,
      type: model.fieldsModel.country.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.country.collectionId,
    };
    const state: IField = {
      id: model.fieldsModel.state.id,
      label: model.fieldsModel.state.label,
      ext: model.fieldsModel.state.ext,
      type: model.fieldsModel.state.type,
      collectionId: model.fieldsModel.state.collectionId,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
    };
    const postCode: IField = {
      id: model.fieldsModel.postCode.id,
      label: model.fieldsModel.postCode.label,
      ext: model.fieldsModel.postCode.ext,
      type: model.fieldsModel.postCode.type,
      style: this.defaultFieldStyle,
      handler: this.handlePostcode,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.postCode.placeholder,
    };
    const city: IField = {
      id: model.fieldsModel.city.id,
      label: model.fieldsModel.city.label,
      ext: model.fieldsModel.city.ext,
      type: model.fieldsModel.city.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.city.placeholder,
    };
    const email: IField = {
      id: model.fieldsModel.email.id,
      label: model.fieldsModel.email.label,
      ext: model.fieldsModel.email.ext,
      type: model.fieldsModel.email.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.email.placeholder,
    };
    const mobileNo: IField = {
      id: model.fieldsModel.mobileNo.id,
      label: model.fieldsModel.mobileNo.label,
      ext: model.fieldsModel.mobileNo.ext,
      type: model.fieldsModel.mobileNo.type,
      style: this.defaultFieldStyle,
      handler: this.handleNumber,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.mobileNo.placeholder,
    };
    return {
      getTitle: () => title,
      fields: [address, country, state, postCode, city, email, mobileNo],
    };
  }

  private createSpouseDetailsFormModel = (): IForm => {
    const title = 'Main Insured Details';
    const spouseFullName: IField = {
      id: model.fieldsModel.spouseFullName.id,
      label: model.fieldsModel.spouseFullName.label,
      ext: model.fieldsModel.spouseFullName.ext,
      type: model.fieldsModel.spouseFullName.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.spouseFullName.placeholder,
    };
    const spouseIdType: IField = {
      id: model.fieldsModel.spouseIdType.id,
      label: model.fieldsModel.spouseIdType.label,
      ext: model.fieldsModel.spouseIdType.ext,
      type: model.fieldsModel.spouseIdType.type,
      style: this.defaultFieldStyle,
      handler: this.handleMainIdType,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.spouseIdType.collectionId,
      placeholder: model.fieldsModel.spouseIdType.placeholder,
    };
    const spouseIdNumber: IField = {
      id: model.fieldsModel.spouseIdNumber.id,
      label: model.fieldsModel.spouseIdNumber.label,
      ext: model.fieldsModel.spouseIdNumber.ext,
      type: model.fieldsModel.spouseIdNumber.type,
      style: this.defaultFieldStyle,
      handler: this.handleMainIdNumber,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.spouseIdNumber.placeholder,
    };
    const spouseDob: IField = {
      id: model.fieldsModel.spouseDob.id,
      label: model.fieldsModel.spouseDob.label,
      ext: model.fieldsModel.spouseDob.ext,
      type: model.fieldsModel.spouseDob.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      getMax: () => this.getMaxDob(),
      getMin: () => this.getMinDob(),
      // current: this.getMaxDob(),
      placeholder: model.fieldsModel.spouseDob.placeholder,
    };
    const spouseGender: IField = {
      id: model.fieldsModel.spouseGender.id,
      label: model.fieldsModel.spouseGender.label,
      ext: model.fieldsModel.spouseGender.ext,
      type: model.fieldsModel.spouseGender.type,
      style: this.defaultFieldStyle,
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.spouseGender.collectionId,
      placeholder: model.fieldsModel.spouseGender.placeholder,
    };
    return {
      getTitle: () => title,
      fields: [spouseFullName, spouseIdType, spouseIdNumber, spouseDob, spouseGender],
    };
  }

  private createPaymentFormModel = (): IForm => {
    const paymentMode: IField = {
      id: model.fieldsModel.paymentMode.id,
      label: model.fieldsModel.paymentMode.label,
      ext: model.fieldsModel.paymentMode.ext,
      type: model.fieldsModel.paymentMode.type,
      style: { col: '12' },
      handler: this.handlePaymentMode,
      disabled: this.defaultFieldDisabled,
      collectionId: this.model.fieldsModel.paymentMode.collectionId,
      placeholder: this.model.fieldsModel.paymentMode.placeholder,
    };
    const receiptNo: IField = {
      id: model.fieldsModel.receiptNo.id,
      label: model.fieldsModel.receiptNo.label,
      ext: model.fieldsModel.receiptNo.ext,
      type: model.fieldsModel.receiptNo.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      placeholder: model.fieldsModel.receiptNo.placeholder,
    };
    const paymentReceivedBy: IField = {
      id: model.fieldsModel.paymentReceivedBy.id,
      label: model.fieldsModel.paymentReceivedBy.label,
      ext: model.fieldsModel.paymentReceivedBy.ext,
      type: model.fieldsModel.paymentReceivedBy.type,
      style: { col: '12' },
      handler: this.defaultFieldHandler,
      disabled: this.defaultFieldDisabled,
      collectionId: model.fieldsModel.paymentReceivedBy.collectionId,
      placeholder: model.fieldsModel.paymentReceivedBy.placeholder,
    };
    return {
      getTitle: () => 'payment details',
      fields: [paymentMode, receiptNo, paymentReceivedBy],
    };
  }

  private removeSpouseData = async () => {
    const { spouse } = this.state.item.properties;
    if (spouse) await this.removeFromStateItemById('properties.spouse');
  };

  private handleInsuredType = (pair: IPair) => {
    const { value } = pair;
    const updatedPairs = [pair];
    if (value === 'Individual') {
      updatedPairs.push({ id: 'properties.Spouse Occupation', value: 'None' });
      updatedPairs.push({ id: 'properties.Spouse Occupation Class', value: 'None' });
      this.removeSpouseData();
    }
    return updatedPairs;
  }

  protected occupationHandler = (pair: IPair): IPair[] => {
    const { props: { getQuote: { collections } } } = this;
    const occupationObj = collections?.occupation
      .find((occupationItem: IObject) => occupationItem.value === pair.value);
    const occupationClass = {
      id: pair.id.includes('spouse') ? 'properties.Spouse Occupation Class' : 'properties.Individual Occupation Class',
      value: occupationObj?.extendedProperties?.class || pair.value,
    };
    return ([pair, occupationClass]);
  };

  private handlePlan = async (step: IStep, plan: IObject) => {
    const { getQuote, getItemOnNext } = this.props;
    const { item: { properties: { priceList } } } = getQuote;
    if (getQuote.product && priceList) {
      let { properties } = getQuote.item;
      const Price: IObject = {};
      // eslint-disable-next-line no-return-assign
      Object.keys(plan).filter((key: string) => key !== 'Plan').forEach((key: string) => Price[key] = plan[key]);
      properties = {
        ...properties,
        priceList,
        Price,
        Plan: plan.Plan,
      };
      this.setState((prevState: IBaseWizardState) => ({
        ...prevState,
        item: {
          ...prevState.item,
          properties: {
            ...prevState.item.properties,
            ...properties,
          },
        },
      }));
      const updatedItem = await getItemOnNext(getQuote.product.id, getQuote.item.id, properties);
      await this.updateStateItem(updatedItem);
      this.setValues([{ id: model.fieldsModel.country.id, value: this.defaultCountry }]);
    }
  }

  next = async () => {
    const { getQuote: { item, product }, getItemOnNext } = this.props;

    if (product && item) {
      this.setState({ sendingRequest: true });
      const properties = { ...item.properties, ...this.state.item.properties };
      const validationErrors = this.scriptValidator
        .validate(this.state.item, item.state || this.state.item.state);
      if (validationErrors === null) {
        this.setState({ invalidForm: true, sendingRequest: false });
        return;
      }
      this.setState((prevState: IBaseWizardState) => ({ ...prevState, validationErrors }));
      if (validationErrors.length === 0) {
        const updatedItem = await getItemOnNext(product.id, item.id, properties);
        this.handlePaymentGatewayStep(updatedItem);
        this.updateStateItem(updatedItem);
        this.setState({ invalidForm: false, sendingRequest: false });
      } else {
        this.setState({ invalidForm: true, sendingRequest: false });
      }
    }
  }

  resetInvalidForm=() => {
    if (this.state.invalidForm) {
      this.setState({ invalidForm: false });
    }
  }

  render() {
    const {
      getQuote, getQuote: { collections, product, steps },
      history,
    } = this.props;
    if (collections && !collections.spouseOccupation) {
      const none = {
        id: 'None',
        name: 'None',
        code: 'None',
        group: 'occupation_abc_pa',
        extendedProperties: {
          class: 'None',
        },
        value: 'None',
      };
      collections.spouseOccupation = [none, ...collections.occupation];
    }
    const {
      item, validationErrors, sendingRequest, invalidForm,
    } = this.state;
    const currentStep = this.getCurrentStep();
    const paymentSelected = !stringLib.isEmpty(objLib.getValueWithLodash(item,
      this.model.fieldsModel.paymentReceivedBy.id));

    const sidebar = sidebarModel.quotation.personalAccident;
    sidebar.body.items = sidebar?.body?.items?.slice(0, 3);
    if (product === null) return <Message message={userMessages.ERROR} />;
    if (collections === null || steps.length === 0) return <Loader />;
    if (this.state.redirectToGateway) return <PaymentForm item={item} />;

    return (
      <RenderWizard
        getQuote={getQuote}
        getCurrentStep={this.getCurrentStep}
        handleNext={this.next}
        handlePrev={() => {
          const { componentType } = this.getCurrentStep();
          const NoOfAdditionalDriver = this?.state?.item?.properties?.['Number of Additional Drivers'];
          switch (componentType) {
            case 'Summary':
              if (NoOfAdditionalDriver === 0) {
                this.setState({
                  skipAutoNextOnAdditionalDivers: true,
                }, () => {
                  this.previous();
                });
              } else {
                this.previous();
              }
              break;
            case 'InsuredDetails':
              this.setState({
                skipAutoNextOnPlanSelection: true,
              }, () => {
                this.previous();
              });
              break;
            default:
              this.previous();
              break;
          }
        }}
        handleSkip={this.skip}
        paymentSelected={paymentSelected}
        isBeneficirayEnterd
        sendingRequest={sendingRequest}
        invalidForm={invalidForm}
        isKhmer={lib.checkIsDemoSite()}
      >
        <ComponentLoader
          name={currentStep.componentType}
          repo={repo}
          history={history}
          propsModel={{
            onChange: (handler: string, obj: IHandledValue) => {
              this[handler](obj);
              this.resetInvalidForm();
            },
            initValues: (pairs: IPair[]) => this.setValues(pairs),
            collections,
            item,
            currentStep,
            model: currentStep.formContainer,
            validationErrors,
            defaults: { country: this.defaultCountry },
            next: this.next,
            previous: (key:string) => {
              this.setState({
                [key]: false,
              }, () => {
                this.previous();
              });
            },
            sidebar,
            skipAutoNextOnPlanSelection: this.state.skipAutoNextOnPlanSelection,
            skipAutoNextOnAdditionalDivers: this.state.skipAutoNextOnAdditionalDivers,
          }}
        />
      </RenderWizard>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  getQuote: state.newGetQuote,
});

const getQuoteActions = new GetQuoteActions();
const policyActions = new PolicyActions();

const mapDispatchToProps = (dispatch: any) => ({
  init: (productCode: number, country: string,
    stateListParam: string) => dispatch(getQuoteActions.init(productCode, country, stateListParam)),
  setStateInCollections: (country: string,
    param: string) => dispatch(getQuoteActions.setStateInCollections(country, param)),
  setSteps: (steps: IStep[]) => dispatch(getQuoteActions.setSteps(steps)),
  getItemOnNext: (productCode: number, id: number,
    properties: IObject) => dispatch(getQuoteActions.getItemOnNext(productCode, id, properties)),
  getItemOnSkip: (productCode: number, id: number,
    properties: IObject) => dispatch(getQuoteActions.getItemOnSkip(productCode, id, properties)),
  getItemOnPrevious: (productCode: number,
    id: number) => dispatch(getQuoteActions.getItemOnPrevious(productCode, id)),
  downloadCertificate: (id: string) => dispatch(policyActions.download(id)),
  reset: () => dispatch(getQuoteActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalAccidentWizard);
