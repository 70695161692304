export const getCodeSnippet = (processName: string) => {
    // TODO for now I didn't put this URL in env becuase its just demo
    // and I dont want to manipulate env.js file just for this.
    const baseUrl= 'https://demo-b2c.coverplus.io/quote/'
    return ` <iframe src="${baseUrl}${processName}"
    frameborder="0"
    scrolling="no" 
    style="overflow:hidden;height:100%;width:100%" 
    height="100%" 
    width="100%">
 </iframe>
    `
}