const policyActionTypes: IPolicyActionTypes = {
  LIST_FAILED: 'POLICY_LIST_FAILED',
  LIST_LOADING: 'POLICY_LIST_LOADING',
  LIST_SET_FORBIDDEN: 'POLICY_LIST_SET_FORBIDDEN',

  LIST_GET: 'POLICY_LIST_GET',
  LIST_SET_NOT_FOUND: 'POLICY_LIST_SET_NOT_FOUND',
  LIST_RESET: 'POLICY_LIST_RESET',
  LIST_CHANGE_TAB: 'POLICY_LIST_CHANGE_TAB',
  LIST_CHANGE_SEARCH_KEY: 'POLICY_LIST_CHANGE_SEARCH_KEY',
  LIST_CHANGE_PAGE: 'POLICY_LIST_CHANGE_PAGE',
  LIST_CHANGE_PAGE_SIZE: 'POLICY_LIST_CHANGE_PAGE_SIZE',
  LIST_CHANGE_FILTERS: 'POLICY_LIST_CHANGE_FILTERS',
  LIST_RESET_FILTERS: 'POLICY_LIST_RESET_FILTERS',
  LIST_APPLY_FILTERS: 'POLICY_LIST_APPLY_FILTERS',
  LIST_SET_TODAY_FILTER: 'POLICY_LIST_SET_TODAY_FILTER',
  LIST_RESET_TODAY_FILTER: 'POLICY_LIST_RESET_TODAY_FILTER',
  LIST_SET_HAS_RECORDS_INITIALLY: 'POLICY_LIST_SET_HAS_RECORDS_INITIALLY',

  DASHBOARD_LOADING: 'POLICY_DASHBOARD_LOADING',
  DASHBOARD_FAILED: 'POLICY_DASHBOARD_FAILED',
  DASHBOARD_SET_FORBIDDEN: 'POLICY_DASHBOARD_SET_FORBIDDEN',
  DASHBOARD_GET: 'POLICY_DASHBOARD_GET',

  CSV_EXPORT_LOADING: 'POLICY_CSV_EXPORT_LOADING',
  CSV_EXPORT_FAILED: 'POLICY_CSV_EXPORT_FAILED',
  CSV_EXPORT_SET_FORBIDDEN: 'POLICY_CSV_EXPORT_SET_FORBIDDEN',
  CSV_EXPORT_GET: 'POLICY_CSV_EXPORT_GET',

  DETAIL_LOADING: 'POLICY_DETAIL_LOADING',
  DETAIL_FAILED: 'POLICY_DETAIL_FAILED',
  DETAIL_SET_FORBIDDEN: 'POLICY_DETAIL_SET_FORBIDDEN',
  DETAIL_GET: 'POLICY_DETAIL_GET',

  CHANGE_VIEW_TYPE: 'POLICY_CHANGE_VIEW_TYPE',
  POLICY_DOWNLOAD_FAILED: 'POLICY_DOWNLOAD_FAILED',
  SET_PRODUCT: 'POLICY_LIST_SET_PRODUCT',
};

export default policyActionTypes;
