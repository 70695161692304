import React,{useState,useEffect} from 'react';
import Modal from 'react-modal';
import {CamundaApi} from '../util/CamundaApi'
import { styles } from './style';
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

export const ProcessList = ({loadProcessByKey})=>{    
    const [modalStatus,setModalStatus] = useState(false);
    const[processList,setProcessList] = useState([]);

    const loadProcesses = async () => {
        CamundaApi().getProcessDefinition()
        .then(response => response.json())
        .then(result => {                     
            setModalStatus(true)
            setProcessList(result)
        })
        .catch(error => console.log('error', error));   
    }

    const filteredArr = processList.reduce((acc, current) => {
        const x = acc.find(item => item.key === current.key);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
    }, []);

    return (
        <>
            <button onClick={loadProcesses} style={styles.button}>List Of Process </button>
            <Modal
                ariaHideApp={false}
                isOpen={modalStatus}                        
                onRequestClose={()=>{
                    setModalStatus(false)
                }}
                id={'workflowList'}
                style={customStyles}
                contentLabel="Example Modal">                
                <table border={1}>
                    <thead>
                        <tr>
                            <th>
                                Process Name
                            </th>
                            <th>
                                Process Id
                            </th>
                            <th>
                                Operation
                            </th>                
                        </tr>                    
                    </thead>
                    <tbody>
                    {
                    filteredArr && filteredArr.map((item,index)=>{
                        return (
                            <tr key={item.key}>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {item.key}
                                </td>
                                <td>
                                    <button onClick={()=>{
                                        loadProcessByKey(item.key)
                                        setModalStatus(false)
                                    }}>Load</button>
                                    <button>Delete</button>
                                    
                                </td>                    
                            </tr>
                        )
                    })
                }
                    </tbody>
                </table>            
            </Modal>
        </>
     
    )
}
export default ProcessList;