import React from 'react';
import { env } from '_config/env';
import {Header} from "../../@xc-core/components/core";
import bannerImg from "../../images/bannerGraphicImages/organisations.png";

const { productsUrl } = env;

export default class ProductUI extends React.Component {
  render() {
    return (
         <>
             <Header
                 title='Legacy Products'
                 bannerGraphic={bannerImg}
                 withBorderBottom
             />
             <div className='container' style={{ height: '100vh' }}>
                 <iframe
                     title='products'
                     frameBorder={0}
                     src={`${productsUrl}/products`}
                     style={{ width: '100%', minHeight: '85vh' }}
                     onLoad={() => this.forceUpdate()}
                 />
             </div>
         </>
    );
  }
}
