import React from 'react';
import Title from './title';

interface IProps { quotation: any }

const QuotationDetailTitle = (props: IProps) => {
  const { quotation } = props;
  const status = quotation.state === 'Completed' ? 'Certificate Issued' : 'Incomplete Quotation';
  return (
    <>
      <Title item={{ ...quotation, displayName: 'Quotation ID', displayId: quotation.refCode }}>
        <p className='m-0'>{`Status: ${status}`}</p>
      </Title>
    </>
  );
};

export default QuotationDetailTitle;
