
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import { fieldLib, objLib } from '@xc-core/lib';

const BeneficiaryDetail = (props: ILoadableComponentProps) => {
  const {
    model: {
      collections,
      item,
      validationErrors,
      model,
      onChange,
      initValues,
    },
  } = props;

  useEffect(() => {
    const beneficiary = objLib.getValueWithLodash(item, model.fields.beneficiary.id) || {};
    const pairs: IPair[] = [{ id: model.fields.beneficiary.id, value: beneficiary }];
    initValues(pairs);
  }, []);

  return (
    <Row>
      <Col md='8'>
        <h5 className='sub-title'>Please enter beneficiary details / សូមបំពេញព័ត៌មានលម្អិតរបស់អ្នកទទួលផល</h5>

        <RenderFields
          onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
          collections={collections}
          item={item}
          ext={true}
          validationErrors={validationErrors}
          fields={fieldLib.applyIndexToFieldsRawId(0, model.beneficiaryDetail.fields)}
        />

      </Col>
      <Col md='4'>
        <SidebarCard.Quotation
          item={item}
          model={sidebarModel.quotation.travelCB}
          ext={true}
        />
      </Col>
    </Row>
  );
};

export default BeneficiaryDetail;
