export const DMNXmlParser = (xml) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, "application/xml");
    const getDefinition = () => {
        return doc.getElementsByTagName("definitions");
    }

    const getDefinitionId = () => {
        const dmnDefinition = getDefinition()
        if (dmnDefinition && dmnDefinition.length > 0) {
            return dmnDefinition[0]?.attributes['id']?.value
        }
        return undefined
    }
    const getDefinitionName = () => {
        const dmnDefinition = getDefinition()
        if (dmnDefinition && dmnDefinition.length > 0) {
            return dmnDefinition[0]?.attributes['name']?.value
        }
        return undefined
    }
    return {
        definitionId:getDefinitionId(),
        definitionName:getDefinitionName()
    }
}

