import React from "react";
import {connect} from "react-redux";
import {Button, Col, Row} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import TextField from "@material-ui/core/TextField";
import {Link} from "react-router-dom";
import Notification from "react-notification-system-redux";
import logo from "../../../images/CoverPlusLogo.svg";
import {recoverPassword} from "../services/recoverPassword.service";
import {getNotificationMessage} from "../../../_services";


class ForgotPasswordPage extends React.Component {
  state = {
    email: "",
    hasError: false,
    disableSubmit: false,
  };
  validator = new SimpleReactValidator({
    element: (message, className) => <small className={`${className} text-danger`}>{message}</small>,
    className: 'text-danger',
    validators: {}
  });

  handleSubmit = async e => {
    const {dispatch} = this.props;
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({disableSubmit: true});
      const response = await recoverPassword(this.state.email);
      if (response.status === 500) {
        this.setState({hasError: true, disableSubmit: false});
      } else {
        dispatch(Notification.show(
          getNotificationMessage("Password reset link has been sent. If your given email belongs to an existing user, you will receive the link in a few minutes."))
        );
        setTimeout(() => {
          window.location.href = "#/";
        }, 2000);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleChange = e => {
    this.state.hasError && this.setState({hasError: false});
    this.setState({[e.target.name]: e.target.value});
  };

  render() {
    const { disableSubmit, hasError } = this.state;
    this.validator.purgeFields();
    return (
      <div>
        <div
          id="main"
          className="container alignCenter d-flex align-items-center"
        >
          <Row>
            <Col sm="9" md="7" lg="5" className="mx-auto">
              <div className="d-flex flex-column">
                <img
                  src={logo}
                  className="logoSize img-fluid mx-auto"
                  alt="Xpress Cover"
                />
                <p className="text-center text-primary">Forgot Password</p>
                <small className="text-center">We can help you reset your password using the email<br/> address linked
                  to your account.
                </small>
                <div className="mx-5">
                  <form name="form" onSubmit={this.handleSubmit}>
                    <div className={"form-group"}>
                      <TextField
                        id="outlined-email-input"
                        value={this.state.email}
                        onChange={e => this.handleChange(e)}
                        label="Email address"
                        className={"col-12"}
                        name="email"
                        margin="normal"
                        variant="outlined"
                      />
                      {this.validator.message('email', this.state.email, 'required|email')}
                      { hasError ? <small className="text-danger">Something went wrong. Please try again.</small> : <></> }
                    </div>
                    <div className="form-group">
                      <Button className="col-12" color="primary" disabled={disableSubmit}>
                        Reset my password
                      </Button>
                    </div>
                  </form>
                  <p className="text-center">
                    <small>
                      It's ok. <Link to="/">Cancel</Link>
                    </small>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(ForgotPasswordPage);