import React from 'react';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import {
  Button, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { utils , removeKey } from 'utils';
import { TitleComponent, Loader } from '_components';
import { sidebarModel } from 'models';
import { policyActions } from 'Products/shared/policyListing/actions';
import { SHOW_FORBIDDEN_MESSAGE } from '_constants';
import { store } from '_helpers';
import CancelForm from 'Products/shared/policyListing/components/CancelForm';
import RemarkModal from 'Products/shared/policyListing/components/EndorseForm';
import { DetailTitle, SidebarCard, PaymentCard } from '@xc-core/components';
import { QuotationScriptValidator } from '@xc-core/validation';
import { ProductActions } from '@redux/actions';
import BasePolicyDetail from './basePolicyDetail';
import RenderInsuredDetails from './renderInsuredDetail';
import PolicyEndorsement from './policyEndorsement';
import getQuoteGraphic from '../../images/bannerGraphicImages/get-quote.png';
import EndorsementCheckPriceModel from './EndorsementCheckPriceModeal';
import { objLib } from '@xc-core/lib';


const productActions = new ProductActions();
const Actions = (props) => {
  const {
    policy,
    downloadPolicy,
    reissuePolicy,
    toggleCancelModal,
    ableToDownload,
    ableToReissue,
    ableToCancel,
    toggleDropDown,
    dropdownOpen,
    ableToEndorse,
    toggleEndorseMode,
  } = props;
  return (
    <>
      <Dropdown className='mb-3 text-right' isOpen={dropdownOpen} toggle={toggleDropDown}>
        <DropdownToggle caret className='btn-action btn-action-primary'>
          Actions
        </DropdownToggle>
        <DropdownMenu>
          { ableToDownload(policy) ? <DropdownItem onClick={() => downloadPolicy(policy.id)}>Download Certificate</DropdownItem> : <></>}
          { ableToDownload(policy) === false ? <DropdownItem onClick={() => downloadPolicy(policy.id)}>Download Cancellation Confirmation</DropdownItem> : <></>}
          { ableToReissue(policy) ? <DropdownItem onClick={reissuePolicy}>Reissue Certificate</DropdownItem> : <></> }
          { ableToEndorse(policy) ? <DropdownItem onClick={toggleEndorseMode}>Endorse Certificate</DropdownItem> : <></>}
          { ableToCancel(policy) ? <DropdownItem className='text-danger' onClick={toggleCancelModal}>Cancel Certificate</DropdownItem> : <></>}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
// const RenderHeader = (props) => {
//   const { policy, policy: { common } } = props;
//   return (
//     <>
//       <h5 className='quotationId text-medium-qc font-size-large'>{ `Policy Number: ${policy.refCode}` }</h5>
//       <span>{ `Created by ${common.createdByName}, at ` }</span>
//       {formatDate(common.createdOn, { withTime: true })}
//       <br />
//       <span>{ `Updated by ${common.userUpdatedByName ? common.userUpdatedByName : ''}, at ` }</span>
//       {formatDate(common.userUpdatedOn, { withTime: true })}
//       <p className='m-0'>{`Policy Type: ${policy.policyState}`}</p>
//       <p className='m-0'>{`Status: ${policy.status}`}</p>
//     </>
//   );
// };
const RenderBody = (props) => {
  const { model, policy } = props;
  return (
    <>
      <RenderInsuredDetails
        model={{ insured: model.items.main, beneficiary: model.items.beneficiary }}
        item={policy}
      />
      { /** rendering other insured */ }
      {/* {
        otherModel.items.map((item))
      } */}
    </>
  );
};
const RenderPageButtons = (props) => {
  const {
    toggleEndorseMode,
    handleBackToListing,
    endorseMode,
    submitEndorsementCheck,
    disableSubmit = false,
  } = props;
  return (
    <div className='text-right'>
      { endorseMode
        ? (
          <div>
            <Button
              color='muted'
              className='my-4 mr-4 customLeastbtn'
              onClick={toggleEndorseMode}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              className='my-4 customPrimarybtn'
              onClick={submitEndorsementCheck}
              disabled={disableSubmit}
            >
              Update & Reissue Certificate
            </Button>
          </div>
        ) : (
          <Button
            color='info'
            className='my-4 customInfobtn'
            onClick={handleBackToListing}
          >
            Back to Listing
          </Button>
        )}
    </div>
  );
};
export class PolicyDetail extends BasePolicyDetail {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator(utils.validations.cbValidationObject);
    this.backendValidator = new QuotationScriptValidator();
    this.beneficiaryValidator = new SimpleReactValidator(utils.validations.cbValidationObject);

    this.state = {
      form: props.policy && props.policy.properties, // props.policyForm,
      submittedValues: props.policy && props.policy.properties,
      sidebar: {},
      paymentDetailForm: props.paymentDetailForm,
      initialPaymentDetailForm: props.paymentDetailForm,
      dropdownOpen: false,
      endorseModalIsOpen: false,
      endorseModalPriceConfirmIsOpen: false,
      cancelModalIsOpen: false,
      endorseMode: false,
      sendingRequest: false,
      endorseRemarkIsValid: true,
      errorMessages: [],
      isFinancialEndorsement: false,
    };
  }

  componentWillReceiveProps(newProps) {
    const { endorseModalIsOpen, cancelModalIsOpen, initialPaymentDetailForm } = this.state;
    const { notification, notificationType } = newProps;
    const { policy } = this.props;
    let newState = { ...this.state };
    if (newProps.policy && newProps.policy.properties) {
      if (!policy) {
        newState = {
          ...newState,
          form: {
            ...newProps.policy.properties,
          },
          submittedValues: {
            ...newProps.policy.properties,
          },
        };
      }
      newState = {
        ...newState,
        form: {
          ...newState.form,
          'Payable Price': newProps.policy.properties['Payable Price'],
          Price: newProps.policy.properties.Price,
          priceList: newProps.policy.properties.priceList,
          'Commission Amount': newProps.policy.properties['Commission Amount'],
          'Previous Price': newProps.policy.properties['Previous Price'],
          'Previous Commission': newProps.policy.properties['Previous Commission'],
        },
        paymentDetailForm: {
          ...newProps.paymentDetailForm,
        },
      };
      if (!Object.keys(initialPaymentDetailForm).length && newProps.paymentDetailForm) {
        newState = {
          ...newState,
          initialPaymentDetailForm: newProps.paymentDetailForm,
        };
      }
      this.setState(newState);
    }

    if (this.props.policyDetailBlob === undefined && newProps.policyDetailBlob) {
      const url = window.URL.createObjectURL(new Blob([newProps.policyDetailBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `policy-${newProps.policy.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      this.props.clearBlobPolicy();
    }
    if (notification) this.props.notify(notification, notificationType);
    if (endorseModalIsOpen) this.setState({ endorseModalIsOpen: false, endorseMode: false });
    if (cancelModalIsOpen) this.setState({ cancelModalIsOpen: false });
  }

  async componentDidMount() {
    const backPath = this.props.history.location.pathname.split('/');
    const { props } = this;
    this.setState({
      backPath: [...backPath.slice(0, 2)].join('/'),
      form: props.policy && props.policy.properties, // props.policyForm,
      submittedValues: props.policy && props.policy.properties,
      sidebar: {},
      paymentDetailForm: props.paymentDetailForm,
      initialPaymentDetailForm: props.paymentDetailForm,
      dropdownOpen: false,
      endorseModalIsOpen: false,
      endorseModalPriceConfirmIsOpen: false,
      cancelModalIsOpen: false,
      endorseMode: false,
      sendingRequest: false,
      endorseRemarkIsValid: true,
      errorMessages: [],
      isFinancialEndorsement: false,
    });
    this.setValidator();
    // const country = policy && policy.properties.Country ?
    //   policy.properties.Country : config.products.CB.defaultValues.country;
    // await this.props.getStates(country);
  }

  setValidator = () => {
    const { policy, setValidationModule } = this.props;
    if (policy) setValidationModule(policy.productCode);
  }

  toggleDropDown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  toggleEndorseMode = async () => {
    const { endorseMode } = this.state;
    const {
      policy,
      endorsementPermissions: {
        financialEndorsementPermitted,
        nonFinancialEndorsementPermitted,
      },
    } = this.props;
    if (!financialEndorsementPermitted && !nonFinancialEndorsementPermitted) {
      store.dispatch({ type: SHOW_FORBIDDEN_MESSAGE });
      return;
    }
    if (endorseMode) {
      this.setState((prevState) => ({
        ...prevState,
        form: policy.properties, // .policyForm,
        sidebar: {},
        endorseMode: !prevState.endorseMode,
        errorMessages: [],
        isFinancialEndorsement: false,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        endorseMode: !prevState.endorseMode,
      }));
    }
  } // end of toggleEndorseMode

  toggleEndorseModal = async () => {
    const { endorseModalIsOpen, form } = this.state;
    const { showPriceUpdateModal, togglePriceUpdateModal } = this.props;
    if (showPriceUpdateModal) await togglePriceUpdateModal();
    this.setState((prevState) => (
      {
        ...prevState,
        form: {
          ...form,
          'Endorse Remarks': '',
        },
      }
    ), async () => {
      this.setState((prevState) => (
        {
          ...prevState,
          endorseModalIsOpen: !endorseModalIsOpen,
          endorseRemarkIsValid: true,
        }
      ));
    });
  }

  handleSidebarPairChange = (pairs) => {
    const { isFinancialEndorsement } = this.state;
    if (!isFinancialEndorsement) {
      this.setState({ isFinancialEndorsement: true });
    }
    pairs.forEach((pair) => {
      const id = pair.id.split('.').slice(1).join('.');
      this.setState((prevState) => ({
        sidebar: {
          ...prevState.sidebar,
          [id]: pair.value,
        },
        form: {
          ...prevState.form,
          [id]: pair.value,
        },
      }), () => {
        this.validateWithBackendValidations();
      });
    });
  };

  validateWithBackendValidations = async () => {
    var { form, isFinancialEndorsement } = this.state;
    if (objLib.isEmpty(form.beneficiaries)) {
      form = removeKey(form, 'beneficiaries')
    }
    let errorMessages = this.backendValidator.validate({ properties: { ...form } }, 'Trip Details');
    errorMessages = errorMessages?.filter((errorObj) => !errorObj.message.includes('cannot be a past date'));
    if (isFinancialEndorsement) return this.setState({ errorMessages });
  };

  handleEndorseRemarkChange = (e) => {
    const value = e && e.target && e.target.value;
    this.setState((prevState) => (
      {
        ...prevState,
        form: {
          ...prevState.form,
          'Endorse Remarks': value,
        },
        endorseRemarkIsValid: true,
      }
    ));
  }

  reissuePolicy = async (id) => {
    const isPermitted = await this.props.reissuePermission();
    if (!isPermitted) {
      store.dispatch({ type: SHOW_FORBIDDEN_MESSAGE });
    } else {
      this.props.reissuePolicy(this.props.policy.id);
    }
  }

  handleSubmitPayment = () => {
    const { policy } = this.props;
    const { paymentDetailForm } = this.state;
    this.props.updatePolicy(policy.id, paymentDetailForm, true);
    this.setState({
      initialPaymentDetailForm: paymentDetailForm, // the initial is being used for cancel,
      // and after every submitting this initial
      // should be updated
    });
  }

  handlePaymentInputChange = (e) => {
    this.setState({
      ...this.state,
      paymentDetailForm: {
        properties: { ...this.state.paymentDetailForm.properties, [e.target.id]: e.target.value },
      },
    });
  }

  handleChange = (e, isBeneficiary = false) => {
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;
    if (isBeneficiary) {
      if (value) {
        this.setState((prevState) => (
          {
            ...prevState,
            form: {
              ...prevState.form,
              beneficiaries: {
                ...prevState.form.beneficiaries,
                [key]: value,
              },
            },
          }
        ));
      } else {
        let beneficiaries =  removeKey(this.state.form.beneficiaries , key)
        this.setState((prevState) => (
          {
            ...prevState,
            form: {
              ...prevState.form,
              beneficiaries
            },
          }
        ));
      }
    } else {
      if (key === 'DOB') {
        value = utils.lib.applyConfigFormat(value);
      }
      this.setState((prevState) => (
        {
          ...prevState,
          form: {
            ...prevState.form,
            'main applicant': {
              ...prevState.form['main applicant'],
              [key]: value,
            },
          },
        }
      ));
    }
  }

  handleNumberFormatFields = (e, isBeneficiary = false) => {
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;

    if (isBeneficiary) {
      value = utils.lib.isOnlyNumber(value) || value === '' ? value : this.state.form.beneficiaries[key];
      if (value) {
        this.setState((prevState) => (
          {
            ...prevState,
            form: {
              ...prevState.form,
              beneficiaries: {
                ...prevState.form.beneficiaries,
                [key]: value,
              },
            },
          }
        ));
      } else {
        let beneficiaries =  removeKey(this.state.form.beneficiaries , key)
        this.setState((prevState) => (
          {
            ...prevState,
            form: {
              ...prevState.form,
              beneficiaries
            },
          }
        ));
      }
      
    } else {
      value = utils.lib.isOnlyNumber(value) || value === '' ? value : this.state.form['main applicant'][key];
      this.setState((prevState) => (
        {
          ...prevState,
          form: {
            ...prevState.form,
            'main applicant': {
              ...prevState.form['main applicant'],
              [key]: value,
            },
          },
        }
      ));
    }
  }

  handleSubmitEndorse = async () => {
    const { form } = this.state;
    if (form['Endorse Remarks']) {
      this.setState({ sendingRequest: true });
      try{
      await this.submitEndorseConfirmation(form['Endorse Remarks']);
      } catch(e){

      }
      finally{
        this.setState({ sendingRequest: false });
      }
    } else {
      this.setState({ endorseRemarkIsValid: false });
    }
  }

  handleSubmitEndorsement = async () => {
    await this.validateWithBackendValidations();
    const { policy, checkEndorsement } = this.props;
    const { isFinancialEndorsement, errorMessages } = this.state;
    var beneficiariesChecked = true;
    if (!objLib.isEmpty(this.state.form.beneficiaries)) {
      beneficiariesChecked = this.beneficiaryValidator.allValid();
    } else {
      const newState = removeKey(this.state, 'form.beneficiaries')
      this.setState(newState)
    }
    if (this.validator.allValid()
      && beneficiariesChecked
      && errorMessages.length === 0
    ) {
      this.setState({ sendingRequest: true });
      const response = await checkEndorsement({
        isFinancialEndorsement,
        policy: {
          refId: policy.refId,
          productSlug: policy.productSlug,
          ...this.preparePropertiesForServer(),
        },
      });
      if (!response || response.type !== 'TOGGLE_PRICE_UPDATE_MODAL') {
        this.toggleEndorseModal();
      }
      this.setState({ sendingRequest: false });
    } else {
      this.validator.showMessages();
      this.beneficiaryValidator.showMessages();
    }
    this.setState({ isFinancialEndorsement: false });
  }

  submitEndorseConfirmation = async (remark) => {
    const { endorsementCheckData: { endorsementRequestId }, endorseConfirm } = this.props;
    await endorseConfirm(endorsementRequestId, remark);
  }

  preparePropertiesForServer = () => {
    const { form } = this.state;
    if (objLib.isEmpty(form.beneficiaries)) {
      return {
        properties: {
          ...this.state.sidebar,
          'main applicant': form['main applicant'],
          'Endorse Remarks': form['Endorse Remarks'],
        },
      };
    }
    return {
      properties: {
        ...this.state.sidebar,
        'main applicant': form['main applicant'],
        beneficiaries: form.beneficiaries,
        'Endorse Remarks': form['Endorse Remarks'],
      },
    };
  };

  resetPaymentDetailsForm = () => {
    this.setState((prevState) => ({
      paymentDetailForm: prevState.initialPaymentDetailForm,
    }));
  }

  filterDestinations = () => {
    const { detailCollections, policy } = this.props;
    const { form } = this.state;
    const productName = policy ? policy.productName : '';
    const annualDestinations = ['Asia Pacific & Schengen', 'Worldwide'];
    const destinationList = objLib.getValueWithLodash(detailCollections, 'destinations');
    const tripType = objLib.getValueWithLodash(form, 'Trip Type');
    // const currentDestination = form.Destination;
    let destinations;

    if (productName === 'Destination Cambodia') {
      destinations = destinationList.filter((dest) => dest.code === 'Cambodia');
    } else {
      switch (tripType) {
        case 'Single Trip':
          destinations = destinationList.filter((destination) => {
            const annualDestCambodia = [...annualDestinations, 'Cambodia'];
            return !annualDestCambodia.includes(
              destination.code,
            );
          });
          break;
        case 'Annual':
          destinations = destinationList;
          // destinations = destinationList.filter((destination) => annualDestinations.includes(
          //   destination.code,
          // ));
          break;
        default:
          destinations = destinationList;
          break;
      }
    }

    // if (submittedTripType !== tripType && !destinations.find((destination) => destination.id === currentDestination)) {
    //     this.handleSidebarPairChange([{ id: 'properties.Destination', value: '' }]);
    // }
    return destinations;
  };

  filterPlans = () => {
    const { detailCollections, policy } = this.props;
    const productName = policy ? policy.productName : '';
    const plans = objLib.getValueWithLodash(detailCollections, 'plans');
    const destinationCambodiaPlans = ['Basic', 'Ultimate', 'Ultimate +'];
    const smartTravelerPlans = ['Standard', 'Premium', 'Premium +'];
    if (productName === 'Destination Cambodia') {
      return destinationCambodiaPlans.map(
        (plan) => (plans.find((planItem) => planItem.id === plan)),
      );
    }
    return smartTravelerPlans.map((plan) => (plans.find((planItem) => planItem.id === plan)));
  };

  filterCountriesOfOrigin = () => {
    const { form } = this.state;
    const { detailCollections, policy } = this.props;
    const productName = policy ? policy.productName : '';
    let countriesOfOrigin = objLib.getValueWithLodash(detailCollections, 'countriesOfOrigin');
    const currentCountryOfOrigin = form['Country of Origin'];
    // if (productName !== 'Destination Cambodia') {
    //     countriesOfOrigin = countriesOfOrigin.filter((country) => country.id === 'Cambodia');
    // }
    if (currentCountryOfOrigin !== '' && !countriesOfOrigin.find((country) => country.id === currentCountryOfOrigin)) {
      this.handleSidebarPairChange([{ id: 'properties.Country of Origin', value: '' }]);
    }
    return countriesOfOrigin;
  };

  isEndorsementAllowed = (endorsementType) => {
    const { endorsementPermissions } = this.props;
    if (!endorsementPermissions) return false;
    if (endorsementType === 'non-financial') {
      return endorsementPermissions.nonFinancialEndorsementPermitted;
    }
    return endorsementPermissions.financialEndorsementPermitted;
  };

  render() {
    this.validator.purgeFields();
    this.beneficiaryValidator.purgeFields();

    const {
      policy, model, downloadPolicy, history, detailCollections,
      endorsementCheckData, showPriceUpdateModal, togglePriceUpdateModal, endorsementPermissions,
    } = this.props;
    const {
      form, paymentDetailForm, endorseModalIsOpen, sendingRequest,
      cancelModalIsOpen, endorseMode, backPath, dropdownOpen, submittedValues,
    } = this.state;

    if (!form) return <Loader />;

    const collections = {
      ...detailCollections,
      destinations: this.filterDestinations(),
      plans: this.filterPlans(),
      countriesOfOrigin: this.filterCountriesOfOrigin(),
    };
    return (
      <>
        <div className='container-fluid px-0'>
          <TitleComponent
            title='Certificate Details'
            bannerGraphic={getQuoteGraphic}
            backTitle='Back to listing'
            backPath={backPath}
            history={history}
          />
          <div className='row d-flex justify-content-center my_50' id='quotation-detail'>
            <Col sm={11} className='quotation-detail-wrapper'>
              {
                policy === null ? <div className='loader d-block' />
                  : (
                    <Row>
                      <Col sm={8}>
                        <DetailTitle.Policy policy={policy} />
                        { endorseMode === false || !this.isEndorsementAllowed('non-financial')
                          ? <RenderBody policy={policy} model={model.body} />
                          : (
                            <PolicyEndorsement
                              properties={form}
                              collections={detailCollections}
                              handleChange={this.handleChange}
                              handleNumberFormatFields={this.handleNumberFormatFields}
                              validator={this.validator}
                              beneficiaryValidator={this.beneficiaryValidator}
                            />
                          ) }
                        <RenderPageButtons
                          toggleEndorseMode={this.toggleEndorseMode}
                          // toggleEndorseModal={this.toggleEndorseModal}
                          submitEndorsementCheck={this.handleSubmitEndorsement}
                          handleBackToListing={this.handleBackToListing}
                          disableSubmit={sendingRequest}
                          endorseMode={endorseMode}
                        />
                      </Col>
                      <Col sm={4}>
                        <Actions
                          policy={policy}
                          downloadPolicy={() => downloadPolicy(policy.id)}
                          reissuePolicy={this.reissuePolicy}
                          toggleCancelModal={this.toggleCancelModal}
                          ableToDownload={() => this.downloadable(policy)}
                          ableToReissue={() => this.reissueable(policy)}
                          ableToCancel={() => this.cancelable(policy)}
                          ableToEndorse={() => this.endorseable(policy)}
                          toggleDropDown={() => this.toggleDropDown(policy)}
                          toggleEndorseMode={this.toggleEndorseMode}
                          dropdownOpen={dropdownOpen}
                          endorsementPermissions={endorsementPermissions}
                        />
                        <SidebarCard.Policy
                          model={sidebarModel.policy.travelCB}
                          status={policy.status}
                          item={{ properties: this.state.form, productName: policy.productName, submittedValues,archiveOperation: policy.archiveOperation }}
                          collections={collections}
                          validator={this.validator}
                          endorseMode={endorseMode}
                          handleChange={this.handleSidebarPairChange}
                          errorMessages={this.state.errorMessages}
                          endorsementPermissions={endorsementPermissions}
                          isEndorsementAllowed={this.isEndorsementAllowed}
                        />
                        <PaymentCard
                          validator={this.validator}
                          collections={detailCollections}
                          item={paymentDetailForm.properties}
                          resetPaymentDetailsForm={this.resetPaymentDetailsForm}
                          handleSubmit={this.handleSubmitPayment}
                          handleInputChange={this.handlePaymentInputChange}
                          notification={this.props.notification}
                        />
                      </Col>
                      <RemarkModal
                        properties={form}
                        isOpen={endorseModalIsOpen}
                        toggleModal={this.toggleEndorseModal}
                        handleInputChange={(e) => this.handleEndorseRemarkChange(e)}
                        handleSubmit={this.handleSubmitEndorse}
                        disableSubmit={sendingRequest}
                        endorseRemarkIsValid={this.state.endorseRemarkIsValid}
                      />
                      <EndorsementCheckPriceModel
                        isOpen={showPriceUpdateModal}
                        toggleModal={togglePriceUpdateModal}
                        // handleSubmit={this.submitEndorseConfirmation}
                        handleSubmit={this.toggleEndorseModal}
                        endorsementCheckData={endorsementCheckData}
                        previousPolicy={policy}
                      />
                      <CancelForm
                        isOpen={cancelModalIsOpen}
                        toggleModal={this.toggleCancelModal}
                        properties={form.properties}
                        policy={this.props.policy}
                      />
                    </Row>
                  )
              }
            </Col>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    policy,
    policyForm,
    notification,
    notificationType,
    policyDetailBlob,
    paymentDetailForm,
    detailCollections,
    endorsementCheckData,
    showPriceUpdateModal,
    endorsementPermissions,
  } = state.policyManagement;

  return {
    policy,
    policyForm,
    notificationType,
    notification,
    policyDetailBlob,
    paymentDetailForm,
    detailCollections,
    endorsementCheckData,
    showPriceUpdateModal,
    endorsementPermissions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetail: (policyId) => dispatch(policyActions.getDetail(policyId)),
    reissuePolicy: (policyId) => dispatch(policyActions.reissuePolicy(policyId)),
    updatePolicy: (policyId, properties, updatePayment = false) => dispatch(
      policyActions.updatePolicy(policyId, properties, updatePayment),
    ),
    checkEndorsement: (newPolicy) => dispatch(
      policyActions.checkEndorsement(newPolicy),
    ),
    endorseConfirm: (endorsementRequestId, remark) => dispatch(
      policyActions.endorseConfirm(endorsementRequestId, remark),
    ),
    togglePriceUpdateModal: () => dispatch(policyActions.togglePriceUpdateModal()),
    setValidationModule: (id) => dispatch(productActions.setQuotationValidationModuleInStorage(id)),
    downloadPolicy: (policyId) => dispatch(policyActions.downloadPolicy(policyId)),
    clearBlobPolicy: () => dispatch(policyActions.clearBlobPolicy()),
    endorsePermission: () => dispatch(policyActions.hasPermission('Non-Financial Endorsement of Certificates')),
    cancelPermission: () => dispatch(policyActions.hasPermission('Cancel Certificate')),
    cancelInEffectPermission: () => dispatch(policyActions.hasPermission('Cancellation (In-Effect Certificate)')),
    cancelNotInEffectPermission: () => dispatch(policyActions.hasPermission('Cancellation (Not In Effect Certificate)')),
    reissuePermission: () => dispatch(policyActions.hasPermission('Reissue Certificate')),
    clearPolicyDetail: () => dispatch(policyActions.clearPolicyDetail()),
    getStates: (country) => dispatch(policyActions.getStates(country)),
    notify: (message, type) => dispatch(Notifications.show({ message }, type)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyDetail);
