import actionTypes from '@redux/actionTypes';
import BaseReducer from './base';

interface BPMNState {

}

class BPMNReducer extends BaseReducer<BPMNState> {
    public initialState: BPMNState;
    public actions: IDictionary<(state: BPMNState, action: IAction) => BPMNState>;
    protected actionTypes: BPMNActionTypes;

    constructor() {
        super();
        this.actionTypes = actionTypes.bpmn;
        this.initialState = {
            currentXML: ""
        };
        this.actions = this.createActions();
    }

    createActions = (): IDictionary<(state: BPMNState, action: IAction) => BPMNState> => ({
        [this.actionTypes.SET_CURRENT_XML]: this.setCurrentXML,
    })

    setCurrentXML = (state: IObject, action: any) => {
        const {xml} = action
        return {
            ...state,
            currentXML: xml
        }
    }

    protected get = (state: BPMNState, action: IAction): BPMNState => ({
        ...state,
    })
}

export default new BPMNReducer();
