import React, {FC} from 'react'
import {Button} from "reactstrap";
import AddNewProductLogic from "./AddNewProduct.logic";
import {customStyles} from './style'
//@ts-ignore
import Modal from 'react-modal';
import {Input} from "../../../@xc-core/components/core";

type Props = {
    reload: any
}
export const AddNewProduct: FC<Props> = ({reload}) => {
    const {setShowModal, showModal, nameOfProduct, slugOfProduct, setSlugOfProduct, setNameOfProduct, saveProduct} = AddNewProductLogic(reload);
    return (
        <>
            <Button
                color='primary'
                className='my-4 customPrimarybtn'
                onClick={() => {
                    setShowModal(true)
                }}
                disabled={false}>Add New Product</Button>
            <Modal
                ariaHideApp={false}
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false)
                }}
                style={customStyles}
                contentLabel="AssignProcessToProduct">
                <div className='container-fluid px-0'>
                    <div className='row d-flex justify-content-center my_30'>
                        <div className='col-sm-12'>
                            <h5 className='sub-title'>Add New Product</h5>
                            <div className="col-sm-12">
                                Name:
                                <Input value={nameOfProduct}
                                       id={"name"}
                                       name={"name"} handler={(e: IPair) => {
                                    setNameOfProduct(e.value)
                                }}/>
                            </div>
                            <div className="my-3 col-sm-12">
                                Slug:
                                <Input value={slugOfProduct}
                                       id={"slug"}
                                       name={"slug"} handler={(e: IPair) => {
                                    setSlugOfProduct(e.value)
                                }}/>
                            </div>
                        </div>
                        <Button
                            color='primary'
                            className='my-4 customPrimarybtn'
                            onClick={saveProduct}
                            disabled={false}>Save</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default AddNewProduct