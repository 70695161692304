import React, {useState} from 'react';
import {styles} from "./style";
import DecisionDefinitionList from './DecisionDefinitionList';

export const ActionButtons = ({deploy, loadProcesses,loadDecisionDefinitionByKey}) => {
    return (
        <div style={{
            display: "flex",
            flex: 1,
            margin: 20,
            flexDirection: "row",
        }}>
            <button onClick={deploy} style={styles.button}>Deploy</button>
            <DecisionDefinitionList
                loadDecisionDefinitionByKey={loadDecisionDefinitionByKey}
                    />            
            
        </div>
    )
}
export default ActionButtons;