
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import { dateLib, objLib } from '@xc-core/lib';
import { defaultValues } from '../config';


const CustomerDetail = (props: ILoadableComponentProps) => {
  const {
    model: {
      collections,
      item,
      validationErrors,
      model,
      onChange,
      initValues,
      defaults,
    },
  } = props;
  useEffect(() => {
    const country = objLib.getValueWithLodash(item, model.fields.country.id)
      || defaults.country;
    const pairs: IPair[] = [
      { id: model.fields.country.id, value: country },
      { id: model.fields.dob.id, value: '' },
      { id: model.fields.startDate.id, value: dateLib.subtractFromNow(0, 0, 0) },
      { id: model.fields.endDate.id, value: dateLib.subtractFromNow(0, 0, 0) },
      { id: model.fields.insuredType.id, value: defaultValues.insuredType },
      { id: ['properties', 'No. of Days'], value: 1 },
    ];
    initValues(pairs);
  }, []);

  const filterDestinations = (): IObject[] => {
    const annualDestinations: string[] = ['Asia Pacific & Schengen', 'Worldwide'];
    const destinationList: IObject[] = objLib.getValueWithLodash(collections, 'destinations');
    const tripType = objLib.getValueWithLodash(item, model.fields.tripType.id);
    const countryOfOrigin = objLib.getValueWithLodash(item, model.fields.countryOfOrigin.id);

    switch (tripType) {
      case 'Single Trip':
        if (countryOfOrigin !== 'Cambodia') {
          return destinationList.filter((dest) => dest.code === 'Cambodia');
        }
        return destinationList.filter((destination) => !annualDestinations.includes(
          destination.code,
        ));
      case 'Annual':
        if (countryOfOrigin !== 'Cambodia') {
          return destinationList.filter((dest) => dest.code === 'Cambodia');
        }
        return destinationList.filter((destination) => [...annualDestinations, 'Cambodia'].includes(
          destination.code,
        ));
      default:
        return destinationList;
    }
  };
  return (
    <Row>
      <Col md='12'>
        <h5 className='sub-title'>Please enter Trip information / សូមបំពេញព័ត៌មានអំពីដំណើរកំសាន្</h5>
        <RenderFields
          onChange={(pairs: IPair[]) => onChange('onChange', { pairs })}
          collections={{
            ...collections,
            destinations: filterDestinations(),
          }}
          item={item}
          ext={true}
          validationErrors={validationErrors}
          fields={model.tripDetail.fields}
        />
      </Col>
    </Row>
  );
};

export default CustomerDetail;
