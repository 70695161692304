import React from 'react';
import { connect } from 'react-redux';
import { Message } from '@xc-core/components/core';
import { userMessages } from '@xc-core/constants';
import AlfalahWalletWizard from '@products/AlfalahWallet/wizard';
import CbTravelWizard from '@products/CbTravel/wizard';
import HGITravelWizard from '@products/HGITravel/wizard';
import FlexiPaWizard from '@products/FlexiPa/wizard';
import MedicalInsuranceWizard from '@products/MedicalInsurance/wizard';
import MotorInsuranceWizard from '@products/MotorInsurance/wizard';
import PersonalAccidentWizard from '@products/PersonalAccident/wizard';
// ---------------------------Old Wizards--------------------------------------------------
import { getQuoteActions } from 'Products/shared/GetQuote/actions';
import GetQuoteTravelInsuranceWizard from 'Products/TravelInsurance/GetQuote';
import GetQuoteHreTravelInsuranceWizard from 'Products/HreTravelInsurance/GetQuote';
import GetQuoteCashForHospitalizationWizard from 'Products/CashForHospitalization/getQuote';
import GetQuoteAlfalahTravelInsuranceWizard from 'Products/AlfalahTravelInsurance/GetQuote';
import config from '_config';
//-------------------------------------------------------------------------------------------


// const renderWizards = (props: { product: IProduct, history: any }) => {
const RenderWizards = (props) => {
  const { newGetQuote, history } = props;

  if (!newGetQuote.product) {
    history.push('/getQuote');
    return <></>;
  }

  switch (newGetQuote.product.id) {
    case config.products.travelInsurance.id:
      return <GetQuoteTravelInsuranceWizard {...props} />;
    case config.products.hreTravelInsurance.id:
      return <GetQuoteHreTravelInsuranceWizard {...props} />;
    case config.products.walletInsurance.id:
      return (
        <AlfalahWalletWizard history={props.history} />
      );
    case config.products.cbTravelInsurance.id:
      return <CbTravelWizard history={props.history} />;
    case config.products.flexiPa.id:
      return <FlexiPaWizard history={props.history} />;
    case config.products.cashForHospitalization.id:
      return <GetQuoteCashForHospitalizationWizard {...props} />;
    case config.products.alfalahTravelInsurance.id:
      return <GetQuoteAlfalahTravelInsuranceWizard {...props} />;
    case config.products.hgiTravelInsurance.id:
      return <HGITravelWizard {...props} />;
    case config.products.medicalInsurance.id:
      return <MedicalInsuranceWizard history={props.history}/>
    case config.products.motorInsurance.id:
      return <MotorInsuranceWizard history={props.history}/>
    case config.products.personalAccident.id:
      return <PersonalAccidentWizard history={props.history}/>;
    default:
      return <Message message={userMessages.NO_SALES_JOURNEY} />;
  }
};

const mapStateToProps = (state) => ({
  newGetQuote: state.newGetQuote,
  item: state.getQuote.item, // for old
  selectedProductCode: state.getQuote.selectedProductCode, // for old
});

// --------------for old----------------------------------------------------------------
const mapDispatchToProps = (dispatch) => ({
  getQuotation: (quotationId) => dispatch(getQuoteActions.getQuotation(quotationId)),
  resetQuotation: () => dispatch(getQuoteActions.resetQuotation()),
  getProducts: () => dispatch(getQuoteActions.getProducts()),
});
// --------------------------------------------------------------------------------------

export default connect(mapStateToProps, mapDispatchToProps)(RenderWizards);
