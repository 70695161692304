import React from 'react'
import ProductLogic from './Product.logic'
import {Header, Loader, Table} from "../../@xc-core/components/core";
import bannerImg from "../../images/bannerGraphicImages/organisations.png";
import AssignProcessToProduct from './SubComponent/AssignProcessToProduct'
import {Button, Col} from "reactstrap";
import AddNewProduct from "./SubComponent/AddNewProduct";

export const Product = () => {
    const {isLoading, products, fields, assignProcessesToProduct, currentProduct, isModalOpen, setModalOpen,reload} = ProductLogic();

    return (
        <>
            <Header
                title='Products'
                bannerGraphic={bannerImg}
                withBorderBottom
            />
            {isLoading && <Loader/>}
            <div className='container-fluid px-0'>
                <div className='row d-flex justify-content-center my_30'>
                    <Col sm='12'>
                        <AddNewProduct reload={reload}/>
                    </Col>
                    <div className='col-sm-12'>
                        <Table
                            titles={fields.filter((el:IPair)=>el.id !== 'deletedAt')}
                            records={products}
                            image={""}
                            hasRecords={true}
                            onRowClicked={(row: IObject) => {

                            }}
                            onRowButtonClicked={(row: IObject) => {
                                assignProcessesToProduct(row)
                            }}
                            handleSearchKeyword={(pair: IPair) => {
                            }}
                            searchKeyword={""}
                            handlePageClick={(pair: IPair) => {
                            }}
                            handlePageSizeChange={(pair: IPair) => {
                            }}
                            pagination={{}}
                            buttonIcon='pencil'
                        />
                    </div>
                </div>
            </div>

            <AssignProcessToProduct
                requestToClose={() => {
                    setModalOpen(false)
                }}
                visible={isModalOpen}
                product={currentProduct}/>
        </>
    )
}
export default Product