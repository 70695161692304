import React from 'react';

const CbSmartTravelTable = () => {
    return(
			<div className="benefit-table">
				<p>BENEFITS SCHEDULE CB SMART TRAVELER / អត្ថប្រយោជន៍ CB SMART TRAVELER</p>
        <table>
						<thead className="blue-header">
							<tr>
								<th colSpan={2}>COVERAGES / វិសាលភាពធានារ៉ាប់រង</th>
								<th>STANDARD</th>
								<th>PREMIUM</th>
								<th>PREMIUM +</th>
							</tr>
						</thead>
            <tbody>
							<tr className="mainField">
								<td colSpan={2}>ANNUAL LIMIT MAXIMUM / កំរិតអតិបរិមាប្រចាំឆ្នាំ</td>
								<td className="fixPrice">$50,000</td>
								<td className="fixPrice">$100,000</td>
								<td className="fixPrice">$150,000</td>
							</tr>
							<tr className="seprateField">
								<td colSpan={5}>Personal Legal Liability Benefit / ភារះទទួលខុសត្រួវតាមផ្លូវច្បាប់</td>
							</tr>
							<tr>
                <td className="center">1</td>
								<td>Personal Legal Liability / ភារះទទួលខុសត្រួវតាមផ្លូវច្បាប់</td>
								<td className="fixPrice">$250,000</td>
								<td className="fixPrice">$500,000</td>
								<td className="fixPrice">$750,000</td>
							</tr>
							<tr className="seprateField">
								<td colSpan={5}>Emergency Medical and Evacuation Benefits / ការព្យាបាលជម្ងឺ និងការបញ្ជូនទៅកាន់ទីតាំងសុវត្ថិភាពជាបន្ទាន់</td>
							</tr>
							<tr>
                <td className="center">2</td>
								<td>Emergency Medical Expensess / ការចំណាយផ្នែកវេជ្ជសាស្រ្ដសម្រាប់សង្គ្រោះបន្ទាន់ </td>
								<td className="fixPrice">$50,000</td>
								<td className="fixPrice">$100,000</td>
								<td className="fixPrice">$150,000</td>
							</tr>
							<tr>
                <td className="center">3</td>
								<td>Emergency Medical Evacuation / ការបញ្ជូនទៅកាន់ទីតាំងសុវត្ថិភាពជាបន្ទាន់ </td>
								<td className="fixPrice">$30,000</td>
								<td className="fixPrice">$50,000</td>
								<td className="fixPrice">$50,000</td>
							</tr>
							<tr>
                <td className="center">4</td>
								<td>Mortal Remains Repatriation / ការធ្វើមាតុភូមិនិវត្តន៍នៃសាកសពរបស់អ្នកត្រូវបានធានារ៉ាប់រង</td>
								<td className="fixPrice">$30,000</td>
								<td className="fixPrice">$50,000</td>
								<td className="fixPrice">$50,000</td>
							</tr>
              <tr>
                <td className="center">5</td>
								<td>Compassionate Visit / អ្នករួមដំណើរពេលធ្វើមាតុភូមិនិវត្តន៍</td>
								<td className="fixPrice">$1,000</td>
								<td className="fixPrice">$2,000</td>
								<td className="fixPrice">$3,000</td>
							</tr>
							<tr className="seprateField">
								<td colSpan={5}>Personal Accident Benefit / គ្រោះថ្នាក់បុគ្គល</td>
							</tr>
              <tr>
                <td className="center">6</td>
								<td>Accidental Death &amp; Permanent Disablement / មរណភាពដោយសារគ្រោះថ្នាក់ និង ពិការភាពអចិន្រ្ដៃយ៍</td>
								<td className="fixPrice">$30,000</td>
								<td className="fixPrice">$75,000</td>
								<td className="fixPrice">$120,000</td>
							</tr>
							<tr className="seprateField">
								<td colSpan={5}>Travel Inconvenience Benefits / ភាពរអាក់រអួលនៃការធ្វើដំណើរ </td>
							</tr>
              <tr>
                <td className="center">7</td>
								<td>Trip Cancellation/Trip Curtaliment/Trip Disruption / ការលុបចោល ការ​កាតបន្ថយរយះពេល និង ការរាំងស្ទះក្នុងការធ្វើដំណើរ</td>
								<td className="fixPrice">$3,000</td>
								<td className="fixPrice">$6,000</td>
								<td className="fixPrice">$9,000</td>
							</tr>
              <tr>
                <td className="center">8</td>
								<td>Hijacking / ការប្លន់ក្នុងយន្ដហោះ</td>
								<td className="fixPrice">$2,000</td>
								<td className="fixPrice">$4,000</td>
								<td className="fixPrice">$6,000</td>
							</tr>
              <tr>
                <td className="center">9</td>
								<td>Loss of Baggage and Personal items / ការបាត់បងកាបូបធ្វើដំណើរ និង សម្ភារះផ្ទាល់ខ្លួន</td>
								<td className="fixPrice">$1,500</td>
								<td className="fixPrice">$3,000</td>
								<td className="fixPrice">$6,000</td>
							</tr>
              <tr>
                <td className="center">10</td>
								<td>Loss of Passport and Travel Documents / ការបាត់បង់លិខិតឆ្លងដែន និង ឯកសារសម្រាប់ដំណើរកម្សាន្ដផ្សេងៗ</td>
								<td className="fixPrice">$120</td>
								<td className="fixPrice">$250</td>
								<td className="fixPrice">$500</td>
							</tr>
              <tr>
                <td className="center">11</td>
								<td>Baggage Delayed/Flight Delayed/Diversion <br />(Every 12 consecutive hours) <br/> ការពន្យាពេលនៃកាបូបធ្វើដំណើរ ការពន្យាពេលហោះហើរ ឬការផ្លាស់ប្ដូរជើងហោះហើរ (រៀងរាល់១២ម៉ោងម្ដង)</td>
								<td className="fixPrice">$300</td>
								<td className="fixPrice">$500</td>
								<td className="fixPrice">$1,000</td>
							</tr>
              <tr>
                <td className="center">12</td>
								<td>Loss of Personal Money / ការបាត់បង់ថវិកាផ្ទាល់ខ្លួន </td>
								<td className="fixPrice">$300</td>
								<td className="fixPrice">$500</td>
								<td className="fixPrice">$1,000</td>
							</tr>
              <tr>
                <td className="center">13</td>
								<td>Loss of Credit Card / ការបាត់បង់បណ្ណឥនទាន</td>
								<td className="fixPrice">$200</td>
								<td className="fixPrice">$300</td>
								<td className="fixPrice">$500</td>
							</tr>
              <tr>
                <td className="center">14</td>
								<td>Advanced Payment of Entertainment Ticket / ថ្លៃសេវាសំបុត្រចូលលេងកំសាន្ដ</td>
								<td className="fixPrice">$500</td>
								<td className="fixPrice">$1,000</td>
								<td className="fixPrice">$1,500</td>
							</tr>
              <tr>
                <td className="center">15</td>
								<td>Missed Flight Connections / ការខកខានការបន្តជើងយន្ដហោះហើរ</td>
								<td className="fixPrice">$200</td>
								<td className="fixPrice">$300</td>
								<td className="fixPrice">$500</td>
							</tr>
              <tr>
                <td className="center">16</td>
								<td>Overbooked Flight/Train/Cruise / ការទទួលកក់សំបុត្រលើសកំណត់នៃជើងហោះហើរ​ ឬនាវាចរណ៍ ឬ រថភ្លើង</td>
								<td className="fixPrice">$200</td>
								<td className="fixPrice">$300</td>
								<td className="fixPrice">$500</td>
							</tr>
            </tbody>
						<tfoot>
							<tr>
								<td colSpan={5}>Deductibles: 25 USD each claim under 2, 7, 9, 12 / កម្រិតមិនធ្វើសំណង:២៥​ដុល្លានៃការទាមទារសំណងក្នុងមួយករណី នៅក្រោមចំណុចលេខ ២ ៧​ ៩ ១២</td>
							</tr>
						</tfoot>
        </table>
				
    </div>);
}

export default CbSmartTravelTable;