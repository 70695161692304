import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";

import {is} from "bpmn-js/lib/util/ModelUtil";

Array.prototype.insert = function (index) {
    this.splice.apply(this, [index, 0].concat(
        Array.prototype.slice.call(arguments, 1)));
    return this;
};

export default function (group, element, translate) {
    if (is(element, "bpmn:UserTask")) {
        group.entries.insert(2, entryFactory.link(translate, {
            id: "edit-form",
            buttonLabel: "Edit Form",
            showLink: () => {
                return !!(element && element.businessObject && element.businessObject.formKey);

            },
            handleClick: () => {
                if (element && element.businessObject && element.businessObject.formKey) {
                    window.open(`/#/form-builder/${element.businessObject.formKey}`, "_blank")
                }
            },
            modelProperty: "Edit Form"
        }))
    }
}
