export const styles={
    button:{
        marginLeft:10,
        marginRight:10,
        background:"transparent",
        borderColor:"#ccc",
        borderRadius:5,
        outline:"#ccc",
        minWidth:100,
    },
    mainContainer:{
        display: "flex",
        flex: 1,
        height: "80vh",
    },
    panelProperties:{
        height: "80vh",
    }
}