import { env as legacyEnv } from '_config/env';

const env = {
  tsd: legacyEnv.apiURL,
  quotation: legacyEnv.quotationUrl,
  policy: legacyEnv.policyUrl,
  rate: legacyEnv.productsUrl,
  audit: legacyEnv.auditUrl,
  camundaApiUrl:legacyEnv.camundaApiUrl,
  formBuilderApiUrl: legacyEnv.formBuilderApiUrl,
  newPolicyApiUrl:legacyEnv.newPolicyApiUrl,
  newProductApiUrl:legacyEnv.newProductApiUrl
};

export default env;
