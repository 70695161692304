import React, {useRef, useEffect, useState} from 'react';
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import 'bpmn-js/dist/assets/bpmn-js.css';
import 'bpmn-js/dist/assets/diagram-js.css';
import Modeler from 'bpmn-js/lib/Modeler';
import PropertiesPanelModule from "bpmn-js-properties-panel";
import PropertiesProviderModule from "bpmn-js-properties-panel/lib/provider/camunda";
import CamundaModdleDescriptor from "camunda-bpmn-moddle/resources/camunda";
import {CamundaApi} from './util/CamundaApi'
import {baseXML} from './Constant'
import {BPMNXmlParser} from "./util/BPMNXmllParser";
import {useSelector, useDispatch} from 'react-redux'
import formEditorPropertiesProviderModule from "./extension/provider";
import formEditorModdleDescriptor from "./extension/descriptors/formEditor.json";

export const BPMNLogic = (url, diagramXML) => {
    const bpmnState = useSelector((state) => state.bpmn)
    const dispatch = useDispatch()

    const mainContainerRef = useRef(null);
    const propertiesContainerRef = useRef(null);
    const [bpmnViewer, setBpmnViewer] = useState()
    const initial = () => {
        const container = mainContainerRef.current
        const properties = propertiesContainerRef.current

        const bpmn = new Modeler({
            container: container, additionalModules: [
                PropertiesPanelModule,
                PropertiesProviderModule,
                formEditorPropertiesProviderModule
            ],
            propertiesPanel: {
                parent: properties
            },
            moddleExtensions: {
                camunda: CamundaModdleDescriptor,
                formEditor: formEditorModdleDescriptor
            },
            keyboard: {
                bindTo: window
            },
        })
        setBpmnViewer(bpmn)

    }

    useEffect(() => {
        if (mainContainerRef && mainContainerRef.current) {
            initial();
        }
    }, [])

    useEffect(() => {
        if (bpmnViewer) {
            bpmnViewer.on('import.done', (event) => {
                const {
                    error,
                    warnings
                } = event;
                if (!error) {
                    bpmnViewer.get('canvas').zoom('fit-viewport');
                }

            });

            bpmnViewer.on('commandStack.changed', (event) => {
                saveLocalXml()
            });

            if (diagramXML) {
                bpmnViewer.importXML(diagramXML);
            } else {
                try {
                    const {currentXML} = bpmnState
                    if (currentXML && currentXML.toString().length > 0) {
                        bpmnViewer.importXML(currentXML)
                    } else {
                        bpmnViewer.importXML(baseXML)
                    }
                } catch (e) {
                    bpmnViewer.importXML(baseXML)
                }
            }
        }
    }, [bpmnViewer])

    const getCurrentXML = async () => {
        return await bpmnViewer.saveXML({format: true})
    }

    const deploy = async () => {

        const {xml} = await getCurrentXML();
        const {processId, processName} = BPMNXmlParser(xml);

        CamundaApi().deployXML({
            xml: xml,
            fileName: `${processId}.bpmn`,
            tenantId: undefined,
            deploymentSource: "XC-POC",
        })
            .then(response => response.json())
            .then(result => {
                alert("Deployed as well")
            })
            .catch(error => console.log('error', error));

    }

    const loadProcessByKey = (processId) => {
        CamundaApi().loadProcessXmlBykey(processId)
            .then(response => response.json())
            .then(result => {
                if(result?.type === "RestException"){
                    return;
                }
                const {bpmn20Xml} = result;
                bpmnViewer.importXML(bpmn20Xml);
            })
            .catch(error => console.log('error', error));

    }

    const saveLocalXml = async () => {
        const {xml} = await getCurrentXML();

        dispatch({type: "SET_CURRENT_XML", xml})
    }

    return {
        isLoad: true,
        mainContainerRef,
        propertiesContainerRef,
        loadProcessByKey,
        deploy,
        bpmnViewer,
    }
}
export default BPMNLogic;