import React from 'react'
import {ButtonWithIcon, Filter, Header, Loader, Table} from "../../@xc-core/components/core";
import bannerImg from "../../images/bannerGraphicImages/organisations.png";
import {Col} from "reactstrap";
import {ProcessModelExecutorLogic} from "./ProcessModelExecutor.logic";

export const ProcessModelExecutor = () => {
    const {isLoading, taskList, fields, startProcess} = ProcessModelExecutorLogic();

    return (
        <>
            <Header
                title='Workflow Executor'
                bannerGraphic={bannerImg}
                withBorderBottom
            />
            {isLoading && <Loader/>}
            <div className='container-fluid px-0'>
                <div className='row d-flex justify-content-center my_30'>
                    <Col sm='12' className='my-2 filter-viewType-container'>

                    </Col>
                    <div className='col-sm-12'>
                        <Table
                            titles={fields}
                            records={taskList}
                            image={""}
                            hasRecords={true}
                            onRowClicked={(row: IObject) => {
                                startProcess(row)
                            }}
                            onRowButtonClicked={(row: IObject) => {
                            }}
                            handleSearchKeyword={(pair: IPair) => {
                            }}
                            searchKeyword={""}
                            handlePageClick={(pair: IPair) => {
                            }}
                            handlePageSizeChange={(pair: IPair) => {
                            }}
                            pagination={{}}
                            buttonIcon='pencil'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProcessModelExecutor;