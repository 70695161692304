import Notifications from 'react-notification-system-redux';
import { utils } from 'utils';
import filterUtil from '_components/filter/utils';
import config from '_config';
import { SHOW_FORBIDDEN_MESSAGE, HANDLE_FILTER_CLICKED } from '_constants';
import { policyConstants } from '../constants';
import { policyServices } from '../services';
import { getNotificationMessage } from '../../../../_services';

import { getQuoteServices } from '../../GetQuote/services';
import { listLib } from '../../../../@xc-core/lib';


const clearPolicyDetail = () => ({
  type: policyConstants.CLEAR_POLICY_DETAIL,
});
const resetFilter = () => ({
  type: policyConstants.POLICY_RESET_FILTER,
});

const getStates = (country, params = '') => async (dispatch) => {
  const states = await getQuoteServices.getStates(country, params);
  if (states) { // && states.length
    return dispatch({
      payload: states,
      type: policyConstants.GET_STATES,
    });
  }
};

const resetKeepFilters = () => ({
  type: policyConstants.RESET_KEEP_FILTERS,
});

// function updateList(list, type, notification=null, notificationType=null){
//   return {
//     type: type,
//     list,
//     notification: notification,
//     notificationType: notificationType
//   };
// }

function hasPermission(scope) {
  return async (dispatch) => {
    const permission = await policyServices.hasPermission(scope);
    let isPermitted = true;
    if (!permission.error) {
      if (permission.status !== 'permitted') {
        // dispatch({
        //   type: policyConstants.FORBIDDEN_TO_ENDORSE,
        //   payload: false
        // });
        isPermitted = false;
      }
      return isPermitted;
    }
  };
} // end of has permission

function exportToCsv(exportObjParams) {
  return async () => {
    const response = await policyServices.exportToCsv(exportObjParams);
    utils.lib.exportResponseToFile(response);
  };
}

function resetCsvBlob() {
  return (dispatch) => {
    dispatch({
      type: policyConstants.RESET_CSV_BLOB,
    });
  };
}

function clearBlobPolicy() {
  return (dispatch) => {
    dispatch({
      type: policyConstants.CLEAR_BLOB,
    });
  };
}

function downloadPolicy(policyId) {
  return (dispatch) => policyServices.downloadPolicy(policyId)
    .then((response) => {
      if (!response.error) {
        return dispatch({
          type: policyConstants.DOWNLOAD_POLICY_DETAIL,
          blob: response,
        });
      }
    });
}

const getProducts = () => async (dispatch) => {
  const products = await getQuoteServices.getProducts();
  if (!products.error) {
    const list = products.list.length < 2 ? [...products.list] : [{ name: 'All', refCode: '', id: '' }, ...products.list];
    dispatch({
      type: policyConstants.GET_POLICY_PRODUCTS,
      payload: list,
    });
    return list; // this method is being called in componentDidMount,
    // returning products because we are calling it in the same cycle
    // of getting the current product from props, which is null and
    // will be ready on the next cycle!
  }
  if (products.message === 'Forbidden') return dispatch({ type: policyConstants.SET_FORBIDDEN_POLICIES });
};

function getList(page, perPage, productCode) {
  return async (dispatch) => {
    const list = await policyServices.getPoliciesList(page, perPage, productCode);
    if (!list.erorr) {
      list.filters = filterUtil.addExtraPropertiesToFilters(list.filters);
      return dispatch({
        payload: { list, hasTemplate: true },
        type: policyConstants.GET_POLICIES_LIST,
      });
    }
    if (list.message === 'Policy Template Not Found') {
      dispatch({
        type: policyConstants.CLEAR_POLICY_LIST,
        payload: {
          hasTemplate: false,
        },
      });
    }
    if (list.message === 'Forbidden') {
      dispatch({ type: policyConstants.SET_FORBIDDEN_POLICIES });
    }
  };
}
function getTodayListForAllProducts(params, productCode = '') {
  return async (dispatch) => {
    const response = await policyServices.applyFilter(params, productCode);
    if (!response.error) {
      const filtersWithExtraProperties = filterUtil.addExtraPropertiesToFilters(response.filters);
      const updatedResponse = {
        ...response,
        filters: filterUtil.getTodayFilterOptions(filtersWithExtraProperties),
      };
      return dispatch({
        type: policyConstants.GET_POLICIES_LIST,
        payload: { list: updatedResponse, hasTemplate: true },
      });
    }
    if (response.message === 'Policy Template Not Found') {
      return dispatch({
        type: policyConstants.CLEAR_POLICY_LIST,
        payload: {
          hasTemplate: false,
        },
      });
    }
    if (response.message === 'Forbidden') {
      return dispatch({ type: policyConstants.SET_FORBIDDEN_POLICIES });
    }
  };
}

function prepareCollections(obj) {
  const collections = {};
  if(obj) {
    Object.keys(obj).forEach((key) => {
      if (key === 'currentOuUsers') {
        collections[key] = listLib.extendWithIDValueForKeys(obj[key], ['username', 'name']);
      } else {
        collections[key] = listLib.extendWithIDValueForKeys(obj[key], ['code', 'name']);
      }
    });
  }
  return collections;
}

function getDetail(policyId) {
  return async (dispatch) => {
    const list = await policyServices.getPolicyDetail(policyId);
    let notification = null;
    let notificationType = null;
    if (!list.error) {
      dispatch({
        type: policyConstants.GET_POLICY_DETAIL,
        item: list.item || null,
        collections: prepareCollections(list.collections),
        notification,
        notificationType,
      });

      const permissions = await policyServices.getEndorsementPermissions(policyId);
      // eslint-disable-next-line no-unused-expressions
      !permissions.error && dispatch({
        type: policyConstants.SET_ENDORSEMENT_PERMISSIONS,
        payload: permissions,
      });
    } else {
      notification = list.error;
      notificationType = 'error';
    }
  };
}
function cancelPolicy(policyId, properties) {
  return (dispatch, getState) => {
    const { policy: { status } } = getState().policyManagement;
    return policyServices.cancelPolicy(policyId, properties)
      .then((response) => {
        let notification = 'Policy cancelled';
        let notificationType = 'success';
        if (response && response.error) {
          notification = response.error;
          notificationType = 'error';
        }
        
        if (response.message === 'Forbidden') {
          if (status === 'In Effect') {
            return dispatch({
              type: SHOW_FORBIDDEN_MESSAGE,
              payload: 'You are not allowed to cancel In Effect certificates.',
            });
          }
          return dispatch({
            type: SHOW_FORBIDDEN_MESSAGE,
            payload: 'You are not allowed to cancel Not In Effect certificates.',
          });
        }
        if(response.status == 400){
          dispatch(Notifications.error(getNotificationMessage(response.message)))
          return;
        }

        dispatch(Notifications.show(getNotificationMessage(notification),notificationType))

        return dispatch({
          type: policyConstants.UPDATE_POLICY_DETAIL,
          item: response,
          // notification,
          // notificationType,
        });
      });
  };
}

function updatePolicy(policyId, properties, updatePayment) {
  return (dispatch) => policyServices.updatePolicy(policyId, properties, updatePayment)
    .then((response) => {
      let notification = updatePayment ? 'Payment details updated' : 'Endorsement has been made.';
      let notificationType = 'success';
      if (response && response.error) {
        if (response.message.includes('is not allowed') || response.message.includes('Forbidden')) {
          return dispatch({
            type: SHOW_FORBIDDEN_MESSAGE,
            payload: response.message,
          });
        }
        notification = response.error;
        notificationType = 'error';
      }

      dispatch(Notifications.show(getNotificationMessage(notification), notificationType))

      return dispatch({
        type: policyConstants.UPDATE_POLICY_DETAIL,
        item: response,
        // notification,
        // notificationType,
      });
    });
}

function checkEndorsement({ policy, isFinancialEndorsement }) {
  return async (dispatch) => {
    const endorsementCheckData = await policyServices.financialEndorsementCheck({ policy });
    if (endorsementCheckData.error) {
      if (endorsementCheckData.message.includes('is not allowed')
        || endorsementCheckData.message.includes('permission')
        || endorsementCheckData.status === 403
      ) {
        return dispatch({
          type: SHOW_FORBIDDEN_MESSAGE,
          payload: endorsementCheckData.message,
        });
      }
      return dispatch(Notifications.error({ message: 'error checking endorsement.' }));
    }
    await dispatch({
      type: policyConstants.SET_POLICY_ENDORSEMENT_CHECK_DATA,
      payload: { endorsementCheckData },
    });
    if (isFinancialEndorsement) {
      return dispatch({ type: policyConstants.TOGGLE_PRICE_UPDATE_MODAL });
    }
    return endorsementCheckData;
    // const { endorsementRequestId } = endorsementCheckData;
    // const response = await policyServices.endorseConfirm(endorsementRequestId);
    // dispatch({
    //   type: policyConstants.UPDATE_POLICY_DETAIL,
    //   item: response,
    // });
    // return dispatch(Notifications.success({ message: 'Endorsement has been made.' }));
  };
}

function endorseConfirm(endorsementRequestId, remark) {
  return async (dispatch) => {
      const response = await policyServices.endorseConfirm(endorsementRequestId, remark);
      if (response.status === 403) {
        return dispatch({
          type: SHOW_FORBIDDEN_MESSAGE,
          payload: response.message,
        });
      }
      if (response.status === 400) {
        return dispatch(Notifications.error(getNotificationMessage(response.message)))
      }
      await dispatch({
        type: policyConstants.UPDATE_POLICY_DETAIL,
        item: response,
      });
      return dispatch(Notifications.success({ message: 'Endorsement has been made.' }));
      // return dispatch({ type: policyConstants.TOGGLE_PRICE_UPDATE_MODAL });
  };
}
function togglePriceUpdateModal() {
  return (dispatch) => {
    dispatch({ type: policyConstants.TOGGLE_PRICE_UPDATE_MODAL });
  };
}

function reissuePolicy(policyId) {
  return (dispatch) => {
    dispatch(Notifications.success(getNotificationMessage('Please wait.')));
    policyServices.reissuePolicy(policyId)
      .then((response) => {
        if (response && response.error) {
          // dispatch(Notifications.show({message: response.error}, 'error'));
          return [];
        } if (!response) {
          dispatch(Notifications.show({ message: 'Hiccup' }, 'error'));
          return [];
        }
        dispatch(Notifications.success(getNotificationMessage('Policy reissue was successful.')));
      });
  };
}

function applyFilters(filterObjectParams) {
  const { stringParams } = filterObjectParams;
  return async (dispatch, getState) => {
    const { policyManagement: { currentProduct } } = getState();
    const response = await policyServices.applyFilter(stringParams, currentProduct.id);
    const updatedResponse = { ...response, filter: filterObjectParams.filterObject };
    if (!response.error) {
      dispatch({
        type: policyConstants.POLICIES_APPLY_FILTER,
        payload: updatedResponse,
      });
    }
  };
}
function handleOptionChanged(filterOptions) {
  return {
    type: HANDLE_FILTER_CLICKED,
    payload: { filterOptions },
  };
}

function setApplyTodayPolicyFilter(filterObject) {
  return {
    type: policyConstants.SET_APPLY_TODAY_POLICY_FILTER,
    // payload: filterObject
  }
}

const setCurrentProductToAll = () => {
  return (dispatch, getState) => {
    const { policyManagement: { products } } = getState();
    const allProduct = products.length === 1 ? products[0]
      : products.find((product) => product.refCode === config.products.all.id);
    return dispatch({
      type: policyConstants.SET_CURRENT_POLICY_PRODUCT_TO_ALL,
      payload: {
        currentProduct: allProduct,
      },
    });
  };
};

function changeProduct(productCode) {
  return async (dispatch, getState) => {
    const { policyManagement: { products } } = getState();
    const selectedProductCode = products.find((product) => product.refCode === productCode);
    const list = await policyServices.getPoliciesList(0, 10, productCode);
    if (!list.error) {
      dispatch({ payload: { list, hasTemplate: true }, type: policyConstants.GET_POLICIES_LIST });
      dispatch({ type: policyConstants.POLICY_RESET_FILTER });
      dispatch({ type: policyConstants.CHANGE_CURRENT_POLICY_PRODUCT, payload: { selectedProductCode, hasTemplate: true } });
    } else if (list.message === 'Policy Template Not Found') {
      dispatch({ type: policyConstants.CLEAR_POLICY_LIST });
      dispatch({ type: policyConstants.CHANGE_CURRENT_POLICY_PRODUCT, payload: { selectedProductCode, hasTemplate: false } });
    } else if (list.message === 'Forbidden') {
      dispatch({ type: policyConstants.SET_FORBIDDEN_POLICIES });
    }
  };
}

export const policyActions = {
  getList,
  getDetail,
  applyFilters,
  reissuePolicy,
  updatePolicy,
  cancelPolicy,
  exportToCsv,
  resetCsvBlob,
  downloadPolicy,
  clearBlobPolicy,
  // applyTodayPoliciesFilter,
  hasPermission,
  clearPolicyDetail,
  handleOptionChanged,
  resetFilter,
  changeProduct,
  getProducts,
  getStates,
  resetKeepFilters,
  getTodayListForAllProducts,
  setApplyTodayPolicyFilter,
  setCurrentProductToAll,
  checkEndorsement,
  endorseConfirm,
  togglePriceUpdateModal,
};
