import React, {FC, useState} from 'react';
import getQuoteGraphic from "../../images/bannerGraphicImages/get-quote.png";
import {RenderApplicantInfo} from "../quotationDetail/SubComponent/RenderApplicantInfo";
import {TitleComponent} from '_components';
import {Row, Col, Button} from 'reactstrap';
import {DetailTitle, SidebarCard,PaymentCard} from '@xc-core/components';
import Actions from './SubComponent/ActionButtons'

type Props = {
    policy: any,
    history: any,
    endorsementPermissions: any
}
const sidebarModel = {
    header: {
        productName: {id: 'productName', name: '', config: {}},
        plan: {id: 'properties.Plan', name: '', config: {}},
        items: [
            {
                current: {id: 'properties.Price.Total', name: 'Total Price', config: {currency: true}},
                prev: {id: 'properties.Previous Price.Total', name: '', config: {currency: true, className: 'cross-line gray bold'}},
                config: {},
            },
        ],
    },
    body: {
        items: [
            {
                id: 'Price Breakdown',
                name: 'Price Breakdown',
                items: [
                    {
                        current: {id: 'properties.Price.Rate', name: 'Rate', config: {currency: true}},
                        prev: {id: 'properties.Previous Price.Rate', name: 'Rate', config: {currency: true, className: 'cross-line light-gray'}},
                        config: {currency: true},
                    },
                    {
                        current: {id: 'properties.Price.Driver Fee', name: 'Driver Fee', config: {currency: true}},
                        prev: {id: 'properties.Previous Price.Driver Fee', name: 'Driver Fee', config: {currency: true, className: 'cross-line light-gray'}},
                        config: {currency: true},
                    },
                ],
                config: {},
            },
            {
                id: 'Other Details',
                name: 'Other Details',
                items: [
                    {current: {id: 'properties.Plan', name: 'Plan', type: 'Select', collectionId: 'plans', config: {}}, prev: {config: {}}, config: {}},
                ],
                config: {isEditable: true},
            },
        ],
    },
};

export const PolicyDetail: FC<Props> = ({policy, history, endorsementPermissions}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const {properties} = policy

    const {Price, Beneficiaries, version, meta, "Payable Price": PayablePrice, "Previous Price": PreviousPrice, commissionPriceField, commissionPriceFieldDisplay, ...other} = properties
    const backPath = history.location.pathname.split('/');
    const toggleDropDown = () => {
        setDropdownOpen(!dropdownOpen)
    }
    return (
        <div className='container-fluid px-0'>
            <TitleComponent
                title='Certificate Details'
                bannerGraphic={getQuoteGraphic}
                backTitle='Back to listing'
                backPath={[...backPath.slice(0, 2)].join('/')}
                history={history}
            />

            <div className='row d-flex justify-content-center my_50' id='quotation-detail'>
                <Col sm={11} className='quotation-detail-wrapper'>
                    <Row>
                        <Col sm={8}>
                            <DetailTitle.Policy policy={policy} />
                            <RenderApplicantInfo info={other} title={"Insured Person Details"}/>
                            {Beneficiaries && Beneficiaries.length > 0 && Beneficiaries.map((beneficiary: any, index: number) => {
                                return (<RenderApplicantInfo key={`Beneficiaries${index}`} info={beneficiary} title={`Beneficiaries ${index + 1}`}/>)
                            })}
                        </Col>
                        <Col sm={4}>
                            <Actions
                                policy={policy}
                                downloadPolicy={() => {
                                }}
                                reissuePolicy={() => {

                                }}
                                toggleCancelModal={() => {

                                }}
                                ableToDownload={() => {
                                    return true
                                }}
                                ableToReissue={() => {
                                    return true
                                }}
                                ableToCancel={() => {
                                    return true
                                }}
                                ableToEndorse={() => {
                                    return true
                                }}
                                toggleDropDown={toggleDropDown}
                                toggleEndorseMode={() => {

                                }}
                                dropdownOpen={dropdownOpen}
                                endorsementPermissions={true}
                            />
                            <SidebarCard.Policy
                                model={sidebarModel}
                                status={policy.status}
                                item={{
                                    properties: {...properties, Plan: properties.Plan || "Plan not found"},
                                    productName: policy.productName,
                                    archiveOperation: policy.archiveOperation
                                }}
                                collections={policy}
                                validator={undefined}
                                endorseMode={false}
                                handleChange={() => {

                                }}
                                errorMessages={undefined}
                                isEndorsementAllowed={() => {

                                }}
                            />
                            <PaymentCard
                                validator={undefined}
                                collections={policy}
                                item={properties}
                                resetPaymentDetailsForm={()=>{
                                }}
                                handleSubmit={()=>{

                                }}
                                handleInputChange={()=>{

                                }}
                                notification={undefined}
                            />
                        </Col>
                    </Row>
                </Col>
            </div>
        </div>
    )
}
export default PolicyDetail