import React from "react";
import { connect } from "react-redux";
import Notifications from 'react-notification-system-redux';
import { Button, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';

import { store } from '../../../../_helpers';
import { SHOW_FORBIDDEN_MESSAGE } from '../../../../_constants';
import { policyActions } from "../../../shared/policyListing/actions";
import EditableInsuredDetail from "_components/quotation/InsuredDetail";
import { ageBoundaryRule } from "utils";
import EndorseForm from "../../../shared/policyListing/components/EndorseForm";
import CancelForm from "../../../shared/policyListing/components/CancelForm";
import { TitleComponent } from "_components";
import getGuoteGraphic from "../../../../images/bannerGraphicImages/get-quote.png";
import config from '_config';
import moment from "moment";
import { updateIndicesByType, ktpIdNumberDobInFutureRule } from "utils";
import { sidebarModel, DetailModel } from "models";
import {
  DetailTitle,
  SidebarCard,
  PaymentCard,
  InsuredDetail,
} from '@xc-core/components';
import { TravelTuguConfig } from '@products';
import BasePolicyDetail from "../../../MedicalInsurance/basePolicyDetail";

class PolicyDetail extends BasePolicyDetail {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({
      className: 'text-danger-step',
      messages: {
        email: "That is not a valid email.",
        max: "This field may not be greater than :max:type",
        min: "This field must be at least :min:type",
        alpha: "This field should contain only letters.",
        numeric: "This field should contain numbers only.",
      },
      validators: {
        alpha_num_passport: {
          message: 'The passport may only contain letters and numbers.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[a-z0-9]+$/i);
          },
          required: true
        },
        validate_ktp_id_number: {
          message: 'Invalid KTP number.',
          rule: (val) => {
            if (val === "") return false;
            if (!Number(val)) return false;
            let dayOfBirth = Number(String(val).substr(6, 2));
            const monthOfBirth = String(val).substr(8, 2);
            let yearOfBirth = Number(String(val).substr(10, 2));
            if (!dayOfBirth) return false;
            if (!monthOfBirth) return false;
            if (!yearOfBirth && yearOfBirth !== 0) return false;
            if (monthOfBirth > 12) return false; // the resulting month should not go beyond 12 :)
            if (dayOfBirth > 40) dayOfBirth -= 40; // for female, we have to subtract 40 to use proper date
            if (dayOfBirth > 29 && Number(monthOfBirth) === 2) return false; // february should never have beyond 29 dates

            const maxInsuredAge = 80;
            let maxInsuredYear = Number(String(moment().year() - maxInsuredAge).substr(2, 2));

            if (yearOfBirth < 10) yearOfBirth = `0${yearOfBirth}`;
            if (yearOfBirth < maxInsuredYear) yearOfBirth = Number("20" + String(yearOfBirth));
            else if (yearOfBirth >= maxInsuredYear)yearOfBirth = Number("19" + String(yearOfBirth));

            const date = moment(`${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`);
            if (!date.isValid()) return false; // the whole date should be a valid date
            // if(isDateInFuture(date)) return false;
            // if (moment().year() < yearOfBirth) return false; // year of birth not be in the future
            return true;
          },
          required: true
        },
        idNumber_dob_inFuture: ktpIdNumberDobInFutureRule,
        full_name_valid_characters: {// name the rule
          message: 'This field must contain letters and certain symbols only.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val,/^[a-z\-\'\@\.\ \/\\\u4E00-\u9FCC]+$/gi)
            && validator.helpers.testRegex(val, /[a-zA-Z]/gi);/** at least one alphabetical character */
          },
        },
        custom_required: {
          message: 'This field is required.',
          rule: (val, params, validator) => {
            return !validator.helpers.isBlank(val) && !!val // making sure val is truthy value
          },
          required: true
        },
        validate_age_boundary: {
          message: `Incorrect date of birth for this age group. 
          Please enter a matching date of birth or change the KTP number accordingly.`,
          rule: ageBoundaryRule,
          required: true,
        },
      }
    });

    this.state = {
      form: props.policyForm,
      paymentDetailForm: props.paymentDetailForm,
      initialPaymentDetailForm: props.paymentDetailForm,
      dropdownOpen: false,
      endorseModalIsOpen: false,
      cancelModalIsOpen: false,
      editMode: false,
      sendingRequest: false,
      endorseRemarkIsValid: true,
    }
  }

  componentWillReceiveProps(newProps) {
    const { endorseModalIsOpen, cancelModalIsOpen } = this.state;
    const { notification, notificationType } = newProps;
    if(newProps.policyForm !== undefined) {
      this.setState({
        ...this.state,
        form: {
          ...newProps.policyForm,
        },
        paymentDetailForm: {
          ...newProps.paymentDetailForm,
        }
      });
      if (!Object.keys(this.state.initialPaymentDetailForm).length && newProps.paymentDetailForm) {
        this.setState({initialPaymentDetailForm: newProps.paymentDetailForm})
      }
    }


    if (this.props.policyDetailBlob === undefined && newProps.policyDetailBlob) {
      const url = window.URL.createObjectURL(new Blob([newProps.policyDetailBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `policy-${newProps.policy.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      this.props.clearBlobPolicy();
    }
    if (notification) this.props.notify(notification, notificationType);
    if (endorseModalIsOpen) this.setState(Object.assign({}, { endorseModalIsOpen: false, editMode: false }))
    if (cancelModalIsOpen) this.setState(Object.assign({}, { cancelModalIsOpen: false }))
  }

  async componentDidMount() {
    const backPath = this.props.history.location.pathname.split('/');
    this.setState({backPath: [...backPath.slice(0,2)].join('/')});
    const { policy } = this.props;
    const country = policy && policy.properties.Country ? 
      policy.properties.Country : config.products.travelInsurance.defaultValues.country;
    await this.props.getStates(country, '?collection=state');
  }

  async toggleEditMode() {
    // const isPermitted = await this.props.endorsePermission();
    // if (!isPermitted) {
    //   store.dispatch({ type: SHOW_FORBIDDEN_MESSAGE });
    //   return;
    // }
    const { editMode } = this.state;
    if (editMode) {
      this.setState({
        ...this.state,
        form: this.props.policyForm,
        editMode: !editMode,
      });
    } else {
      this.setState({ ...this.state, editMode: !editMode });
    }
  } // end of toggleEditMode
 async reissuePolicy(id) {
  const isPermitted = await this.props.reissuePermission();
   if(!isPermitted) {
     store.dispatch({type: SHOW_FORBIDDEN_MESSAGE});
     return;
   }else {
    this.props.reissuePolicy(this.props.policy.id);
   }
 } // end of reissuePolicy
  handleDOBChange = (date , idx) => {
  }
  handleDOBInputChange = (date , idx) => {
  }

  handleInputChange = (e, index) => {
    this.setState({
      ...this.state,
      form: {
        properties: { ...this.state.form.properties, [e.target.id]: e.target.value }
      },
      endorseRemarkIsValid: true,
    });
  };

  handlePaymentInputChange = (e) => {
    this.setState({
      ...this.state,
      paymentDetailForm: {
        properties: { ...this.state.paymentDetailForm.properties, [e.target.id]: e.target.value }
      }
    });
  }

  handleInputOtherInsured = (e, index) => {
    const { form } = this.state;

    let newObject = Object.assign([], form.properties['Other Insured'], {
      [index-1]: {
        ...form.properties['Other Insured'][index-1],
        [e.target.id]: e.target.value
      }
    });

    this.setState({
      ...this.state,
      form: {
        ...form,
        properties: {
          ...form.properties,
          'Other Insured': newObject,
        }
      }
    });
  }

  handleInputBeneficiary = (e, index) => {
    const { form } = this.state;

    let newObject = Object.assign([], form.properties['Beneficiaries'], {
      [index]: {
        ...form.properties['Beneficiaries'][index],
        [e.target.name]: e.target.value
      }
    });

    this.setState({
      ...this.state,
      form: {
        ...form,
        properties: {
          ...form.properties,
          'Beneficiaries': newObject,
        }
      }
    });
  }

  renderOtherInsured(properties, mainInsurerIsAdult) {
    const { editMode } = this.state;
    let adultIndex = mainInsurerIsAdult ? 1 : 0;
    let seniorIndex = !mainInsurerIsAdult ? 1 : 0;
    let childIndex = 0;
    const indices = { adult: adultIndex, child: childIndex, senior: seniorIndex };
    if (properties && properties['Other Insured']) {
      return (
        <div>
          { properties['Other Insured'].map((data, index) => {
            const currentId = index + 1;
            return (
              <EditableInsuredDetail
                index = {updateIndicesByType(indices, data.Type)}
                beneficiaries={properties['Beneficiaries'][currentId]}
                beneficiaryIndex={currentId}
                key={index}
                data={data}
                main={false}
                editMode={editMode}
                inputChange={this.handleInputOtherInsured.bind(this)}
                beneficiaryChange={this.handleInputBeneficiary.bind(this)}
                validator={this.validator}
                uniqueId={`other ${currentId}`}
              />
            )
          })
          }
        </div>
      )
    } else {
      return null;
    }
  }

  toggleDropDown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleBackToListing() {
    this.props.history.push(`/policies`);
  }

  handleSubmitPayment = () => {
    const { policy } = this.props;
    const { paymentDetailForm } = this.state;
    this.props.updatePolicy(policy.id, paymentDetailForm, true);
    this.setState({
      initialPaymentDetailForm: paymentDetailForm, // the initial is being used for cancel,
      // and after every submitting this initial
      // should be updated
    });
  }

  handleSubmit = async () => {
    const { policy } = this.props;
    const { form } = this.state;
    if (this.validator.allValid() && form.properties["Endorse Remarks"]) {
      this.setState({sendingRequest: true});
      await this.props.updatePolicy(policy.id, form);
      this.setState({sendingRequest: false, endorseRemarkIsValid: true});
    } else {
      if(form.properties["Endorse Remarks"]) {
        this.validator.showMessages();
        this.toggleEndorseModal();
      }else {
        this.setState({endorseRemarkIsValid: false})
      }
    }
  }

  ableToEndorse = (policy) => {
    if (!policy) return false;
    return ['not in effect', 'in effect'].includes(String(policy.status).toLowerCase()) && ['new', 'endorsement'].includes(String(policy.policyState).toLowerCase());
  }

  ableToReissue = (policy) => {
    if (!policy) return false;
    return ['not in effect', 'in effect'].includes(String(policy.status).toLowerCase()) && ['new', 'endorsement'].includes(String(policy.policyState).toLowerCase());
  }

  ableToCancel = (policy) => {
    if (!policy) return false;
    return ['not in effect', 'in effect'].includes(String(policy.status).toLowerCase()) && ['new', 'endorsement'].includes(String(policy.policyState).toLowerCase());
  }

  ableToDownload = (policy) => {
    if (!policy) return false;
    return (['cancelled'].includes(String(policy.status).toLowerCase())
      && ['cancellation'].includes(String(policy.policyState).toLowerCase())) === false;
  }

  resetPaymentDetailsForm= () => {
    this.setState((prevState) => ({
      paymentDetailForm: prevState.initialPaymentDetailForm,
    }));
  }

  render() {
    const { policy, notificationType, detailCollections } = this.props;
    const { form, paymentDetailForm, endorseModalIsOpen, cancelModalIsOpen, editMode } = this.state;
    let mainInsurerIsAdult;
    if (policy && policy.properties) mainInsurerIsAdult = !!Number(policy.properties.Adults);
    return (
      <div className="container-fluid px-0">
        <TitleComponent 
          title="Certificate Details"
          bannerGraphic={getGuoteGraphic}
          backTitle="Back to listing"
          backPath={this.state.backPath}
          history={this.props.history}
        >
        </TitleComponent>       
        <div className="row d-flex justify-content-center my_50" id="quotation-detail">
          <Col sm={11} className="quotation-detail-wrapper">
            { 
              policy === null ? <div className={"loader d-block"} /> :
                (
                  <Row>
                    <Col sm={8}>
                      <DetailTitle.Policy policy={policy} />
                      {
                        editMode === false ? ( 
                          <InsuredDetail 
                            item={{
                              insureds: [{ ...policy.properties }, ...policy.properties['Other Insured']],
                              beneficiaries: policy.properties.Beneficiaries,
                            }}
                            model={DetailModel.travelTugu}
                            insuredTypes={TravelTuguConfig.insuredTypes}
                          />
                        ) : (
                          <EditableInsuredDetail
                            beneficiaries={form.properties ? form.properties['Beneficiaries'][0] : []}
                            beneficiaryIndex={0}
                            data={form.properties}
                            main={true}
                            editMode={editMode}
                            inputChange={this.handleInputChange.bind(this)}
                            beneficiaryChange={this.handleInputBeneficiary.bind(this)}
                            validator={this.validator}
                            policyProperties={policy ? policy.properties : null}
                            uniqueId="main"
                            mainInsurerIsAdult={mainInsurerIsAdult}
                          />
                        )
                      }
                      { editMode === true ? this.renderOtherInsured(form.properties, mainInsurerIsAdult) : <></> }
                      <div className="text-right">
                        { editMode
                        ? (
                          <div>
                            <Button
                              color="muted"
                              className="my-4 mr-4 customLeastbtn"
                              onClick={this.toggleEditMode.bind(this)}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="primary"
                              className="my-4 customPrimarybtn"
                              onClick={this.toggleEndorseModal.bind(this)}
                            >
                              Update & Reissue Certificate
                            </Button>
                          </div>
                        ) : (
                          <Button
                            color="info"
                            className="my-4 customInfobtn"
                            onClick={this.handleBackToListing.bind(this)}
                          >
                            Back to Listing
                          </Button>
                        )
                        }
                      </div>
                    </Col>
                    <Col sm={4}>
                      { editMode || notificationType === 'error' ? null : (
                        <Dropdown className="mb-3 text-right" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                          <DropdownToggle caret className='btn-action btn-action-primary'>
                            Actions
                          </DropdownToggle>
                          <DropdownMenu>
                            { this.ableToDownload(this.props.policy) ? <DropdownItem onClick={() => this.props.downloadPolicy(policy.id)}>Download Certificate</DropdownItem> : <></>}
                            { this.ableToDownload(this.props.policy) === false ? <DropdownItem onClick={() => this.props.downloadPolicy(policy.id)}>Download Cancellation Confirmation</DropdownItem> : <></>}
                            { this.ableToReissue(this.props.policy) ? <DropdownItem onClick={this.reissuePolicy.bind(this)}>Reissue Certificate</DropdownItem> : <></> }
                            { this.ableToEndorse(this.props.policy) ? <DropdownItem onClick={this.toggleEditMode.bind(this)}>Endorse Certificate</DropdownItem> : <></>  }
                            { this.ableToCancel(this.props.policy) ? <DropdownItem className={'text-danger'} onClick={this.toggleCancelModal.bind(this)}>Cancel Certificate</DropdownItem> : <></>}
                          </DropdownMenu>
                        </Dropdown>
                      )}
                      <SidebarCard.Policy
                        model={sidebarModel.policy.travelTugu}
                        status={policy.status}
                        item={policy}
                      />
                      <PaymentCard 
                        validator={this.validator}
                        resetPaymentDetailsForm={this.resetPaymentDetailsForm}
                        handleSubmit={this.handleSubmitPayment}
                        item={paymentDetailForm.properties} 
                        collections={detailCollections}
                        handleInputChange={this.handlePaymentInputChange}
                        notification={this.props.notification}
                      />
                    </Col>
                    <EndorseForm
                      properties={form.properties}
                      isOpen={endorseModalIsOpen}
                      toggleModal={this.toggleEndorseModal.bind(this)}
                      handleInputChange={this.handleInputChange}
                      handleSubmit={this.handleSubmit.bind(this)}
                      disableSubmit={this.state.sendingRequest}
                      endorseRemarkIsValid={this.state.endorseRemarkIsValid}
                    />
                    <CancelForm
                      isOpen={cancelModalIsOpen}
                      toggleModal={this.toggleCancelModal.bind(this)}
                      properties={form.properties}
                      policy={this.props.policy}
                    />
                  </Row>
                )
            }
          </Col>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.clearPolicyDetail();
  }
}

PolicyDetail.defaultProps = {
  policy: undefined
}

function mapStateToProps(state) {
  const {
    policy,
    policyForm,
    notification,
    notificationType,
    policyDetailBlob,
    paymentDetailForm,
    detailCollections,
  } = state.policyManagement;

  return {
    policy,
    policyForm,
    notificationType,
    notification,
    policyDetailBlob,
    paymentDetailForm,
    detailCollections,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetail: policyId => dispatch(policyActions.getDetail(policyId)),
    reissuePolicy: policyId => dispatch(policyActions.reissuePolicy(policyId)),
    updatePolicy: (policyId, properties, updatePayment=false) => dispatch(policyActions.updatePolicy(policyId, properties, updatePayment)),
    downloadPolicy: policyId => dispatch(policyActions.downloadPolicy(policyId)),
    clearBlobPolicy: () => dispatch(policyActions.clearBlobPolicy()),
    endorsePermission: () => dispatch(policyActions.hasPermission('Non-Financial Endorsement of Certificates')),
    cancelPermission : () => dispatch(policyActions.hasPermission('Cancel Certificate')),
    cancelInEffectPermission: () => dispatch(policyActions.hasPermission('Cancellation (In-Effect Certificate)')),
    cancelNotInEffectPermission: () => dispatch(policyActions.hasPermission('Cancellation (Not In Effect Certificate)')),
    reissuePermission: () => dispatch(policyActions.hasPermission('Reissue Certificate')),
    clearPolicyDetail: () => dispatch(policyActions.clearPolicyDetail()),
    getStates: (country, param) => dispatch(policyActions.getStates(country, param)),
    notify: (message, type) => dispatch(Notifications.show({message}, type)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyDetail);
