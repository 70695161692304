const model = {
  body: {
    items: {
      main: {
        id: 'properties.main applicant',
        items: [
          { id: 'properties.main applicant.Full Name', name: 'Full Name', config: {} },
          { id: 'properties.main applicant.Nationality', name: 'Nationality', config: {} },
          { id: 'properties.main applicant.ID Type', name: 'ID Type', config: {} },
          { id: 'properties.main applicant.ID Number', name: 'ID Number', config: {} },
          { id: 'properties.main applicant.DOB', name: 'DOB', config: {} },
          { id: 'properties.main applicant.Gender', name: 'Gender', config: {} },
          { id: 'properties.main applicant.Address', name: 'Address', config: {} },
          { id: 'properties.main applicant.City', name: 'City / Town', config: {} },
          { id: 'properties.main applicant.State', name: 'State / Province / Region', config: {} },
          { id: 'properties.main applicant.Postcode', name: 'Postcode', config: {} },
          { id: 'properties.main applicant.Country', name: 'Country', config: {} },
          { id: 'properties.main applicant.Email Address', name: 'Email Address', config: {} },
          { id: 'properties.main applicant.Contact Number', name: 'Phone Number', config: {} },
        ],
        config: {},
      },
      beneficiary: {
        id: 'properties.beneficiaries',
        items: [
          { id: 'properties.beneficiaries.Full Name', name: 'Full Name', config: {} },
          { id: 'properties.beneficiaries.Relationship', name: 'Relationship', config: {} },
          { id: 'properties.beneficiaries.ID Type', name: 'ID Type', config: {} },
          { id: 'properties.beneficiaries.ID Number', name: 'ID Number', config: {} },
          { id: 'properties.beneficiaries.Email', name: 'Email Address', config: {} },
          { id: 'properties.beneficiaries.Contact Number', name: 'Phone Number', config: {} },
        ],
        config: {},
      },
    },
    config: {},
  },
};

export default model;
