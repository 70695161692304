import React, {FC} from 'react'
import getQuoteGraphic from "../../images/bannerGraphicImages/get-quote.png";
import {TitleComponent} from '_components';
import {Row, Col, Button} from 'reactstrap';
import {DetailTitle, SidebarCard} from '@xc-core/components';
import {RenderApplicantInfo} from "./SubComponent/RenderApplicantInfo";

type Props = {
    quotation: any,
    history: any
}

const sidebarModel = {
    header: {
        productName: {id: 'productName', name: '', config: {}},
        plan: {id: 'properties.Plan', name: '', config: {}},
        total: {id: 'properties.Price.Total', name: 'Total Price', config: {}},
        config: {},
    },
    body: {
        items: [
            {
                id: 0,
                title: {id: 'Price Breakdown', name: 'Price Breakdown'},
                items: [
                    {id: 'properties.Price.Rate', name: 'Rate', config: {currency: true}},
                    {id: 'properties.Price.Driver Fee', name: 'Driver Fee', config: {currency: true}},
                ],
                config: {},
            },
            {
                id: 2,
                title: {id: 'Other Details', name: 'Other Details'},
                items: [
                    {id: 'properties.Plan Type', name: 'Plan Type', config: {}},
                ],
                config: {},
            },
            {
                id: 3,
                title: {id: 'Payment Details', name: 'Payment Details'},
                items: [
                    {id: 'properties.Mode of Payment', name: 'Mode of Payment', config: {}},
                    {id: 'properties.Receipt No', name: 'Receipt No', config: {}},
                    {id: 'properties.Payment Received By', name: 'Payment Received By', config: {}},
                ],
                config: {},
            },
        ],
    },
};

export const QuotationDetail: FC<Props> = ({quotation, history}) => {
    const {properties} = quotation
    const {Price, Beneficiaries, version, meta, ...other} = properties
    const backPath = history.location.pathname.split('/');

    return (
        <div className='container-fluid px-0'>
            <TitleComponent
                title='Quotation Details'
                bannerGraphic={getQuoteGraphic}
                backTitle='Back to listing'
                backPath={[...backPath.slice(0, 2)].join('/')}
                history={history}
            />
            <div className='row d-flex justify-content-center my_50' id='quotation-detail'>
                <Col sm={11} className='quotation-detail-wrapper'>
                    <Row>
                        <Col sm={8}>
                            <DetailTitle.Quotation quotation={quotation}/>
                            <RenderApplicantInfo info={other} title={"Insured Person Details"}/>
                            {Beneficiaries && Beneficiaries.length > 0 && Beneficiaries.map((beneficiary: any, index: number) => {
                                return (<RenderApplicantInfo key={`Beneficiaries${index}`} info={beneficiary} title={`Beneficiaries ${index + 1}`}/>)
                            })}
                        </Col>
                        <Col sm={4}>
                            <SidebarCard.Quotation
                                item={quotation}
                                model={sidebarModel}
                            />
                        </Col>
                    </Row>
                </Col>
            </div>
        </div>
    )
}
export default QuotationDetail