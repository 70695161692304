//other server keys will be updated
let token = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')).token : ''

const url = '/services/tds';
//const url = 'http://1d734b42.ngrok.io';
const quotationUrl = '/services/quote';
const policyUrl = '/services/policy';
const productsUrl = '/services/rate';
const auditUrl = '/services/audit';
const camundaApiUrl = '/services/data/engine-rest';
const formBuilderApiUrl='/services/data/forms';
const newPolicyApiUrl="/services/insurance"
const newProductApiUrl="/services/insurance"

export const env = {
	apiURL: url,
	apiVersion: `${url}`,
	quotationUrl: quotationUrl,
	policyUrl,
	productsUrl,
	auditUrl,
	camundaApiUrl,
	token,
	formBuilderApiUrl,
	newPolicyApiUrl,
	newProductApiUrl
};
