import React from 'react';

const DestinationCambodiaTable = () => {
    return(
			<div className="benefit-table">
				<p>BENEFITS SCHEDULE DESTINATION CAMBODIA / អត្ថប្រយោជន៍ DESTINATION CAMBODIA</p>
        <table>
						<thead className="blue-header">
							<tr>
								<th colSpan={2} className="text-left">COVERAGES / វិសាលភាពធានារ៉ាប់រង</th>
								<th>BASIC</th>
								<th>ULTIMATE</th>
								<th>ULTIMATE +</th>
							</tr>
						</thead>
            <tbody>
							<tr className="mainField">
								<td colSpan={2}>ANNUAL LIMIT MAXIMUM / កំរិតអតិបរិមាប្រចាំឆ្នាំ</td>
								<td className="fixPrice">$10,000</td>
								<td className="fixPrice">$25,000</td>
								<td className="fixPrice">$50,000</td>
							</tr>
							<tr className="seprateField">
								<td colSpan={5}>Emergency Medical and Evacuation Benefits / ការព្យាបាលជម្ងឺ និងការបញ្ជូនទៅកាន់ទីតាំងសុវត្ថិភាពជាបន្ទាន់</td>
							</tr>
							<tr>
                <td className="center">1</td>
								<td>Emergency Medical Expenses / ការចំណាយផ្នែកវេជ្ជសាស្រ្ដសម្រាប់សង្គ្រោះបន្ទាន់ </td>
								<td className="fixPrice">$10,000</td>
								<td className="fixPrice">$25,000</td>
								<td className="fixPrice">$50,000</td>
							</tr>
							<tr>
                <td className="center">2</td>
								<td>Emergency Medical Evacuation / ការបញ្ជូនទៅកាន់ទីតាំងសុវត្ថិភាពជាបន្ទាន់ </td>
								<td className="fixPrice">$10,000</td>
								<td className="fixPrice">$25,000</td>
								<td className="fixPrice">$50,000</td>
							</tr>
							<tr>
                <td className="center">3</td>
								<td>Emergency Return Home / ការត្រលប់ទៅកាន់មាតុប្រទេសជាបន្ទាន់</td>
								<td className="fixPrice">$1,000</td>
								<td className="fixPrice">$2,000</td>
								<td className="fixPrice">$3,000</td>
							</tr>
              <tr>
                <td className="center">4</td>
								<td>Mortal Remains Repatriation / ការធ្វើមាតុភូមិនិវត្តន៍នៃសាកសពរបស់អ្នកត្រូវបានធានារ៉ាប់រង</td>
								<td className="fixPrice">$10,000</td>
								<td className="fixPrice">$25,000</td>
								<td className="fixPrice">$50,000</td>
							</tr>
              <tr>
                <td className="center">5</td>
								<td>Compassionate Visit / អ្នករួមដំណើរពេលធ្វើមាតុភូមិនិវត្តន៍</td>
								<td className="fixPrice">$1,000</td>
								<td className="fixPrice">$2,000</td>
								<td className="fixPrice">$3,000</td>
							</tr>
							<tr className="seprateField">
								<td colSpan={5}>Travel Inconvenience Benefits / ភាពរអាក់រអួលនៃការធ្វើដំណើរ</td>
							</tr>
              <tr>
                <td className="center">6</td>
								<td>During the Flight / នៅពេលកំពុងធ្វើដំណើរ</td>
								<td colSpan={3}>Coverages begins on the date and time you exit your country of origin. / ការធានារ៉ាប់រងនឺងចាប់ផ្ដើមពីនៅកាលបរិច្ឆេទនិងពេលវេលាដែលអ្នកចាកចេញពីប្រទេសដើមរបស់អ្នក។</td>
							</tr>
            </tbody>
        </table>
    </div>);
}

export default DestinationCambodiaTable;