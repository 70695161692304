import React, {useEffect, useState} from 'react';
import {CamundaApi} from './util/CamundaApi'
import {useHistory} from 'react-router-dom'

export const ProcessModelExecutorLogic = () => {
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory()

    const fields: Array<IPair> = [
        {id: "id", value: "ID"},
        {id: "key", value: "key"},
        {id: "name", value: "name"},
        {id: "category", value: "Category"},
        {id: "deploymentId", value: "Deployment ID"},
        {id: "description", value: "Description"},
        {id: "suspended", value: "Suspended"},
        {id: "tenantId", value: "Tenant ID"},
        {id: "version", value: "Version"},
        {id: "versionTag", value: "Version Tag"},
    ]

    const [taskList, setTaskList] = useState([])

    useEffect(() => {
        CamundaApi()
            .getProcessDefinition()
            .then(async (response) => {
                const data = await response.json()
                setTaskList(data)
                setIsLoading(false)
            }).catch((error) => {
            console.log(error)
        })
    }, [])

    const startProcess = (task: any) => {
        if (task && task.key) {
            history.push(`/process-model-executor/${task.key}`)
        }
    }

    return {
        isLoading,
        taskList,
        fields,
        startProcess
    }
}