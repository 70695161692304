import env from 'env';
import BaseListingService from './baseListing';
import Request from './request';
import Response from './response';

class ProductService extends BaseListingService {
  protected env = env.quotation;

  protected api = 'products';

  protected withApi = '';

  public getList = async (): Promise<any> => {
    const url = `${this.env}/${this.api}/active`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data.list; // response should be a list rather than an object and
    // this list should be removed from response
  };

  public getApiDocumentation = async (): Promise<any> => {
    const url = `${this.env}/${this.api}/api-documentation`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public getQuotationValidationModule = async (id: number): Promise<string> => {
    const url = `${this.env}/${this.api}/${id}/validation-module`;
    const request = new Request();
    const response = new Response(url, request, 'text');
    const result = await response.handle();
    return result.data;
  }

  public newListProduct=async ():Promise<any> =>{
    const url = `${env.newPolicyApiUrl}/products/`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result?.data || []
  }
}

export default ProductService;
