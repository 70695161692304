/* eslint-disable no-case-declarations */
import React from 'react';
import { objLib } from '@xc-core/lib';
import {
  Input, Select, DatePicker, RadioButton,
  SelectMulti, Switch, Checkbox, MultiValueInput,PriceRange
} from '@xc-core/components/core';

interface IFieldProps {
  model: IField
  item: IObject
  collections?: IObject
  onChange: Function
  validationError?: IScriptValidationError | undefined
  validationRules?:string
  validationName?: string
  validationData?: IObject | string,
  validator?: ISimpleValidator,
  inputValue?: string,
  inputHandler?:any
}

const Field = (props: IFieldProps) => {
  const {
    model,
    item,
    collections,
    onChange,
    validationError,
    validationRules,
    validationData,
    validator,
    inputValue,
    inputHandler
  } = props;

  switch (model.type) {
    case 'Input':
      return (
        <Input
          id={model.id}
          name={model.label}
          value={objLib.getValueWithLodash(item, model.id) || ''}
          handler={(pair: IPair) => onChange(model.handler(pair, item))}
          disabled={model.disabled(item)}
          placeholder={model.placeholder}
          validationMessage={validationError ? validationError.message : ''}
          validationRules={validationRules ? validationRules : ""}
          validationData={validationData ? validationData : ''}
          validator={validator}
        />
      );
    case 'Select':
      return (
        <Select
          id={model.id}
          name={model.label}
          value={objLib.getValueWithLodash(item, model.id) || model.defaultValue || ''}
          handler={(pair: IPair) => onChange(model.handler(pair, item))}
          disabled={model.disabled(item)}
          placeholder={model.placeholder}
          collections={objLib.getValueWithLodash(collections, model.collectionId || '')}
          validationMessage={validationError ? validationError.message : ''}
          validationRules={validationRules ? validationRules : ""}
          validationData={validationData ? validationData : ''}
          validator={validator}
        /> 
      );
    case 'DatePicker':
      return (
        <DatePicker
          id={model.id}
          name={model.label}
          value={objLib.getValueWithLodash(item, model.id) || ''}
          handler={(pair: IPair) => onChange(model.handler(pair, item))}
          disabled={model.disabled(item)}
          placeholder={model.placeholder}
          min={model.getMin ? model.getMin(item,model.id) : ''}
          max={model.getMax ? model.getMax(item,model.id) : ''}
          start={objLib.getValueWithLodash(item, model.start || '') || ''}
          end={objLib.getValueWithLodash(item, model.end || '') || ''}
          openTo={model.current || ''}
          validationMessage={validationError ? validationError.message : ''}
        />
      );
    // case 'PlanButton':
    //   return (
    //     <PlanButton
    //       key={getType(plan)}
    //       isSelected={selectedPlan ? getType(plan) === getType(selectedPlan) : false}
    //       type={getType(plan)}
    //       value={numberLib.applyFormat(getValue(plan), config.currencyFormat)}
    //       onClick={() => handler(plan)}
    //     />
    //   );
    case 'RadioButton':
      return <RadioButton
                  id={model.id}
                  name={model.label}
                  value={model.value ? model.value : ''}
                  checked={model.check(model.id,item)}
                  handler={(pair: IPair) => onChange(model.handler(pair, item))}
                  disabled={model.disabled(item)}
                  validationMessage={validationError ? validationError.message : ''}
              />;

    case 'AdvancedSelect':
        return <SelectMulti 
            id={model.id}
            name={model.label}
            value={objLib.getValueWithLodash(item, model.id) || model.defaultValue || ''}
            handler={(pair: IPair) => onChange(model.handler(pair, item))}
            disabled={model.disabled(item)}
            collections={objLib.getValueWithLodash(collections, model.collectionId || '')}
            validationMessage={validationError ? validationError.message : ''}
            validationRules={validationRules ? validationRules : ""}
            validationData={validationData ? validationData : ''}
            validator={validator}
            inputValue={inputValue ? inputValue : ''}
            inputHandler={inputHandler}
            isMulti={model.isMulti}
        />
      case 'Switch':
        return <Switch
            id={model.id}
            name={model.label}
            value={objLib.getValueWithLodash(item, model.id)}
            handler={(pair: IPair) => onChange(model.handler(pair, item))}
            disabled={model.disabled(item)}
            validationMessage={validationError ? validationError.message : ''}
            validationRules={validationRules ? validationRules : ""}
            validationData={validationData ? validationData : ''}
            validator={validator}
        />
      case 'Checkbox':
        return <Checkbox
            id={model.id}
            name={model.label}
            checked={objLib.getValueWithLodash(item, model.id)}
            handler={(pair: IPair) => onChange(model.handler(pair, item))}
            disabled={model.disabled(item)}
            validationMessage={validationError ? validationError.message : ''}
            validationRules={validationRules ? validationRules : ""}
            validationData={validationData ? validationData : ''}
            validator={validator}
        />;
    case 'MultiValueInput':
      const valuesString = objLib.getValueWithLodash(item, model.id);
      const values: string[] = valuesString?.length ? valuesString.split(';') : [];
      return (
        <MultiValueInput
          values={values}
          id={model.id}
          name={model.label}
          handler={(pair: IPair) => onChange(model.handler(pair, item))}
        />
      );
    case 'PriceRange':  
      return (
        <PriceRange
          id={model.id}
          name={model.label}
          value={objLib.getValueWithLodash(item, model.id) || ''}
          handler={(pair: IPair) => onChange(model.handler(pair, item))}
          disabled={model.disabled(item)}
          placeholder={model.placeholder}
          validationMessage={validationError ? validationError.message : ''}
          validationRules={validationRules ? validationRules : ""}
          validationData={validationData ? validationData : ''}
          validator={validator}
          min={model.getMin ? model.getMin(item,model.id) : ''}
          max={model.getMax ? model.getMax(item,model.id) : ''}        
        />
      );
      
    default:
      return <></>;
  }
};

export default Field;
