import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {Route, Switch} from "react-router-dom";
import BPMN from './index'
import DMN from './DMN'

import './style.css'
import bannerImg from "../../images/bannerGraphicImages/organisations.png";
import {Header} from "../../@xc-core/components/core";

export const ProcessModellerStack = (props) => {
    return (
        <>
            <Header
                title='Workflows'
                bannerGraphic={bannerImg}
                withBorderBottom
            />
            <Tabs style={{
                padding:10
            }}>
                <TabList>
                    <Tab>BPMN Diagram</Tab>
                    <Tab>DMN Diagram</Tab>
                </TabList>
                <TabPanel>
                    <BPMN/>
                </TabPanel>
                <TabPanel>
                    <DMN/>
                </TabPanel>
            </Tabs>
        </>
    )
}
export default ProcessModellerStack