import React from 'react';
import * as materialUI from '@material-ui/core';
import {useHistory} from "react-router-dom";

interface IProductButtonProps {
  product: IProduct,
  isSelected: boolean,
  className?: string,
  handler: () => void,
}
const ProductButton = (props: IProductButtonProps) => {
  const history = useHistory()
  const {
    isSelected, handler, className, product,
  } = props;


  return (
    <materialUI.Grid item xs={12} md={4} key={product.id} className={'product-button'}>
      <materialUI.Card
        className={`${isSelected ? 'cardActive' : ''}`}
      >
        <materialUI.CardActionArea
          onClick={product.hasWorkflowQuotation?()=>{
            if(product.steps && product.steps.length>0 && product.steps[0].processId){
              history.push(`/process-model-executor/${product.steps[0].processId}`)
            }
          }: handler}
          data-set={1}
          type='button'
        >
          <div
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              color: '#064e8c',
            }}
          >
            <i className={isSelected ? 'icon-checkedOutline font-size-normal' : ''} />
          </div>
          <materialUI.CardContent
            className='d-flex align-items-center flex-column'
          >
            <div style={{ position: 'relative' }}>
              <div className='product-icon'>
                <i className={`text-white ${product.icon}`} />
              </div>
              <materialUI.Badge
                invisible
                className={`product-badge ${product.isActive ? 'badge-active' : 'badge-inactive'}`}
                variant='dot'
                // eslint-disable-next-line react/no-children-prop
                children={[]}
              />
            </div>
            <h5 className='title m-2'>
              {product.name}
            </h5>
          </materialUI.CardContent>
        </materialUI.CardActionArea>
      </materialUI.Card>
    </materialUI.Grid>
  );
};

export default ProductButton;
