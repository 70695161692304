import React, {useEffect, useRef} from 'react';
import BPMNLogic from "./BPMN.logic";
import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import ActionButtons from "./components/ActionButtons";
import {useParams} from "react-router-dom";
import {styles} from "./components/style";

export const BPMN = () => {
    const {id} = useParams();
    const {
        mainContainerRef,
        propertiesContainerRef,
        deploy,
        loadProcessByKey,
        bpmnViewer
    } = BPMNLogic(undefined, undefined);

    useEffect(()=>{
        let initialLoad = true;
        if(id && initialLoad && bpmnViewer){
            loadProcessByKey(id)
        }
        return ()=>{
            initialLoad = false
        }
    },[id,bpmnViewer])

    return (
        <>
            <ActionButtons
                deploy={deploy}
                loadProcessByKey={loadProcessByKey}
            />
            <div className={"modeler"} style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <div style={styles.mainContainer} ref={mainContainerRef}/>
                <div style={styles.panelProperties} ref={propertiesContainerRef}/>
            </div>
        </>
    )
}
export default BPMN;
