import React from 'react';
import { Row, Col } from 'reactstrap';
import { sidebarModel, DetailModel } from 'models';
import { SidebarCard, InsuredDetail } from '@xc-core/components';
import { TravelAlfalahConfig } from '@products';

const Summary = (props) => {
  const { item } = props.curentThis.props;
  const { properties, productName} = props.curentThis.props.item;
  const { Beneficiaries, 'Other Insured': otherInsured } = properties;

  // const getAlert = () => true;

  return (
    <Row>
      <Col lg='8'>
        <InsuredDetail
          item={{
            insureds: [{ ...properties }, ...otherInsured],
            beneficiaries: Beneficiaries,
          }}
          model={DetailModel.travelAlfalah}
          insuredTypes={TravelAlfalahConfig.insuredTypes}
        />
      </Col>
      <Col lg='4'>
        <SidebarCard.Quotation
          properties={properties}
          data={properties}
          productName={productName}
          model={sidebarModel.quotation.travelAlfalah.getModelWithFilteredSections(properties)}
          item={item}
        />
      </Col>
    </Row>
  );
}

export default Summary;
