import React from 'react';
// @ts-ignore
import {Form} from '@formio/react';
import '../formBuilder/style.css'
import {ProcessModelExecutorLoadFormLogic} from "./ProcessModelExecutorLoadForm.logic";
import {Header, Loader} from "../../@xc-core/components/core";
import bannerImg from "../../images/bannerGraphicImages/organisations.png";
import {Col} from "reactstrap";


export const ProcessModelExecutorLoadForm = () => {
    const {isLoading, formInfo, onSubmit, customEventHandler} = ProcessModelExecutorLoadFormLogic();
    return (
        <>
            <Header
                title={formInfo?.title}
                bannerGraphic={bannerImg}
                withBorderBottom
            />
            <div className='container-fluid'>
                <div className='row d-flex justify-content-center my_30'>
                    <Col sm='12'>
                        {formInfo && !isLoading && <Form
                            onCustomEvent={customEventHandler}
                            onSubmit={onSubmit}
                            form={formInfo}/>}
                    </Col>
                </div>
            </div>
            {isLoading && <Loader/>}
        </>
    );
}
export default ProcessModelExecutorLoadForm