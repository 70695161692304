import actionTypes from '@redux/actionTypes';
import BaseReducer from './base';

interface DMNState {

}

class DMNReducer extends BaseReducer<DMNState> {
    public initialState: DMNState;
    public actions: IDictionary<(state: DMNState, action: IAction) => DMNState>;
    protected actionTypes: DMNActionTypes;

    constructor() {
        super();
        this.actionTypes = actionTypes.dmn;
        this.initialState = {
            currentXML: ""
        };
        this.actions = this.createActions();
    }

    createActions = (): IDictionary<(state: DMNState, action: IAction) => DMNState> => ({
        [this.actionTypes.SET_CURRENT_XML_DMN]: this.setCurrentXML,
    })

    setCurrentXML = (state: IObject, action: any) => {
        const {xml} = action
        return {
            ...state,
            currentXML: xml
        }
    }

    protected get = (state: DMNState, action: IAction): DMNState => ({
        ...state,
    })
}

export default new DMNReducer();
