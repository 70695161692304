import { ProductService } from '@xc-core/services';
import actionTypes from '@redux/actionTypes';
import { productLib } from '@xc-core/lib';
import QuotationScriptValidator from '@xc-core/validation/quotationScriptValidator';
import BaseAction from './base';

class ProductAction extends BaseAction {
  constructor() {
    super();
    this.service = new ProductService();
  }

  protected actions = actionTypes.product;

  protected service: ProductService;

  protected csvExportScope = '';

  protected editScope = '';

  protected createScope = '';

  public setList = () => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.LIST_LOADING));
    try {
      let products = await this.service.getList();
      products = productLib.prepareList(products);

      //FETCH product from new Policy (Insurance)

      let newProducts=await this.service.newListProduct();
      newProducts = productLib.prepareListNewProduct(newProducts);
      products=[...products,...newProducts]

      dispatch(this.createAction<any>(this.actions.LIST_GET, products));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.LIST_SET_FORBIDDEN));
      }
      // if (error.status === 404) {
      //   dispatch(this.createAction(this.actions.LIST_SET_NOT_FOUND));
      // }
      dispatch(this.createAction(this.actions.LIST_FAILED, error));
    }
  }

  public setQuotationValidationModuleInStorage = (id: number) => async (): Promise<void> => {
    let script = '';
    try {
      script = await this.service.getQuotationValidationModule(id);
      const quotationScriptValidator = new QuotationScriptValidator();
      quotationScriptValidator.storeAndAddScript(script);
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
  }
}

export default ProductAction;
