import env from 'env';
import Request from './request';
import Response from './response';
import BaseListingService from './baseListing';

class PolicyService extends BaseListingService {
  protected env = env.policy;

  protected api = 'policies';

  protected withApi = 'with-collections';

  public getDashboard = async (): Promise<any> => {
    const url = `${this.env}/${this.api}/dashboard`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public download = async (id: string): Promise<any> => {
    const url = `${this.env}/${this.api}/download?idOrCode=${id}`;
    const request = new Request();
    const response = new Response(url, request, 'file');
    const result = await response.handle();
    return result.data;
  }

  public async getCollectionList(list: string[]): Promise<IObject> {
    const url = `${this.env}/collections/list`;
    const obj = { groupList: list };
    const request = new Request(obj, 'POST');
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }

  public async getWitnessSignature(refId: string): Promise<any> {
    const url = `${this.env}/${this.api}/witness-image?refCode=${refId}`;
    const request = new Request(null, 'GET');
    const response = new Response(url, request, 'file');
    const result = await response.handle();
    return result.data;
  }
  public async getTemplate(productSlug: string): Promise<any> {
    const url = `${this.env}/template/${productSlug}`;
    const request = new Request();
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
  public async downloadTemplate(productSlug: string,templateName: string): Promise<any> {
    const url = `${this.env}/template/${productSlug}/${templateName}`;
    const request = new Request(null, 'GET');
    const response = new Response(url, request, 'file');
    const result = await response.handle();
    return result.data;
  }
  public async updateTemplate(productSlug: string,templateName: string, smsTemplate: string, emailTemplate: string, certificateTemplate: any): Promise<any> {
    const url = `${this.env}/template/${productSlug}`;
    var formdata:any = new FormData();
    if(smsTemplate){
      formdata.append("new_policy_sms",  smsTemplate);
    }
    if(emailTemplate){
      formdata.append("new_policy_email", emailTemplate);
    }
    if(certificateTemplate){
      formdata.append("policy", certificateTemplate);
    }
    const request = new Request(formdata, 'POST');
    const response = new Response(url, request);
    const result = await response.handle();
    return result.data;
  }
}

export default PolicyService;
