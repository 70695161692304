const getIndex = (id: string): number => {
  const firstIndex = id.indexOf('[');
  const lastIndex = id.indexOf(']');

  const index = id.substr(firstIndex + 1, lastIndex - firstIndex - 1);
  return parseInt(index, 10);
};


const applyIndexToRawId = (index: number, id: string): string => {return id.replace('_#_', `${index}`)};


const applyIndexToFieldsRawId = (index: number, fields: any[]): any[] => (
  fields.map((field: any) => (
    {
      ...field,
      id: applyIndexToRawId(index, field.id),
      // id: field.id.replace('[_#_]', `[${index.toString()}]`),
    }
  ))
);


const addIndexToFields = (fields: Array<IObject>,arrayProp:string ,index:number): IField[]=>{
  // @ts-ignore
  return fields.map((field)=>{
    return {
      ...field,
      id: field.id.replace(arrayProp,`${arrayProp}[${index}]`)
    }
  })
}

// sample : getCorrespondingId('properties.Other Insured[0].ID Type','properties.Other Insured.ID Number')
// sample output : properties.Other Insured[0].ID Number
const getCorrespondingId = (source:string, target: string) : string =>{
  let toReplace = source.split('.').filter(val=> val.includes('[')).map(val =>{
    return {id: val.slice(0,val.indexOf('[')),index: val.slice(val.indexOf('[')+1,val.indexOf(']'))}
  })
  let result = target.split('.');
  toReplace.forEach((val)=>{
    result = result.map((resVal)=>{
      if(resVal === val.id){
        return `${resVal}[${val.index}]`
      } else {
        return resVal;
      }
    })
  })
  return result.join('.');
}



const field = { applyIndexToFieldsRawId, getIndex, applyIndexToRawId, addIndexToFields, getCorrespondingId };

export default field;
