import storage from '@xc-core/storage';


export default abstract class BaseScriptValidator<T extends IObject> implements IScriptValidator {
  constructor() {
    const validation = storage.getItem(this.storageKey);
    if (validation === null) { // initialize the validation object in storage
      storage.setItem(this.storageKey, {});
    }
  }

  protected abstract scriptKey: string;

  private storageKey = 'validations';

  public storeAndAddScript = (script: string) => {
    this.storeScript(script);
    this.addScriptToWindow();
  }

  public validate = (object: T, state = ''): IScriptValidationError[] | null => {
    try {
      console.log('VALIDATION OBJ', object);
      console.log('SATE', state);
      const debug = false;
      const validationResult = window.validateData(object, state, debug);
      console.log('ERROR', validationResult);
      return validationResult;
    } catch (error) {
      console.log('error', error);
      return null;
    }
  }

  public removeFromErrors = (errors: IScriptValidationError[], id: string)
      : IScriptValidationError[] => errors.filter(
    (error: IScriptValidationError) => !error.referral.includes(id),
  )

  private storeScript = (script: string) => {
    let validations = storage.getItem(this.storageKey);
    validations = { ...validations, [this.scriptKey]: script };
    storage.setItem(this.storageKey, validations);
  }

  private addScriptToWindow = () => {
    this.addMomentScript();
    this.addValidationScript();
  }


  private addMomentScript = () => {
    const id = 'validation_moment';
    const script = document.getElementById(id);
    if (!script) {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.id = id;
      scriptElement.defer = true;
      scriptElement.src = 'https://momentjs.com/downloads/moment-with-locales.min.js';
      document.body.appendChild(scriptElement);
    }
  };

  // private addValidationScript = () => {
  //   const id = `validation_${this.scriptKey}`;
  //   const script = document.getElementById(id);
  //   if (!script) {
  //     const scriptElement = document.createElement('script');
  //     scriptElement.type = 'text/javascript';
  //     scriptElement.defer = true;
  //     scriptElement.id = id;
  //     const validation = storage.getItem(this.storageKey);
  //     const scriptValue = validation[this.scriptKey];
  //     scriptElement.innerHTML = scriptValue;
  //     document.body.appendChild(scriptElement);
  //   }
  // }

  private addValidationScript = () => {
    const id = `validation_${this.scriptKey}`;
    const script = document.getElementById(id);
    if (script) script.remove();
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.defer = true;
    scriptElement.id = id;
    const validation = storage.getItem(this.storageKey);
    const scriptValue = validation[this.scriptKey];
    scriptElement.innerHTML = scriptValue;
    document.body.appendChild(scriptElement);    
  }
}
