import React from 'react';
import {Route, Switch} from "react-router-dom";
import Product from './Product'


export const ProductStack = (props: any) => {
    return (
        <Switch>
            <Route exact path='/product' component={Product}/>
        </Switch>
    );
}
export default ProductStack