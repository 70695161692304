import React from 'react';
import { Col, Row } from 'reactstrap';
import RenderFields from '@containers/getQuote/loadableComponents/repo/renderFields';
import lib from 'utils/lib';

const PrepareProduct = (props: ILoadableComponentProps) => {
  const {
    model: {
      collections,
      item,
      validationErrors,
      model,
      onChange,
      initValues,
      defaults,
      next,
    },
  } = props;

  const handleChange = (pairs: IPair[]) => onChange('onChange', { pairs });

  return (
    <Row>
      <Col md='12'>
        <h5 className='sub-title'>
          Please enter Basic details
          {lib.checkIsDemoSite() && '/ '}
        </h5>
        <p className='custom-text-primary'>
          Main Insured Details
          {lib.checkIsDemoSite() && '/ '}
        </p>
        <RenderFields
          onChange={handleChange}
          collections={collections}
          item={item}
          ext={lib.checkIsDemoSite()}
          validationErrors={validationErrors}
          fields={model.mainDetail.fields}
        />
      </Col>
    </Row>
  );
};

export default PrepareProduct;
