import React, { Fragment } from "react";
import { connect } from "react-redux";
import Notifications from "react-notification-system-redux";
import { TitleComponent } from "_components";
import SimpleReactValidator from "simple-react-validator";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
} from "reactstrap";
import { userGroupActions } from "../actions";
import { Accordion } from "../../../_components/Accordion";
import { ModalUi } from "../../../_components";
import { CommonInputTypes } from "../../../_components/CommonInputTypes";
import { getNotificationMessage } from "../../../_services";
import usergroupGraphic from "../../../images/bannerGraphicImages/usergroups.png";
import { hasPermissionTo } from "../../../_actions";

class UserGroupDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupDetails: "",
      collections: "",
      resourceTree: "",
      updatedResourceTree: [],
      updatedResourceTreeObj: [],
      orgName: "",
      deleteModalOpen: false,
      isGroupNameUnique: true,
      disableSubmit: false,
    };

    this.validator = new SimpleReactValidator({
      className: "text-danger",
      messages: {
        email: "That is not an email.",
      },
      validators: {
        unique_group_name: {
          message: "Group name already exists.",
          rule: () => this.state.isGroupNameUnique,
        },
      }
    });
  }

  componentDidMount() {
    const { history, match: {params}, getDetails, getCreateGroupDetail } = this.props;
    let componentType;
    if (params.name !== "CreateGroup") {
      getDetails(params.name);
      componentType = "userGroupDetails";
    } else {
      getCreateGroupDetail();
      componentType = "createUserGroup";
    }
    const backPath = history.location.pathname.split("/");
    this.setState({
      componentType,
      backPath: [...backPath.slice(0, 2)].join("/"),
    });
  }

  componentWillReceiveProps(props) {
    if (!props.userGroupDetails) return;
    this.setState({
      groupDetails: props.userGroupDetails.item,
      collections: props.userGroupDetails.collections,
      resourceTree: props.userGroupDetails.resourceTree,
      // orgName: !Array.isArray(props.userGroupDetails.item) ? props.userGroupDetails.item.extendedProperties.orgUnitType : ''
    });
    if (!Array.isArray(props.userGroupDetails.item)) {
      this.setState((prevState) => {
        return {
          groupDetails: {
            ...prevState.groupDetails,
            "orgName": props.userGroupDetails.item.extendedProperties.orgUnitType
          }
        }
      });
    }

    if (props.notification === "success") {
      let notificationMessage = {
        // uid: 'once-please', // you can specify your own uid if required
        title: "Organisation Created Successfully",
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Close",
          callback: () => console.log("close")
        }
      };

      this.props.notifications(notificationMessage, props.notification);
      setTimeout(() => {
        this.props.history.push("/userGroup");
      }, 900);
    } else if (props.notification === "info") {
      let notificationMessage = {
        // uid: 'once-please', // you can specify your own uid if required
        title: "User Group Details updated Successfully",
        position: "tr",
        autoDismiss: 5,
        action: {
          label: "Close",
          callback: () => console.log("close")
        }
      };
      this.props.notifications(notificationMessage, props.notification);
      setTimeout(() => {
        this.props.removeNotification();
      }, 1200);
    }
  }

  handleInputChange = (event, val) => {
    const {groupDetails, updatedResourceTree} = this.state;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "name") {
      this.setState({ isGroupNameUnique: true });
    }

    if (val === "resourceTree") {
      updatedResourceTree[name] = value;
      let createObj = {scope: name, status: value};
      this.setState({
        updatedResourceTree,
        updatedResourceTreeObj: [
          ...this.state.updatedResourceTreeObj,
          createObj
        ]
      });
    } else {
      groupDetails[name] = value;
      this.setState({
        groupDetails
      });
    }
  }

  onSubmit = async (e) => {
    const { createGroups, updateGroupDetails, match: { params } } = this.props;
    const { groupDetails, updatedResourceTreeObj } = this.state;

    if (this.validator.allValid()) {
      this.setState({ disableSubmit: true });
      const userGroupDetails = {
        name: groupDetails.name,
        dataScope: groupDetails.dataScope
          && groupDetails.dataScope.length ? groupDetails.dataScope : null,
        extendedProperties: {
          orgUnitType:
            groupDetails.orgName !== undefined
              ? groupDetails.orgName
              : groupDetails.extendedProperties.orgUnitType,
        },
        resourceTree: updatedResourceTreeObj,
      };
      if (params.name !== "CreateGroup") {
        await updateGroupDetails(params.name, userGroupDetails);
        this.setState({ disableSubmit: false });
        this.closeForm();
      } else {
        const isCreated = await createGroups(userGroupDetails);
        this.setState({ disableSubmit: false, isGroupNameUnique: isCreated });
        if (isCreated) {
          this.closeForm();
        } else {
          this.validator.showMessages();
          this.forceUpdate();
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    e.preventDefault();
  };

  closeForm = () => {
    const emptyState = {
      groupDetails: "",
      collections: "",
      resourceTree: "",
      updatedResourceTree: [],
      updatedResourceTreeObj: [],
      orgName: ""
    };
    this.setState(emptyState);
    this.props.history.push('/userGroup');
  };

  toggleDeleteModal = async () => {
    const hasPermission  = await this.props.hasPermissionTo("Delete Group");
    hasPermission && this.setState(prevState => ({deleteModalOpen: !prevState.deleteModalOpen}));
  };

  deleteUserGroup = async () => {
    const {history, notifications, userGroupDetails: {item: {name}}} = this.props;
    const deletedUserGroup = await userGroupActions.deleteUserGroup(name);
    if (deletedUserGroup.status === "success") {
      history.push('/userGroup');
    } else notifications(getNotificationMessage("something when wrong!"), "error");
  };

  render() {
    const { groupDetails, collections, componentType, deleteModalOpen, disableSubmit } = this.state;
    let { resourceTree } = this.state;
    if (resourceTree && !Array.isArray(resourceTree)) resourceTree = resourceTree.children;
    return (
      <div className="container-fluid px-0">
        <TitleComponent
          title={componentType === "userGroupDetails" ? "Edit - User Group Details" : "Create - User Group"}
          bannerGraphic={usergroupGraphic}
          backTitle="Back to listing"
          backPath={this.state.backPath}
          history={this.props.history}
        >
        </TitleComponent>
        <Row className="justify-content-center my_50">
          <Col sm="10">
            <Card className="rounded-xl border-0 shadow-sm">
              {groupDetails === undefined ? (
                <div
                  className={
                    groupDetails === undefined ? "loader d-block" : "d-none"
                  }
                />
              ) : (
                <Fragment>
                  <CardBody className="p-3">
                    <h6 className="text-primary card-title sub-title-container">
                      User Group Details
                    </h6>
                    <Row>
                      <Col md={4}>
                        <CommonInputTypes
                          idName="name"
                          group={false}
                          type="input"
                          placeholder="Enter group name"
                          value={groupDetails ? groupDetails.name : ""}
                          onChange={this.handleInputChange}
                          title="User Group Name"
                          id="1"
                          validator={this.validator}
                          validationRules="unique_group_name"
                        />
                      </Col>
                      <Col md={4}>
                        <CommonInputTypes
                          idName="orgName"
                          group={false}
                          placeholder="Enter Organisation Name"
                          option={
                            collections !== "" ? collections.orgUnitType : []
                          }
                          type="select"
                          title="Organisation Type"
                          value={groupDetails.orgName}
                          onChange={this.handleInputChange}
                          id="2"
                          disabled={false}
                          validator={this.validator}
                        />
                      </Col>
                      <Col md={4}>
                        <CommonInputTypes
                          idName="dataScope"
                          group={false}
                          option={collections !== "" ? collections.dataScope : []}
                          type="select"
                          title="Managed by"
                          value={groupDetails.dataScope || ""}
                          onChange={this.handleInputChange}
                          id="3"
                          disabled={false}
                          validator={this.validator}
                          optional={true}
                        />
                      </Col>
                    </Row>
                    {componentType === "userGroupDetails" && <Row>
                      <Col className="d-flex justify-content-end">
                        <Button
                          onClick={this.toggleDeleteModal}
                          className="bg-transparent border-danger text-danger"
                        >
                          <i className="icon-garbage d-inline-block mr-2"/>
                          <span>Delete</span>
                        </Button>
                      </Col>
                    </Row>}
                  </CardBody>
                </Fragment>
              )}
            </Card>
          </Col>
        </Row>
        {groupDetails !== "" && (
          <Row className="justify-content-center my_50">
            <Col sm="10">
              <Card className="rounded-xl border-0 shadow-sm ">
                <CardBody className="p-3">
                  <h6 className="text-primary card-title sub-title-container">
                    Permission Settings
                  </h6>
                  {resourceTree !== undefined && (
                    <Fragment>
                      {resourceTree.map((data, key) => (
                        <Accordion
                          key={key}
                          headerComponent={`${data.name} (${data.children.length})`}
                          containerClasses={'shadow-none'}
                          bodyComponent={data.children.map((val, key) => (
                            <FormGroup row key={key} className={"mt-2 align-items-center mx-3"}>
                              <Col sm={10}>
                                <p className="text-secondary">{val.name}</p>
                              </Col>
                              <Col sm={2}>
                                <CommonInputTypes
                                  group={false}
                                  idName={val.name}
                                  option={collections.permissionStatuses}
                                  type="select"
                                  title=""
                                  value={
                                    this.state.updatedResourceTree[val.name] ?
                                      this.state.updatedResourceTree[val.name] : val.permissionStatus
                                  }
                                  onChange={(e) => this.handleInputChange(e,"resourceTree")}
                                  id="6"
                                  disabled={false}
                                  validator={""}
                                />
                              </Col>
                            </FormGroup>
                          ))}
                        />
                      ))}
                    </Fragment>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <Col sm="11">
          <div className="row justify-content-end mb-5" id="modalButton">
            <div className="col-2 text-center">
              <Button className="text-info" color="link" onClick={this.closeForm}>
                Cancel
              </Button>
            </div>
            <div className="col-2">
              <Button
                color="primary"
                onClick={this.onSubmit}
                className="fullWidth rounded-xl"
                disabled={disableSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </Col>
        {/* *********************************DELETE GROUP MODAL***************************** */}
        {componentType === "userGroupDetails" && (
          <div>
            <ModalUi
              isOpen={deleteModalOpen}
              toggle={this.toggleDeleteModal}
              className="my-auto"
            >
              <p>Are you sure you want to delete this user group?</p>
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button onClick={this.toggleDeleteModal} className="mr-2 bg-transparent text-dark border-0">No</Button>
                  <Button onClick={this.deleteUserGroup} className="btn-primary">Delete</Button>
                </Col>
              </Row>
            </ModalUi>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    userGroupDetails,
    notification,
  } = state.userGroupManagement;
  return {
    userGroupDetails,
    notification,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetails: (name) => dispatch(userGroupActions.getUserGrpupDetails(name)),
    getCreateGroupDetail: () => dispatch(userGroupActions.getCreateGroupDetail()),
    createGroups: (data) => dispatch(userGroupActions.createGroup(data)),
    hasPermissionTo: (scope) => dispatch(hasPermissionTo(scope)),
    updateGroupDetails: (name, data) => dispatch(userGroupActions.updateGroupDetails(name, data)),
    notifications: (message, type) => dispatch(Notifications.show(message, type)),
    removeNotification: () => dispatch(userGroupActions.updateNotification()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserGroupDetails);
