import React, {useState, useEffect} from 'react'
import {FormBuilderApi} from "./util/FormBuilderApi";
import {useParams} from "react-router-dom";


export const FormBuilderLogic = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [form, setCurrentForm] = useState(undefined);
    const [formList, setFormList] = useState();
    let {formName} = useParams();

    useEffect(() => {
        if (formName === undefined) {
            setIsLoading(false)
        } else {
            setIsLoading(true)
            //load data
            loadForm(formName)
        }
    }, [formName])

    const loadForm = (formName) => {
        FormBuilderApi()
            .loadForm(formName)
            .then(async (response) => {
                const data = await response.json();
                setCurrentForm(data)
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
            })
    }
    
    const saveForm = (form) => {
        const {name, path, title, display} = form;
        if (name === undefined) {
            alert("error form name its required")
            return;
        }
        FormBuilderApi()
            .saveForm(name, form)
            .then((response) => {
                alert("form saved as well")
                console.log(response.data)
            })
            .catch((error) => {
                console.log("something went wrong...", error)
            })
    }

    const getFormList= async () =>{
        FormBuilderApi().getFormList().then(async (res)=>{
            const data = await res.json();
            setFormList(data);
        }).catch((error) => {
            console.log("something went wrong...", error)
        })
    }

    const getCurrentForm = () => {
        if (formName === undefined) {
            return {display: 'form'}
        } else {
            if (form) {
                return form;
            } else {
                return {display: 'form'}
            }
        }
    }

    return {
        isLoading: isLoading,
        saveForm,
        getCurrentForm,
        getFormList,
        formList
    }
}
