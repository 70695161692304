/* eslint-disable object-curly-newline */
import baseFields from '../base-fields';

const quotation = {
  ...baseFields.quotation,
  gender: { ...baseFields.quotation.gender, id: 'properties.Gender' },
  advisedTo: { id: 'properties.Questionnaire.Advised to', label: '2. Abnormal Health Results:', type: 'Hidden' },
  treatmentFor: { id: 'properties.Questionnaire.Treatment for', label: '1. Health Risks:', type: 'Hidden' },
  declinedInsuranceCoverage: { id: 'properties.Questionnaire.Declined Insurance Coverage', label: '3. Insurance Declined/Postponed/Loading:', type: 'Hidden' },
  insuranceBenefitClaim: { id: 'properties.Questionnaire.Insurance Benefit Claim', label: '4. Critical Illness/DisabilityClaim:', type: 'Hidden' },
  HazardousPursuit: { id: 'properties.Questionnaire.Hazardous Pursuit', label: '', type: 'Hidden' },
  witness: { id: 'properties.Witness', label: '', type: 'Hidden' },
  witnessName: { id: 'properties.Witness.Full Name', label: 'Full Name of Witness', type: 'Input', placeholder: 'Witness’s full name as per NRIC' },
  witnessNric: { id: 'properties.Witness.NRIC', label: 'NRIC No. of Witness', type: 'Input', placeholder: 'Witness’s NRIC No.' },
  witnessSign: { id: 'properties.Witness.Signature', label: '', type: 'Signature' },
  witnessSingBlob: { id: 'WitnessSignatureBlob', label: '', type: 'Hidden' },
  witnessMobileBody: { id: 'properties.Witness.Mobile No', label: '', type: 'Mobile', placeholder: '1234567890', collectionId: 'phone_code' },
  witnessMobileCountryCode: { id: 'properties.Witness.Mobile No Country Code', label: '', type: 'Hidden' },
  witnessMobile: { id: 'witnessMobile', label: 'Phone Number', type: '' },
  nominees: { id: 'properties.Nominees', label: '', type: 'Array' },
  nomineeName: { id: 'properties.Nominees[_#_].Full Name', label: 'Full Name', type: 'Input', placeholder: 'Your nominee’s full name as per NRIC' },
  nomineeNationality: { id: 'properties.Nominees[_#_].Nationality', label: 'Nationality', type: 'Select', collectionId: 'nationality' },
  nomineeNationalityName: { id: 'Nominees[_#_].Nationality', label: 'Nationality', type: 'Select', collectionId: 'nationality' },
  nomineeIdType: { id: 'properties.Nominees[_#_].ID Type', label: 'ID Type', type: 'Select', collectionId: 'id_type' },
  nomineeIdNumber: { id: 'properties.Nominees[_#_].ID Number', label: 'ID Number', type: 'Input', placeholder: 'Your nominee’s ID Number' },
  nomineeDob: { id: 'properties.Nominees[_#_].DOB', label: 'DOB', type: 'DatePicker' },
  nomineeReligion: { id: 'properties.Nominees[_#_].Religion', label: 'Religion', type: 'Select', collectionId: 'religion' },
  nomineeEmail: { id: 'properties.Nominees[_#_].Email Address', label: 'Email Address', type: 'Input', placeholder: 'Your nominee’s email' },
  nomineeCountry: { id: 'properties.Nominees[_#_].Country', label: 'Country', type: 'Input', placeholder: '' },
  nomineeAddress: { id: 'Nominees[_#_].Address', label: 'Residential Address', type: 'Input', placeholder: '' },
  nomineeAddress1: { id: 'properties.Nominees[_#_].Address1', label: '', type: 'Input', placeholder: 'Line 1' },
  nomineeAddress2: { id: 'properties.Nominees[_#_].Address2', label: '', type: 'Input', placeholder: 'Line 2 (Optional)' },
  nomineeCity: { id: 'properties.Nominees[_#_].City', label: 'City', type: 'Input', placeholder: 'Your city' },
  nomineeState: { id: 'properties.Nominees[_#_].State', label: 'State', type: 'Select', collectionId: 'state' },
  nomineeStateName: { id: 'Nominees[_#_].StateName', label: 'State/Province/Region', type: 'Select', collectionId: 'state' },
  nomineePostcode: { id: 'properties.Nominees[_#_].Postcode', label: 'Postcode', type: 'Input', placeholder: 'Your postcode' },
  nomineeRelationship: { id: 'properties.Nominees[_#_].Relationship', label: 'Relationship', type: 'Select', collectionId: 'relationship' },
  nomineeRelationshipName: { id: 'Nominees[_#_].Relationship Name', label: 'Relationship', type: 'Hidden' },
  nomineeCreditPercentage: { id: 'properties.Nominees[_#_].Credit Percentage', label: '% Share', type: 'Input', placeholder: 'Your credit percentage' },
  nomineeMobileBody: { id: 'properties.Nominees[_#_].Mobile No', label: '', type: 'Hidden', placeholder: '1234567890', collectionId: 'phone_code' },
  nomineeMobileCountryCode: { id: 'properties.Nominees[_#_].Mobile No Country Code', label: '', type: 'Hidden' },
  nomineeMobile: { id: 'Nominees[_#_].Mobile No', label: 'Phone Number', type: '' },
};

const policy = { ...baseFields.policy, ...quotation };

export default { quotation, policy };