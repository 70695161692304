const componentsToHide = [
    {
        key: 'labelPosition',
        ignore: true,
    },
    {
        key: 'widget.type',
        ignore: true,
    },
    {
        key: 'inputMask',
        ignore: true,
    },
    {
        key: 'displayMask',
        ignore: true,
    },
    {
        key: 'allowMultipleMasks',
        ignore: true,
    },
    {
        key: 'customClass',
        ignore: true,
    },
    {
        key: 'prefix',
        ignore: true,
    },
    {
        key: 'suffix',
        ignore: true,
    },
    {
        key: 'hidden',
        ignore: true,
    },
    {
        key: 'hideLabel',
        ignore: true,
    },
    {
        key: 'showWordCount',
        ignore: true,
    },
    {
        key: 'showCharCount',
        ignore: true,
    },
    {
        key: 'autofocus',
        ignore: true,
    },
    {
        key: 'spellcheck',
        ignore: true,
    },
    {
        key: 'disabled',
        ignore: true,
    },
    {
        key: 'tableView',
        ignore: true,
    },
    {
        key: 'modalEdit',
        ignore: true,
    },
    {
        key: 'hideInput',
        ignore: true,
    },
    {
        key: 'mask',
        ignore: true,
    },
    {
        key: 'tabindex',
        ignore: true,
    },
    {
        key: 'autocomplete',
        ignore: true,
    },
];

export const FormBuildOptions ={
    builder: {
        custom: {
            title: 'Custom Component',
            weight: 10,
            components: {
                ekyc: {
                    title: 'EKYC',
                    key: 'Ekyc',
                    icon: 'terminal',
                    schema: {
                        label: 'EKYC',
                        type: 'ekyc',
                        key: 'Ekyc',
                        input: false
                    }
                },
            }
        },
        advanced: false,
        premium:false,
        data: false,
    },
    editForm: {
        ekyc: [
            {
                key: 'display',
                components: componentsToHide,
            },
            {
                key: 'data',
                ignore: true,
            },
            {
                key: 'conditional',
                ignore: true,
            },
            {
                key: 'logic',
                ignore: true,
            },
            {
                key: 'addons',
                ignore: true,
            },
            {
                key: 'layout',
                ignore: true,
            },
            {
                weight: 0,
                key: 'customDisplay',
                type: 'textfield',
                label: 'EKYC Display',
                components: [
                    {
                        weight: 0,
                        type: 'textfield',
                        input: true,
                        key: 'label1',
                        label: 'Label',
                        placeholder: 'EKYC',
                        // validate: {
                        //     required: true,
                        // },
                    },
                    {
                        weight: 0,
                        type: 'textfield',
                        input: true,
                        key: 'url',
                        label: 'URL',
                        placeholder: '',
                        // validate: {
                        //     required: true,
                        // },
                    },
                ],
            },
    

        ],
    },
}

