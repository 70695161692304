import { combineReducers } from "redux";

// ----new reducers ----/
import GetQuoteReducer from '@redux/reducers/getQuote';
import QuotationListReducer from '@redux/reducers/quotationList';
import PolicyListReducer from '@redux/reducers/policyList';
import LeadListReducer from '@redux/reducers/leadListReducer';
import auditLogReducer from '@redux/reducers/auditLogReducer';
import passwordManagementReducer from '@redux/reducers/passwordManagementReducer';
import QuotationExportReducer from '@redux/reducers/quotationExport';
import PolicyExportReducer from '@redux/reducers/policyExport';
import ProductListReducer from '@redux/reducers/productList';
import UserListReducer from '@redux/reducers/userList';
import UserReducer from '@redux/reducers/user';
import OrganizationListReducer from '@redux/reducers/organizationList';
import UserGroupListReducer from '@redux/reducers/userGroupList';
import ModalMessageReducer from '@redux/reducers/modalMessage';
import systemConfigReducer from '@redux/reducers/systemConfigReducer';
import fiscalYearReducer from '@redux/reducers/fiscalYearReducer';
import distributionControlReducer from '@redux/reducers/distributionControlReducer';
import scopeManagementReducer from '@redux/reducers/scopeManagementReducer';
import BPMNReducer from '@redux/reducers/bpmnReducer'
import DMNReducer from '@redux/reducers/dmnReducer'
//-----------------------

import { reducer as notifications } from "react-notification-system-redux";
import { authentication } from "../TeamManagementModule/LoginModule/reducers/authentication.reducers";
import organisations from "../TeamManagementModule/OrganizationModule/reducers/org.reducers";
import userManagement from "../TeamManagementModule/Users/reducers/userManagement.reducers";
import userGroupManagement from "../TeamManagementModule/UserGroups/reducers/userGroups.reducers";
import quotationManagement from "../Products/shared/quotationListing/reducers/quotationManagement.reducers";
import policyManagement from "../Products/shared/policyListing/reducers/policyManagement.reducers";
import getQuote from "../Products/shared/GetQuote/reducers/getQuote.reducers";
import documentation from "../TeamManagementModule/DocumentationModule/reducers";
import { loading } from "./loading.reducer";
import { global } from "./global.reducer";
import { searchValue, checkedFilterFieldCount } from "./search.reducer";
import { dashboard } from "../TeamManagementModule/DashboardModule/reducers/index";


const getQuoteReducer = new GetQuoteReducer();
const quotationListReducer = new QuotationListReducer();
const quotationExportReducer = new QuotationExportReducer();
const policyListReducer = new PolicyListReducer();
const leadListReducer = new LeadListReducer();
const policyExportReducer = new PolicyExportReducer();

const userListReducer = new UserListReducer();
const userReducer = new UserReducer();
const userGroupListReducer = new UserGroupListReducer();
const organizationListReducer = new OrganizationListReducer();
const productListReducer = new ProductListReducer();
const modalMessageReducer = new ModalMessageReducer();

const rootReducer = combineReducers({
  quotationManagement,
  policyManagement,
  organisations,
  userManagement,
  userGroupManagement,

  notifications,
  authentication,
  getQuote,
  loading,
  searchValue,
  checkedFilterFieldCount,
  documentation,
  dashboard,
  global,

  // ---new reducers
  modalMessage: modalMessageReducer.changeState.bind(modalMessageReducer),
  newGetQuote: getQuoteReducer.changeState.bind(getQuoteReducer),
  quotationList: quotationListReducer.changeState.bind(quotationListReducer),
  policyList: policyListReducer.changeState.bind(policyListReducer),
  leadList: leadListReducer.changeState.bind(leadListReducer),
  auditLog: auditLogReducer.changeState.bind(auditLogReducer),
  passwordManagement: passwordManagementReducer.changeState.bind(passwordManagementReducer),
  userList: userListReducer.changeState.bind(userListReducer),
  user: userReducer.changeState.bind(userReducer),
  organizationList: organizationListReducer.changeState.bind(organizationListReducer),
  userGroupList: userGroupListReducer.changeState.bind(userGroupListReducer),
  productList: productListReducer.changeState.bind(productListReducer),
  quotationExport: quotationExportReducer.changeState.bind(quotationExportReducer),
  policyExport: policyExportReducer.changeState.bind(policyExportReducer),
  systemConfig: systemConfigReducer.changeState.bind(systemConfigReducer),
  fiscalYear: fiscalYearReducer.changeState.bind(fiscalYearReducer),
  distributionControl: distributionControlReducer.changeState.bind(distributionControlReducer),
  scopeManagement: scopeManagementReducer.changeState.bind(scopeManagementReducer),
  bpmn:BPMNReducer.changeState.bind(BPMNReducer),
  dmn:DMNReducer.changeState.bind(DMNReducer),
});

export default rootReducer;
