import React,{useState,useEffect} from 'react';
import Modal from 'react-modal';
import {CamundaApi} from '../../util/CamundaApi'
import { styles } from './style';
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

export const DecisionDefinitionList = ({loadDecisionDefinitionByKey})=>{
    const [modalStatus,setModalStatus] = useState(false);
    const[decisionDefinitionList,setDecisionDefinitionList] = useState([]);

    const loadProcesses = async () => {
        CamundaApi().getDecisionDefinitions(true)
        .then(response => response.json())
        .then(result => {                     
            setModalStatus(true)
            setDecisionDefinitionList(result)
        })
        .catch(error => console.log('error', error));   
    }
      
    return (
        <>
            <button onClick={loadProcesses} style={styles.button}>List Of Decision Definition </button>
            <Modal
                ariaHideApp={false}
                isOpen={modalStatus}                        
                onRequestClose={()=>{
                    setModalStatus(false)
                }}
                id={'workflowList'}
                style={customStyles}
                contentLabel="Example Modal">                
                <table border={1}>
                    <thead>
                        <tr>
                            <th>
                                Decision Definition Name
                            </th>
                            <th>
                                Decision Definition ID
                            </th>
                            <th>
                                Operation
                            </th>                
                        </tr>                    
                    </thead>
                    <tbody>
                    {
                    decisionDefinitionList && decisionDefinitionList.map((item,index)=>{
                        return (
                            <tr key={item.key}>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {item.key}
                                </td>
                                <td>
                                    <button onClick={()=>{
                                        loadDecisionDefinitionByKey(item.key)
                                        setModalStatus(false)
                                    }}>Load</button>
                                    <button>Delete</button>
                                    
                                </td>                    
                            </tr>
                        )
                    })
                }
                    </tbody>
                </table>            
            </Modal>
        </>
     
    )
}
export default DecisionDefinitionList;