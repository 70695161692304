import React from 'react';
import { Header, Loader } from '@xc-core/components/core';
import bannerImg from '../../../images/bannerGraphicImages/get-quote.png';
import Stepper from './stepper';
import Footer from './footer';

interface IRenderWizardProps {
  getQuote: IGetQuoteState
  getCurrentStep: () => IStep
  handleNext: () => void
  handlePrev: () => void
  handleSkip: () => void
  paymentSelected: boolean
  sendingRequest: boolean
  isBeneficirayEnterd: boolean
  invalidForm: boolean
  // properties: IObject
  // handleOnChange: any
  // handlePlan: any
  children: any,
  isKhmer?: boolean
}

const RenderWizard = (props: IRenderWizardProps) => {
  const {
    getQuote: { steps, isLoading },
    getCurrentStep,
    handleNext,
    handlePrev,
    handleSkip,
    paymentSelected,
    isBeneficirayEnterd,
    sendingRequest,
    invalidForm,
    // properties,
    // handleOnChange,
    // handlePlan,
    // getQuote,
    children,
    isKhmer
  } = props;

  // if (product === null) return <Message message={userMessages.ERROR} />;
  // if (getQuote.isLoading || collections === null || steps.length === 0) return <Loader />;
  const currentStep = getCurrentStep();
  
  return (
    <>
      <Header
        title='Get Quote'
        bannerGraphic={bannerImg}
        withBorderBottom
      />
      { isLoading ? <Loader /> : (
        <div className='row d-flex justify-content-center my_50'>
          <div className='col-10'>
            <div style={{ width: '90%' }}>
              <Stepper
                steps={steps}
                currentStep={currentStep}
              />
              {children}
              {currentStep.order === steps.length ? <></> : (
                <Footer
                  onNext={handleNext}
                  onPrevious={handlePrev}
                  onSkip={handleSkip}
                  sendingRequest={sendingRequest}
                  invalidForm={invalidForm}
                  view={currentStep.view.display.footer}
                  isPaymentSelected={paymentSelected}
                  isKhmer={isKhmer}
                  isBeneficirayEnterd={isBeneficirayEnterd}
                  currentStep={currentStep}
                />
              )}
            </div>
          </div>
        </div>
      ) }
    </>
  );
};

export default RenderWizard;
