import env from 'env';

export const FormBuilderApi = () => {
    const baseUrl = env.formBuilderApiUrl

    const send = (path, params) => {
        return fetch(`${baseUrl}${path}`, params)
    }

    const saveForm = (formName, data) => {
        const bodyPayload = {formId: formName, contentType: "application/json", contents: JSON.stringify(data)};
        const headers = new Headers();
        headers.append("content-type", "application/json");

        return send("/", {
            body: JSON.stringify(bodyPayload),
            method: 'POST',
            headers: headers,
        })
    }
    const loadForm = (formName) => {
        return send(`/render/${formName}`, {
            method: "GET"
        })
    }

    const getFormList=()=>{
        return send('/',{method:"GET"})
    }

    return {
        saveForm,
        loadForm,
        getFormList
    }
}
