export const BPMNXmlParser = (xml) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, "application/xml");
    const getProcess = () => {
        return doc.getElementsByTagName("bpmn:process");
    }
    const getProcessId = () => {
        const bpmnProcess = getProcess()
        if (bpmnProcess && bpmnProcess.length > 0) {
            return bpmnProcess[0]?.attributes['id']?.value
        }
        return undefined
    }
    const getProcessName = () => {
        const bpmnProcess = getProcess()
        if (bpmnProcess && bpmnProcess.length > 0) {
            return bpmnProcess[0]?.attributes['name']?.value
        }
        return undefined
    }
    const getActivityInfo = (idOfActivity) => {
        const tags = doc.getElementsByTagName("bpmn:callActivity");
        if (tags[idOfActivity]) {
            return {
                keyOfTask: tags[idOfActivity].attributes['calledElement'].value,
                name: tags[idOfActivity].attributes['name'].value,
            }
        }
        return undefined
    }
    return {
        processId: getProcessId(),
        processName: getProcessName(),
        getProcess,
        getActivityInfo
    }
}

