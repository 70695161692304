import React from "react";
import PropTypes from "prop-types";
import MaterialTitlePanel from "./MaterialTitlePanel";
import { Link } from "react-router-dom";
import logo from "../images/CoverPlusLogo.svg";

class SidebarContent extends React.Component {
  componentDidMount() {
    const { menuArray } = this.props;
    let currentLocation = window.location.hash;
    currentLocation = currentLocation.replace(/[^a-zA-Z// ]/g, "");
    currentLocation =
      currentLocation.match("/(.*)/") === null
        ? currentLocation.match("/(.*)")
        : currentLocation.match("/(.*)/");
    let getName = menuArray.filter(name => name.link === "/" + currentLocation[1]);
    if (!getName.length) return this.props.click(1, 'Dashboard');
    // this.props.click(getName[0].id, getName[0].name);
  }

  render() {
    const { props } = this;
    const { menuArray } = props;
    return (
      <MaterialTitlePanel>
        <div className="noFlex shadow-sm" id="sidebar-wrapper">
          <div className="p-3 my-4 mx-2 hideOnLarge">
            <img src={logo} className="img-fluid" alt="Logo" />
          </div>
          <span className="borderBottom mx-3 hideOnLarge" />
          <div className="list-group list-group-flush py-2">
            {menuArray.map(data => {
              /**
               * @getQuotePathResettingState
               * will help us reset the state for the getQuote page from the navigation
               * we check if the link is '/getQuote' and only then reset the state on the getQuote journey
               */
              let getQuotePathResettingState = { pathname: data.link };
              if (data.link === "/getQuote") getQuotePathResettingState = {
                ...getQuotePathResettingState,
                state: 'reset'
              };
              return (
                <Link
                  key={data.id}
                  onClick={() => props.click(data.id, data.name)}
                  to={getQuotePathResettingState}
                  className={
                    "list-group-item navStyle list-group-item-action" +
                    (props.active === data.id ? " activeNav" : " text-info")
                  }
                  replace={true}
                >
                  <i className={"icon-" + data.icon} />&nbsp; {data.name}
                </Link>
              );
            })}
          </div>
        </div>
      </MaterialTitlePanel>
    );
  }
}

SidebarContent.propTypes = {
  style: PropTypes.object
};

export default SidebarContent;
