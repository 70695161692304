import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Button, Col, Row} from "reactstrap";
import Notification from "react-notification-system-redux";
import logo from "../../../images/CoverPlusLogo.svg";
import TextField from "@material-ui/core/TextField";
import SimpleReactValidator from "simple-react-validator";
import {validateToken, createPassword} from "../services/createPassword.service";
import {getNotificationMessage} from "../../../_services";

const TokenIssueScreen = () => (

<Fragment>
  <div
    className="d-flex align-items-center justify-content-center flex-column p-4"
    style={{
      height: "100vh",
      color: "#979797",
      backgroundColor: "#FCFCFC",
    }}>
    <i className="icon-brokenLink mb-4" style={{fontSize: "3rem"}}/>
    <p style={{fontSize:"1.5rem"}}><strong>Oops! This link is no longer active.</strong></p>
    <p>Please reach out to the sender for a new link</p>
  </div>
</Fragment>);

class CreatePasswordPage extends React.Component {
  state = {
    token: "",
    newPassword: "",
    confirmPassword: "",
    tokenIsValid: false,
    loading: true,
  };
  validator = new SimpleReactValidator({
    element: (message, className) => <small className={`${className} text-danger`}>{message}</small>,
    className: 'text-danger',
    validators: {
      passwordsShouldMatch: {
        message: "Sorry, these passwords don't match",
        rule: () => {
          const {newPassword, confirmPassword} = this.state;
          return newPassword === confirmPassword && newPassword !== "" && confirmPassword !== "";
        }
      }
    }
  });

  componentDidMount() {
    const {search} = this.props.location;
    const token = search.slice(search.indexOf("=") + 1);
    if (token) this.validateToken(token);
    else {
      this.setState({
        tokenIsValid: false,
        loading: false,
      });
    }
  }

  validateToken = async token => {
    const response = await validateToken(token);
    if (response.status === true) this.setState({
      token,
      tokenIsValid: true,
      loading: false,
    });
    else this.setState({
      loading: false,
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const {token, newPassword, confirmPassword} = this.state;
    if (this.validator.allValid()) {
      const params = {
        token,
        password: newPassword,
        passwordConfirm: confirmPassword
      };
      this.setState({loading: true});
      const response = await createPassword(params);
      if (response.status === "success") {
        this.props.dispatch(Notification.show(
          getNotificationMessage('Password is reset successfully. Please login using your new password.')
        ));
        setTimeout(() => this.props.history.push("/"), 2000)
      } else {
        this.setState({loading: false});
        this.props.dispatch(Notification.error(getNotificationMessage(response.message)));
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleChange = e => this.setState({[e.target.name]: e.target.value});

  render() {
    const {tokenIsValid, loading} = this.state;
    if (loading) return (<div className="loader"/>);
    if (!tokenIsValid) return (<TokenIssueScreen/>);
    return (
      <div>
        <div
          id="main"
          className="container alignCenter d-flex align-items-center"
        >
          <Row>
            <Col sm="9" md="7" lg="5" className="mx-auto">
              <div className="d-flex flex-column">
                <img
                  src={logo}
                  className="logoSize img-fluid mx-auto"
                  alt="Xpress Cover"
                />
                <p className="text-center text-primary">Create Password</p>
                <small className="text-center">You will need to create a password to login to system</small>
                <div className="mx-5">
                  <form name="form" onSubmit={this.handleSubmit}>
                    <div className={"form-group"}>
                      <TextField
                        id="standard-password-input"
                        value={this.state.newPassword}
                        onChange={e => this.handleChange(e)}
                        label="New Password"
                        className={"col-12"}
                        name="newPassword"
                        margin="normal"
                        variant="outlined"
                        type="password"
                      />
                      {this.validator.message('new password', this.state.newPassword, 'required|passwordsShouldMatch')}
                    </div>
                    <div className={"form-group"}>
                      <TextField
                        id="standard-confirm-password-input"
                        value={this.state.confirmPassword}
                        onChange={e => this.handleChange(e)}
                        label="Confirm Password"
                        className={"col-12"}
                        name="confirmPassword"
                        margin="normal"
                        variant="outlined"
                        type="password"
                      />
                      {this.validator.message('confirm password', this.state.confirmPassword, 'required|passwordsShouldMatch')}
                    </div>
                    <div className="form-group">
                      <Button className="col-12" color="primary">
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(CreatePasswordPage);