import moment from 'moment';
import config from '@xc-core/config';

/**
 * apply format to string date using moment module
 * @param {string} value: date in string
 * @param {string} format: string
 * @return {string}
 */
const applyFormat = (value: string, format: string): string => {
  // console.log('applyFormat, value', value);
  // console.log('applyFormat, format', format);
  if (!value) return '';
  const m = moment(value);
  // console.log('applyFormat, moment', m.isValid());

  return m.isValid() ? m.format(format) : '';
};

const toDate = (value: string, format: string): Date | null => {
  if (!value) return null;
  const m = moment(value, format);
  return m.isValid() ? m.toDate() : null;
};

const toString = (value: Date, format: string): string => {
  const m = moment(value);
  return m.isValid() ? m.format(format) : '';
};

const applyFormatFromTo = (value: string, from: string, to: string): string => {
  const m = moment(value, from);
  return m.isValid() ? m.format(to) : '';
};

const getCurrentDate = (format: string): string => moment().format(format);

const isInRange = (value: string, obj: IObject | null) => {
  const selected = value ? applyFormatFromTo(value, config.dateMomentFormat, 'YYYYMMDD') : '';
  const max = value && obj ? applyFormatFromTo(obj.max, config.dateMomentFormat, 'YYYYMMDD') : '';
  const min = value && obj ? applyFormatFromTo(obj.min, config.dateMomentFormat, 'YYYYMMDD') : '';

  return Number(selected) >= Number(min) && Number(selected) <= Number(max);
};

const subtractFromNow = (years = 0, months = 0, days = 0): string => (moment(new Date())
  .subtract(`${years}`, 'years')
  .subtract(`${months}`, 'months')
  .subtract(`${days}`, 'days')
  .format(config.dateMomentFormat));

const getDurationAsMilliseconds = (duration: string) => moment.duration(duration).asMilliseconds();

const asDateTime = (datetime: string) => moment(datetime).format(config.dateTimeMomentFormat);
const addToDate = (date: string, years = 0, months = 0, days = 0): string => {
  const dateObject = moment(date, config.dateMomentFormat);
  return dateObject.add(`${years}`, 'years')
    .add(`${months}`, 'months')
    .add(`${days}`, 'days')
    .format(config.dateMomentFormat);
};

const subtractFromDate = (date: string, years = 0, months = 0, days = 0): string => {
  const dateObject = moment(date, config.dateMomentFormat);
  return dateObject.subtract(`${years}`, 'years')
    .subtract(`${months}`, 'months')
    .subtract(`${days}`, 'days')
    .format(config.dateMomentFormat);
};

const getDiff = (date1:string, date2:string, unit: 'days' | 'months' | 'years'): number => {
  const date1Obj = moment(date1, config.dateMomentFormat);
  const date2Obj = moment(date2, config.dateMomentFormat);
  return Math.abs(date1Obj.diff(date2Obj, unit));
};

const compare = (str1: string, str2: string, format: string): number => {
  const formatted1 = applyFormatFromTo(str1, format, 'YYYYMMDD');
  const formatted2 = applyFormatFromTo(str2, format, 'YYYYMMDD');
  const num1 = Number(formatted1);
  const num2 = Number(formatted2);

  if (num1 === num2) return 0;
  if (num1 > num2) return 1;
  return -1;
};

/** GOT FROM LUEI
 function yearsDiff(t,r,e){var n=moment(t,dateFormat),
  a=moment(r,dateFormat),o=0;return n>a?(!0===e&&n.add(1,"days"),
  o=n.diff(a)):(!0===e&&a.add(1,"days"),o=a.diff(n)),~~(o/315576e5)}
  yearsdiff(string startDate, string endDate, bool addOneExtraDay)
 */

const date = {
  applyFormat,
  applyFormatFromTo,
  toDate,
  toString,
  getCurrentDate,
  isInRange,
  subtractFromNow,
  getDurationAsMilliseconds,
  asDateTime,
  subtractFromDate,
  addToDate,
  getDiff,
  compare,
  config,
};

export default date;
