import moment from 'moment';
import { CONFIG_DATE_MOMENT_FORMAT } from '../../../utils';

const minAge = {
  years: 18,
  months: 0,
  days: 0,
};
const maxAge = {
  years: 65,
  months: 0,
  days: 365,
};


const properties : IObject = {
  'Start Date': moment().format(CONFIG_DATE_MOMENT_FORMAT),
  'End Date': moment().format(CONFIG_DATE_MOMENT_FORMAT),
};

const country = 'Cambodia';

const insuredType = 'Individual';

const stateListParam = 'state_alf';

const cnicLength = 13;

const postcodeLength = 5;

const debitCreditNoLength = 16;

const defaults = {
  minAge,
  maxAge,
  country,
  stateListParam,
  cnicLength,
  postcodeLength,
  debitCreditNoLength,
  properties,
  insuredType,
};

export default defaults;
