import React from "react";
import Modal from 'react-modal';
import {useHistory} from "react-router-dom";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const FormList=(props)=>{
    const {isOpen,openHandler,items} = props;
    const history = useHistory();

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            onRequestClose={()=>{
                openHandler(false)
            }}
            id={'workflowList'}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <table border={1}>
                <thead>
                <tr>
                    <th>
                        Form
                    </th>
                    <th>
                        Operation
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    items && items.map((item)=>{
                        return (
                            <tr key={item.formId}>
                                <td>
                                    {item.formId}
                                </td>
                                <td>
                                    <button
                                        style={{backgroundColor:'#3E83FC'}}
                                        onClick={()=>{
                                            openHandler(false)
                                            history.push(`/form-builder/${item.formId}`)
                                        }}
                                    >
                                        Load
                                    </button>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </Modal>
    )
}

export default FormList;