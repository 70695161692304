import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import { numberLib, objLib } from '@xc-core/lib';
import config from '@xc-core/config';

interface ISidebarProps { item: any, model: any, ext?: boolean }

const RenderBlockItem = (props:ISidebarProps) => {
  const { item, model, ext } = props;
  let value = objLib.getValueFromPath(item, model.id);
  if (model.config.currency) {
    value = parseFloat(value);
    value = value ? numberLib.applyFormat(value, config.currencyFormat) : 0;
  }

  return (
    <Row className='pt-1 pb-3' key={model.id}>
      <Col
        sm={6}
        className={model.config.className}
      >
        {model.name}{ext && model.ext}
      </Col>
      <Col
        sm={6}
        className={`text-right summary-label-3 ${model.config.className}`}
      >
        {model.config.currency ? `${config.currencySymbol} ` : ''}
        {value}
      </Col>
    </Row>
  );
};
const RenderBlock = (props:ISidebarProps) => {
  const {
    item,
    model,
    ext,
  } = props;

  return (
    <div key={model.title} className='item'>
      <span className='plan-title d-block mb-3 mt-2'>{model.title.name}{ext && model.title.ext}</span>
      {
        model.items.map((modelItem: any) => (
          <RenderBlockItem key={modelItem.id} model={modelItem} item={item} ext={ext} />
        ))
      }
    </div>
  );
};
const RenderHeader = (props:ISidebarProps) => {
  const { item, model, ext } = props;
  const productName = (item && objLib.getValueFromPath(item, model.productName.id));
  const plan = (item && objLib.getValueFromPath(item, model.plan.id));
  let total = (item && objLib.getValueFromPath(item, model.total.id));
  total = parseFloat(total);
  total = total ? numberLib.applyFormat(total, config.currencyFormat) : 0;
  return (
    <>
      <div className='pt-2 text-primary pr-3'>
        <Row>
          <Col sm={10} className='font-size-small text-secondary mt-2'>
            {`${productName}${plan ? ` - ${plan}` : ''}`}
          </Col>
          <Col sm={2} className='text-right pr-0 mt-2 customCircularbutton'>
            {/* TODO it should be dynamic  */}
            {
              productName == 'Flexi PA'
                ?
                  <i className='header-icon icon-flexi-pa' />
                :
                  <i className='header-icon icon-planeProduct' />
            }
          </Col>
        </Row>
      </div>
      <div className='pt-3'>
        <span className='plan-title'>
          {model.total.name}{ext && model.total.ext}
        </span>
        <h2 className='text-primary py-2 sidebar-price'>
          {`${config.currencySymbol} ${total}`}
        </h2>
      </div>
    </>
  );
};
const RenderBody = (props:ISidebarProps) => {
  const { model, item, ext } = props;
  return (
    <>
      {
      model.items.map((m: any) => (
        <RenderBlock
          model={m}
          item={item}
          key={m.id}
          ext={ext}
        />
      ))
      }
    </>
  );
};
const QuotationSidebarCard = (props:ISidebarProps) => {
  const { item, model, ext } = props;
  return (
    <div className='sidebar-card'>
      <Card style={{ borderRadius: '10px' }} className='shadow-md border-0'>
        <CardBody className='px-3'>
          <RenderHeader
            item={item}
            model={model.header}
            ext={ext}
          />
          <RenderBody
            model={model.body}
            item={item}
            ext={ext}  
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default QuotationSidebarCard;
