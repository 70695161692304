/* eslint-disable no-useless-escape */

const email = (value: string) => {
  // const regExpr = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regExpr = /^([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}$/g;
  return regExpr.test(value);
};

const numberOnly = (value: string) => /^\d+$/.test(value);

const alphanumeric = (value: string) => /^[a-zA-Z0-9]+$/i.test(value);

const fullNameCertainChars = (value: string) => /^[a-zA-Z\-\'\@\.\ \/\\\u4E00-\u9FCC]+$/gi.test(value);

const atLeastOneAlphanumericChar = (value: string) => /[a-zA-Z]/gi.test(value);

const reg = {
  email,
  numberOnly,
  alphanumeric,
  fullNameCertainChars,
  atLeastOneAlphanumericChar,
};

export default reg;
