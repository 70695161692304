import env from 'env';

export const ProductApi = () => {
    const policyBaseUrl = "https://stg-xc2.coverplus.io/services/insurance"
    const productBaseUrl = "https://stg-xc2.coverplus.io/services/insurance"

    const send = (path: string, params: any) => {
        return fetch(`${path}`, params)
    }

    const getListOfProduct = () => {
        return send(`${policyBaseUrl}/products/`, {
            method: "GET"
        })
    }
    const updateProcessListOfProduct = (payload: any) => {
        return send(`${productBaseUrl}/products`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(payload)
        })
    }
    const getProductById = (productId: string) => {
        return send(`${policyBaseUrl}/products/${productId}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
            },
        })
    }

    const deleteProcessItem=(productId:number)=>{
        return send(`${productBaseUrl}/processes/${productId}`, {
            method: "DELETE",
        })
    }


    return {
        getListOfProduct,
        updateProcessListOfProduct,
        getProductById,
        deleteProcessItem
    }
}
