
const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan Type', name: '', config: {} },
    total: { id: 'properties.Price.Total', name: 'Total Price', config: {} },
    config: {},
  },
  body: {
    items: [
      {
        id: 0,
        title: { id: 'Price Breakdown', name: 'Price Breakdown' },
        items: [
          { id: 'properties.Price.Main Policy', name: 'Gross Premium', config: { currency: true } },
          { id: 'properties.Price.Admin Surcharge', name: 'Admin Surcharge', config: { currency: true } },
          { id: 'properties.Price.Federal Excise Duty', name: 'Federal Excise Duty', config: { currency: true } },
          { id: 'properties.Price.Fixed Insurance Fee', name: 'Fixed Insurance Fee', config: { currency: true } },
          { id: 'properties.Price.Stamp Duty', name: 'Stamp Duty', config: { currency: true } },
          { id: 'properties.Price.Withholding Tax', name: 'Withholding Tax', config: { currency: true } },
        ],
        config: {},
      },
    ],
    config: {},
  },
  config: {},
};

export default model;
