import React, {useState} from 'react';
import {styles} from "./style";
import ProcessList from './ProcessList';

export const ActionButtons = ({deploy, loadProcesses,loadProcessByKey}) => {    
    return (
        <div style={{
            display: "flex",
            flex: 1,
            margin: 20,
            flexDirection: "row",
        }}>
            <button onClick={deploy} style={styles.button}>Deploy</button>
            <ProcessList                     
                    loadProcessByKey={loadProcessByKey}
                    />            
            
        </div>
    )
}
export default ActionButtons;