import React, { Fragment } from 'react';
import { BaseComponent } from '@xc-core/components';
import { objLib } from '@xc-core/lib';
import ButtonCircular from '../buttonCircular';

interface IRenderRecordProps {
  record: IObject,
  titles: any,
  onRowClicked: (item: IObject) => void,
  onRowButtonClicked: (item: IObject) => void,
  className?: string,
  withChildren?: boolean,
  isChild?: boolean,
  arrowIcon?: string,
  toggleChildren?: Function,
  buttonIcon?: string,
  onDeleteRow?:(record: IObject)=>void
  onExportRow?:(record: IObject)=>void
}
const RenderRecord = (props: IRenderRecordProps) => {
  const {
    record, className, buttonIcon,
    isChild, titles, onRowClicked, onRowButtonClicked,
    arrowIcon, toggleChildren, withChildren,onDeleteRow, onExportRow
  } = props;

  const renderValue = (record: IObject, title: IObject) => {
    if (title.type === 'icon') {
      return (
        <span dangerouslySetInnerHTML={{ __html: objLib.getValueFromPath(record, title.id) }} />
      );
    }
    return objLib.getValueFromPath(record, title.id);
  };

  return (
    <tr
      className={className}
      data-testid="table-body-tr"
      key={Math.random().toString()}
      onClick={() => onRowClicked(record)}
    >
      { withChildren === false ? <td> </td> : (
        <td onClick={(e) => (toggleChildren && toggleChildren(e))}>
          <i className={arrowIcon} />
        </td>
      )}
      {titles.map((title: any) => (
        <td
          key={`${title.id}-${record.id}`}
          className={`${isChild && title.id === 0 ? 'pl-4' : ''}`}
        >
          {/* {objLib.getValueFromPath(record, title.id)} */}
          {renderValue(record, title)}
        </td>
      ))}
      <td
        key='editId'
        className='actionIcon text-right'
      >
        <div style={{display:'flex', flexDirection:'row'}}>
          {
            onExportRow ? (
                <ButtonCircular
                    className='table-row-button mx-1'
                    handler={(pair: IPair) => onExportRow(record)}
                    key='row-action'
                    icon={'hyperlink'}
                    id={record.id}
                    name='details'
                />
            ) :null
          }
          {
            buttonIcon ? <ButtonCircular
                className='table-row-button'
                handler={(pair: IPair) => onRowButtonClicked(record)}
                key='row-action'
                icon={buttonIcon}
                id={record.id}
                name='details'
                color='primary'
            /> : null
          }
          {
            onDeleteRow ? (
                <ButtonCircular
                    className='table-row-button mx-1 text-danger'
                    handler={(pair: IPair) => onDeleteRow(record)}
                    key='row-action'
                    icon={'garbage'}
                    id={record.id}
                    name='details'
                />
            ) :null
          }
        </div>
      </td>
    </tr>
  );
};

interface IRenderChildrenRecordProps {
  records: any,
  onRowClicked: (item: IObject) => void,
  onRowButtonClicked: (item: IObject) => void,
  titles: any,
  arrowIcons: any,
  parentId: any,
  buttonIcon?: string,
  onDeleteRow?:(record: IObject)=>void,
  onExportRow?:(record: IObject)=>void
}
const RenderChildrenRecords = (props: IRenderChildrenRecordProps) => {
  const {
    records,
    onRowClicked,
    onRowButtonClicked,
    titles,
    arrowIcons,
    parentId,
    buttonIcon,
    onDeleteRow,
    onExportRow
  } = props;
  return (
    records.map((record: any) => (
      <RenderRecord
        key={record.id}
        className={`child-bg ${arrowIcons[parentId] === 'icon-downArrow' ? '' : 'd-none'}`}
        record={record}
        isChild
        onRowClicked={onRowClicked}
        onRowButtonClicked={(item: IObject) => onRowButtonClicked(item)}
        titles={titles}
        buttonIcon={buttonIcon}
        onDeleteRow={onDeleteRow}
        onExportRow={onExportRow}
      />
    )));
};

interface INoDataProps {
  headerLength: number,
  recordsLength: number,
  hasRecords: boolean,
  noDataImagePath: string,
}
const NoData = (props: INoDataProps) => {
  const {
    headerLength,
    recordsLength,
    hasRecords,
    noDataImagePath,
  } = props;
  return (
    <tr>
      <td colSpan={headerLength}>
        <div className='empty-state'>
          { noDataImagePath && <img src={noDataImagePath} alt='no data' className='center' /> }
          <small className='text-lg-center ml-2'>
            {
              hasRecords && recordsLength === 0
                ? <p>No results match. Please try again.</p>
                : (
                  <div>
                    <p className='m-0 text-left empty-state-p'>No records yet.</p>
                    <p className='m-0 text-left empty-state-p'>Start creating one and it will show up here.</p>
                  </div>
                )
            }
          </small>
        </div>
      </td>
    </tr>
  );
};

interface IBodyProps {
  records: any[],
  hasRecords: boolean,
  image: string,
  titles: any,
  onRowClicked: Function,
  onRowButtonClicked: Function,
  buttonIcon?: string,
  onDeleteRow?:(record: IObject)=>void
  onExportRow?:(record: IObject)=>void
}
interface IBodyState { arrowIcons: any[] }
class Body extends BaseComponent<IBodyProps, IBodyState> {
  constructor(props: IBodyProps) {
    super(props);
    this.state = {
      arrowIcons: [],
    };
  }

  hasChildren = (record: any) => (record.children && record.children.length > 0) === true;

  toggleChildren = (e: any, index: any) => {
    const { arrowIcons } = this.state;

    const newArrowIcons = [...arrowIcons];
    if (!newArrowIcons[index]) {
      newArrowIcons[index] = 'icon-rightArrow'; /** default class */
    }
    newArrowIcons[index] = newArrowIcons[index] === 'icon-rightArrow' ? 'icon-downArrow' : 'icon-rightArrow';
    this.setState({ arrowIcons: newArrowIcons });
    e.stopPropagation();
  };

  render() {
    const {
      records, onRowClicked, onRowButtonClicked, titles,
      image, hasRecords, buttonIcon,onDeleteRow,onExportRow
    } = this.props;
    const { arrowIcons } = this.state;
    return (
      <tbody>
        { records.length <= 0 ? (
          <NoData
            headerLength={titles.length}
            recordsLength={records && records.length}
            hasRecords={hasRecords}
            noDataImagePath={image}
          />
        ) : (
          records.map((record) => (
            <Fragment key={record.id}>
              <RenderRecord
                record={record}
                onRowClicked={(item: any) => onRowClicked(item)}
                onRowButtonClicked={(item: IObject) => onRowButtonClicked(item)}
                titles={titles}
                toggleChildren={(e: any) => this.toggleChildren(e, record.id)}
                arrowIcon={`${this.hasChildren(record) ? arrowIcons[record.id] || 'icon-rightArrow' : ''}`}
                withChildren={this.hasChildren(record)}
                buttonIcon={buttonIcon}
                onDeleteRow={onDeleteRow}
                onExportRow={onExportRow}
              />
              {this.hasChildren(record) === false ? <></> : (
                <RenderChildrenRecords
          // className={`child-bg ${arrowIcons[record.id] === 'icon-downArrow' ? '' : 'd-none'}`}
                  arrowIcons={arrowIcons}
                  records={record.children}
                  titles={titles}
                  parentId={record.id}
                  key={Math.random().toString()}
                  onRowClicked={(item: any) => onRowClicked(item)}
                  onRowButtonClicked={(item: IObject) => onRowButtonClicked(item)}
                  buttonIcon={buttonIcon}
                  onDeleteRow={onDeleteRow}
                  onExportRow={onExportRow}
                />
              )}
            </Fragment>
          ))
        )}
      </tbody>
    );
  }
}

export default Body;
