import React, {useRef, useEffect, useState} from 'react';
import {CamundaApi} from '../util/CamundaApi'
import {baseDMNXML, baseXML} from '../Constant'
import DmnJS from "dmn-js/lib/Modeler";
import "dmn-js/dist/assets/diagram-js.css";
import "dmn-js/dist/assets/dmn-font/css/dmn-embedded.css";
import "dmn-js/dist/assets/dmn-js-decision-table-controls.css";
import "dmn-js/dist/assets/dmn-js-decision-table.css";
import "dmn-js/dist/assets/dmn-js-drd.css";
import "dmn-js/dist/assets/dmn-js-literal-expression.css";
import "dmn-js/dist/assets/dmn-js-shared.css";
import "dmn-js-properties-panel/dist/assets/properties-panel.css";
import {
    DmnPropertiesPanelModule,
    DmnPropertiesProviderModule,
} from 'dmn-js-properties-panel';

import {DMNXmlParser} from "../util/DMNXmllParser";
import {useSelector, useDispatch} from 'react-redux'


export const DMNLogic = (url, diagramXML) => {
    const dmnState = useSelector((state) => state.dmn)
    const dispatch = useDispatch()


    const mainContainerRef = useRef(null);
    const propertiesContainerRef = useRef(null);
    const [dmnViewer, setDMNViewer] = useState()
    const initial = () => {
        const container = mainContainerRef.current
        const properties = propertiesContainerRef.current
        const dmn = new DmnJS({
            container: container,
            drd: {
                propertiesPanel: {
                    parent: properties
                },
                additionalModules: [
                    DmnPropertiesPanelModule,
                    DmnPropertiesProviderModule,
                ]
            },
            keyboard: {
                bindTo: window
            },
            decisionTable: {
                keyboard: {
                    bindTo: document
                }
            },

        });
        setDMNViewer(dmn)
    }

    useEffect(() => {
        if (mainContainerRef && mainContainerRef.current) {
            initial();
        }
    }, [])

    const dmnChangeListener = () => {
        if (dmnViewer) {
            const activeEditor = dmnViewer?.getActiveViewer();
            const eventBus = activeEditor?.get('eventBus');
            eventBus.on("commandStack.changed", function (event) {
                saveLocalXml()
            });
        }
    }
    useEffect(() => {
        if (dmnViewer) {


            if (diagramXML) {
                dmnViewer.importXML(diagramXML, dmnChangeListener);
            } else {
                try {
                    const {currentXML} = dmnState
                    console.log(dmnState)
                    if (currentXML && currentXML.toString().length > 0) {
                        dmnViewer.importXML(currentXML, dmnChangeListener)
                    } else {
                        dmnViewer.importXML(baseDMNXML, dmnChangeListener)
                    }
                } catch (e) {
                    dmnViewer.importXML(baseDMNXML, dmnChangeListener)
                }
            }
        }
    }, [dmnViewer])
    const getCurrentXML = () => {
        return new Promise((resolve, reject) => {
            dmnViewer.saveXML({format: true}, (err, xml) => {
                if (err) {
                    reject("fail to export")
                }
                resolve({xml})
            })
        })
    }

    const deploy = async () => {
        const {xml} = await getCurrentXML();
        const {definitionId, definitionName} = DMNXmlParser(xml)
        CamundaApi().deployXML({
            xml: xml,
            fileName: `${definitionId}.dmn`,
            tenantId: undefined,
            deploymentSource: "XC-Portal",
        })
            .then(response => response.json())
            .then(result => {
                alert("Deployed as well")
            })
            .catch(error => console.log('error', error));

    }

    const loadDecisionDefinitionByKey = (definitionId) => {
        CamundaApi().loadDecisionDefinitionByKey(definitionId)
            .then(response => response.json())
            .then(result => {
                const {dmnXml} = result;
                dmnViewer.importXML(dmnXml);
            })
            .catch(error => console.log('error', error));
    }
    const saveLocalXml = async () => {
        const {xml} = await getCurrentXML();
        console.log("SAVE TO LOCAL XML",xml)
        dispatch({type: "SET_CURRENT_XML_DMN", xml})
    }

    return {
        isLoad: true,
        mainContainerRef,
        propertiesContainerRef,
        loadDecisionDefinitionByKey,
        deploy,
    }
}
export default DMNLogic;