import React from 'react';
import { Row, Col } from "reactstrap";
import { SidebarCard, InsuredDetail } from '@xc-core/components';
import { sidebarModel, DetailModel } from 'models';

const Summary = props => {

  const { properties, quotation } = props;
  return (
    <Row>
      <Col lg='8'>
      	<InsuredDetail
      		item={{
      		insureds: [{ ...properties }],
      		beneficiaries: properties.Beneficiaries,
      		}}
      		model={DetailModel.cashAlfalah}
      	/>
      </Col>
      <Col lg='4'>
      	<SidebarCard.Quotation 
      			model={sidebarModel.quotation.cashAlfalah}
      			item={quotation}
      	/>
      </Col>
    </Row>
  );
};

export default Summary;
