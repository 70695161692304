import formEditorProps from "./FormEditorProps";

const LOW_PRIORITY = 500;

export default function FormEditorPropertiesProvider(propertiesPanel, translate) {
    this.getTabs = function (element) {
        return function (entries) {
            entries.map((itemEntries, index) => {
                if (itemEntries.id === "forms") {
                    formEditorProps(itemEntries.groups[0], element, translate)
                }
            })
            return entries;
        };
    };
    propertiesPanel.registerProvider(LOW_PRIORITY, this);
}

FormEditorPropertiesProvider.$inject = ["propertiesPanel", "translate"];
