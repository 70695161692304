import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { userActions } from "../actions";
import "../css/LoginPage.css";
import logo from "../../../images/CoverPlusLogo.svg";
import AppVersion from "./AppVersion";
import styled from "styled-components";

const VersionContainer = styled.div`
  position: fixed;
  bottom: 50px;
  right: 60px;
`;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      usernameNotFound: false,
      passwordNotFound: false,
      backendMessage: '',
    };
    this.validator = new SimpleReactValidator({
      element:(message, className) => <small className={`${className} text-danger`}>{message}</small>,
      className: 'text-danger',
      validators: {
        username_exist: {
          message: "Sorry, we couldn't find an account with that username/email. Please try again.",
          rule: () => (!this.state.usernameNotFound)
        },
        password_matches: {
          message: "Sorry, that password is incorrect. Please try again.",
          rule: () => (!this.state.passwordNotFound)
        },
        backend_message: {
          message: `${this.state.backendMessage}`,
          rule: () => (this.state.backendMessage === ''),
        },
      },
    });
  }

  handleChange = name => event => {
    this.state.usernameNotFound && this.setState({ usernameNotFound: false });
    this.state.passwordNotFound && this.setState({ passwordNotFound: false });
    this.state.backendMessage && this.setState({ backendMessage: '' });
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const { username, password } = this.state;
      const user = await this.props.login(username, password);
      if (user.type === "USERS_LOGIN_SUCCESS") return  window.location.reload();

      if (user.error === "Incorrect password") {
        this.setState({ passwordNotFound: true }, () => {
          this.validator.showMessages();
          this.forceUpdate();
        });
      } else if (user.error === "Username/Email does not exist") {
        this.setState({ usernameNotFound: true }, () => {
          this.validator.showMessages();
          this.forceUpdate();
        });
      } else if (user.error) {
        this.setState({ backendMessage: user.error }, () => {
          this.validator.showMessages();
          this.forceUpdate();
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { loggingIn } = this.props;
    const { backendMessage } = this.state;
    return (
      <div
        id="main"
        className="container alignCenter d-flex align-items-center"
      >
        <Row>
          <Col sm="9" md="7" lg="5" className="mx-auto">
            <div className="d-flex flex-column">
              <img
                src={logo}
                className="logoSize img-fluid mx-auto"
                alt="Logo"
              />
              <p className="text-center text-primary">Login</p>
              <div className="mx-5">
                <form name="form" onSubmit={this.handleSubmit}>
                  <div className={"form-group"}>
                    <TextField
                      id="outlined-email-input"
                      value={this.state.username}
                      onChange={this.handleChange("username")}
                      label="Username/Email"
                      className={"col-12"}
                      // type="email"
                      name="email"
                      // autoComplete="email"
                      margin="normal"
                      variant="outlined"
                    />
                    {this.validator.message('username/email', this.state.username, 'required|username_exist' )}
                  </div>
                  <div className={ "form-group" } >
                    <TextField
                      id="outlined-password-input"
                      label="Password"
                      type="password"
                      className={"col-12"}
                      autoComplete="current-password"
                      margin="normal"
                      variant="outlined"
                      value={this.state.password}
                      onChange={this.handleChange("password")}
                    />
                    {this.validator.message('password', this.state.password, 'required|password_matches|backend_message')}
                    {backendMessage && (
                      <div>
                        <small className='text-danger'>{backendMessage}</small>
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <Button className="col-12" color="primary">
                      Login Now
                    </Button>
                    {loggingIn && (
                      <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    )}
                  </div>
                </form>
                <p className="text-center">
                  <small>
                    Forgot Password?{" "}
                    <Link to="forgot_Password" >Click here</Link>
                  </small>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <VersionContainer className="">
          <AppVersion version={"1.4.0"}/>
        </VersionContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn
  };
}

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => dispatch(userActions.login(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
