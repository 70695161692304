import React, { useEffect, useRef, useState} from 'react';
import * as reactstrap from 'reactstrap';
import { eventLib } from '@xc-core/lib';

interface PropsPriceRangeInput extends IInputFormControlModel{
  min?:string,
  max?:string,
}
const PriceRange = (props: PropsPriceRangeInput) => {
  const {
    className, id, validator, value, name, placeholder,
    disabled, autoFocus, rules, customMessage, handler,
    validationMessage, validationData, validationName,validationRules,max,min
  } = props;
  // const {showMessage} = validator;
  const inputRef = useRef(null);
  const currentStartPosition = useRef(null);
  const currentEndPosition = useRef(null);
  const [,forceUpdate] = useState(0);


  const onChange = (e: any) => {

    const pair = eventLib.getPair(e);
    inputRef.current = e.target;
    currentStartPosition.current = e.target.selectionStart;
    currentEndPosition.current = e.target.selectionEnd;
    handler(pair);

  };

  useEffect(()=>{
    if(validator?.showMessage){
      forceUpdate(1)
    }
  },[validator?.showMessage])


  return (
    <>
      <div style={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
      }}>
          {
            value !== undefined && value !== "" ?(
                <div style={{
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center'
                }}>
                  <span>{value} $</span>
                  <span>{placeholder}</span>
                </div>
            ):null
          }
      </div>
      <reactstrap.Input
        className={`xc-input ${className}`}
        id={id}
        value={value||min}
        onChange={(e: any) => onChange(e)}
        type='range'
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        max={max}
        min={min}
        step={1000}
      />
      <div style={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between"
      }}>
        <span>{min} $</span>
        <span>{max} $</span>
      </div>
      {validator
        ? <div>{validator.message(validationName ? validationName : name,
          validationData ? validationData : value,
          validationRules ? validationRules : "") }</div>
        : ''}
      {
        validationMessage ? <div className='text-danger-step'>{validationMessage}</div> : <></>
      }
    </>
  );
};

export default PriceRange;
