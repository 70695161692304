import env from 'env';

export const CamundaApi = () => {
    const baseUrl = env.camundaApiUrl

    const send = (path, params) => {
        return new Promise((resolve, reject) => {
            fetch(`${baseUrl}${path}`, params).then(async (response) => {
                switch (response.status) {
                    case 200:
                        resolve(response)
                        break
                    case 400:
                    case 500:
                    case 404:
                        const parsedErrorResponse = await response.json();
                        reject(parsedErrorResponse)
                        break;
                }
            })
        })
    }

    const getProcessDefinition = (options) => {
        return send("/process-definition?latestVersion=true", {
            method: "GET"
        })
    }

    const startProcess = (taskItem) => {
        return send(`/process-definition/key/${taskItem?.key}/start`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: "{}",
        })
    }

    const getTaskByProcessId = (processId) => {
        return send(`/task?processInstanceId=${processId}`, {
            method: "GET"
        })
    }

    const doCompleteTask = (taskId, payload) => {
        return send(`/task/${taskId}/complete`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "content-type": "application/json",
            },
        })
    }

    const getProcessInstance = (processId, superProcessInstance) => {
        let url = `/process-instance`
        if (superProcessInstance) {
            url += `/?superProcessInstance=${processId}&active=true`
        }
        return send(url, {
            method: "GET"
        })
    }

    const getProcessActivity = (processId) => {
        return send(`/process-instance/${processId}/activity-instances`, {
            method: "GET"
        })
    }

    const doBackProcess = (processId, activityId, currentActivityInstanceId) => {
        return send(`/process-instance/${processId}/modification`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                "skipCustomListeners": true,
                "skipIoMappings": true,
                "instructions": [
                    {
                        "type": "startBeforeActivity",
                        "activityId": activityId,
                        "variables": {}
                    },
                    {
                        "type": "cancel",
                        "activityInstanceId": currentActivityInstanceId
                    }
                ],
                "annotation": "Modified to resolve an error."
            })
        })

    }

    const getVariableInstance = (processId) => {
        return send(`/variable-instance?firstResult=0&maxResults=200&deserializeValues=false`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                "processInstanceIdIn": [
                    processId
                ],
                "sorting": [
                    {"sortBy": "variableName", "sortOrder": "asc"}
                ]
            })
        })
    }

    const getActivityInstance = (processId) => {
        return send(`/process-instance/${processId}/activity-instances`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
            },
        })
    }

    const getHistoryOfProcess = (processId) => {
        return send(`/history/process-instance/${processId}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
            },
        })
    }

    return {
        getProcessDefinition,
        startProcess,
        getTaskByProcessId,
        doCompleteTask,
        getProcessInstance,
        getProcessActivity,
        doBackProcess,
        getVariableInstance,
        getActivityInstance,
        getHistoryOfProcess
    }
}

