const noAddOnCountries = ['Andorra', 'Argentina', 'Aruba', 'Bahrain', 'Brazil', 'Costa Rica', 'Czechia', 'Czech Republic', 'France', 'French Polynesia', 'Guam', 'Israel', 'Kuwait', 'Maldives', 'Montenegro', 'Occupied Palestinian territory', 'Panama', 'Peru', 'Puerto Rico', 'Saint Martin', 'Spain', 'Serbia and Montenegro'];

const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    items: [
      {
        current: { id: 'properties.Price.Total', name: '', config: { currency: true } },
        prev: { id: 'properties.Previous Price.Total', name: '', config: { currency: true, className: 'cross-line gray bold' } },
        config: {},
      },
    ],
  },
  body: {
    items: [
      {
        id: 'Price Breakdown',
        name: 'Price Breakdown',
        items: [
          {
            current: { id: 'properties.Price.Main Policy', name: 'Gross Premium', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Main Policy', name: 'Gross Premium', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Price.Admin Surcharge', name: 'Admin Surcharge', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Admin Surcharge', name: 'Admin Surcharge', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Price.Federal Excise Duty', name: 'Federal Excise Duty', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Federal Excise Duty', name: 'Federal Excise Duty', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Price.Fixed Insurance Fee', name: 'Fixed Insurance Fee', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Fixed Insurance Fee', name: 'Fixed Insurance Fee', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Price.Stamp Duty', name: 'Stamp Duty', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Stamp Duty', name: 'Stamp Duty', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Price.Withholding Tax', name: 'Withholding Tax', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Withholding Tax', name: 'Withholding Tax', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          // {
          //   current: { id: 'properties.Price.COVID-19 Add On', name: 'Covid-19 Add-On', config: { currency: true } },
          //   prev: { id: 'properties.Previous Price.COVID-19 Add On', name: 'Covid-19 Add-On', config: { currency: true, className: 'cross-line light-gray' } },
          //   config: { currency: true },
          // },

        ],
        config: {},
      },
      {
        id: 'Commission Breakdown',
        name: 'Commission Breakdown',
        items: [
          {
            current: { id: 'properties.Commission Amount', name: 'Commission Amount', config: { currency: true } },
            prev: { id: 'properties.Previous Commission', name: 'Commission Amount', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
        ],
        config: {},
      },
      {
        id: 'Other Details',
        name: 'Other Details',
        items: [
          { current: { id: 'properties.Destination', name: 'Destination', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.destinationCountry', name: 'Country', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Trip Type', name: 'Trip Type', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Start Date', name: 'Start Date', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.End Date', name: 'End Date', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Insured Type', name: 'Insured Type', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Adults', name: 'Adults', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Children', name: 'Children', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Senior Citizen (66-75)', name: 'Senior Citizen (66-75)', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Senior Citizen (76-80)', name: 'Senior Citizen (76-80)', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Senior Citizen (81-85)', name: 'Senior Citizen (81-85)', config: {} }, prev: { config: {} }, config: {} },
        ],
        config: {},
      },
      {
        id: 'COVID-19 Add On Details',
        name: 'COVID-19 Add On Details',
        items: [
          { current: { id: 'properties.Covid-19 Add On', name: 'Type', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Add On Start Date', name: 'Start Date', config: {} }, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Add On End Date', name: 'End Date', config: {} }, prev: { config: {} }, config: {} },
        ],
        config: {},
      },
    ],
  },
  getModelWithFilteredSections(properties) {
    const { 'Trip Type': tripType, destinationCountry } = properties;
    const result = { ...JSON.parse(JSON.stringify(this)) };
    if (tripType === 'Annual' || noAddOnCountries.includes(destinationCountry)) {
      result.body.items = result.body.items
        .filter((item) => item.id !== 'COVID-19 Add On Details')
        .map((item) => {
          if (item.id === 'Other Details') {
            const items = item.items.filter(({ current }) => current.name !== 'Country');
            return { ...item, items };
          }
          return item;
        });
    }
    return result;
  },
};

export default model;
