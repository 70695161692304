import React, {useState, useEffect} from 'react';
import {CamundaApi} from "../../processModeller/util/CamundaApi";
import {ProductApi} from '../util/ProductApi'
import {Product} from "../Types";
import {useDispatch, useSelector} from "react-redux";
import NotificationAction from "@redux/actions/notification";
import PolicyAction from "@redux/actions/policy";

import { getCodeSnippet } from '../util/util';
import { async } from 'q';
const notificationAction = new NotificationAction();
const policyAction = new PolicyAction();

interface Process{
    processId: string,
    name: string,
    type: string,
    id: number
}

export const AssignProcessToProductLogic = (product: any) => {
    const [productInfo, setProductInfo] = useState<Product | undefined>()
    const [process, setProcess] = useState<any>([])
    const [typeOfProcess, setTypeOfProcess] = useState<string>("");
    const [selectedProcess, setSelectedProcess] = useState<any>([])
    const [currentCodeSnippet, setCurrentCodeSnippet] = useState<string|undefined>();
    const [currentFile, setFile]= useState<File>()
    const dispatch = useDispatch()
    const processListFields: Array<IPair> = [
        {id: "id", value: "ID"},
        {id: "name", value: "name"},
        {id: "type", value: "Type"},
        {id: "processId", value: "Process Id"},
        {id: "createdAt", value: "Created At"},
        {id: "updatedAt", value: "Updated At"},
    ]

    useEffect(() => {
        getListOfProcess();
    }, [])
    useEffect(() => {
        if (product) {
            getProductInfo(product?.productId)
        }
    }, [product])


    const getProductInfo = async (productId: string) => {
       const templateInfo= await dispatch(policyAction.getTemplate(productId))
        ProductApi()
            .getProductById(productId)
            .then(async (response) => {
                const parsedData = await response.json();
                setProductInfo({
                    ...parsedData,
                    templateInfo: typeof templateInfo === 'object'? templateInfo : undefined
                })
            })
    }

    const getListOfProcess = () => {
        CamundaApi()
            .getProcessDefinition()
            .then(async (response) => {
                const parsedData = await response.json();
                console.log(parsedData)
                const data: Array<IPair> = [];
                parsedData.map((item: any) => {
                    data.push({
                        id: item.key,
                        value: item.name
                    })
                })
                setProcess(data)
            })
    }

    const handleChangeSelectedProcess = (item: any) => {
        setSelectedProcess(item.value)
    }

    const updateProcessListOfProduct = () => {

    }

    const addProcessToProduct = () => {
        const payload = {
            ...product,
            processes: [
                {
                    "name": selectedProcess?.label,
                    "processId": selectedProcess?.value,
                    "type": typeOfProcess,
                }
            ]
        }
        ProductApi().updateProcessListOfProduct(payload)
            .then(async (response: any) => {
                if (response.status === 200) {
                    if (productInfo) {
                        getProductInfo(productInfo?.productId)
                        clearForm();
                    }
                }
                const parsedData = await response.json();
            }).catch(() => {
        })
    }
    const clearForm = () => {
        setTypeOfProcess("")
    }

    const removeProcessItem= (productId:number)=>{
        ProductApi().deleteProcessItem(productId).then(async (response)=>{
            if(response.status === 200){
                const newProductInfo= {
                    ...productInfo,
                    processes: productInfo?.processes.filter((el)=>parseInt(el.id) !== productId)
                }
                setProductInfo(newProductInfo as (Product | undefined))
            }
        })
    }
    const showCodeSnippet = (row:IObject)=>{
        const { processId }= row;
        setCurrentCodeSnippet(processId)
    }
    const copyCodeSnippet = ()=>{
        if(currentCodeSnippet){
            navigator.clipboard.writeText(getCodeSnippet(currentCodeSnippet));
            dispatch(notificationAction.success('success', 'Copy Done', ''))
        }
    }
    const downloadTemplate = async (productId: string, templateName: string) => {
        const templateInfo= await dispatch(policyAction.downloadTemplate(productId, templateName))
        //@ts-ignore
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //@ts-ignore
            window.navigator.msSaveOrOpenBlob(templateInfo, templateName);  
          }
          else {
             //@ts-ignore
             let link = document.createElement('a');
             //@ts-ignore
             link.href = window.URL.createObjectURL(templateInfo);
             link.download = templateName;
             document.body.appendChild(link);
             link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
             link.remove();
             window.URL.revokeObjectURL(link.href);
          }
     }
     const uploadNewTemplate = (e: any) =>{
        setFile(e.target.files[0])
     }
     const updateTemplateInputs = (key:string,e:any)=>{
        
        const copyProducInfo:any = {...productInfo};
        if(copyProducInfo.templateInfo === undefined){
            copyProducInfo.templateInfo= {}
            copyProducInfo.templateInfo={
                new_policy_sms:{
                    content: ''
                },
                new_policy_email:{
                    content: ''
                }
            };
        }
        copyProducInfo.templateInfo[key].content=e.target.value;
    
        setProductInfo(copyProducInfo)
    }
    const updateTemplate = async() =>{
        try{
        const response = await dispatch(policyAction.updateTemplate(
        productInfo?.productId||'',
        '',
        productInfo?.templateInfo?.new_policy_sms?.content||'',
        productInfo?.templateInfo?.new_policy_email?.content||''
        ,currentFile))
        dispatch(notificationAction.success('success', 'Template has been updated', ''))
        }catch(e){
            dispatch(notificationAction.success('error', 'Something went wrong', ''))
        }
    }

    return {
        process,
        handleChangeSelectedProcess,
        selectedProcess,
        updateProcessListOfProduct,
        setTypeOfProcess,
        typeOfProcess,
        addProcessToProduct,
        processListFields,
        productInfo,
        removeProcessItem,
        showCodeSnippet,
        currentCodeSnippet,
        copyCodeSnippet,
        downloadTemplate,
        uploadNewTemplate,
        updateTemplateInputs,
        updateTemplate
    }
}
export default AssignProcessToProductLogic