import React from "react";
import {Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Row} from "reactstrap";
import {OrganisationInput} from "../TeamManagementModule/OrganizationModule/containers";


const Products = (props) => {
  const {collections, productsOnState, handleProductChange} = props;
  const permissionStatuses = collections ? collections.permissionStatuses : [];
  return (
    collections && collections.products.length ?
      <Row className="justify-content-center my_50">
        <Col sm={10}>
          <ListGroup  className="list shadow-sm rounded-xl">
            <ListGroupItemHeading className="text-primary p-3">
              <ListGroupItemText>
                Products
              </ListGroupItemText>
            </ListGroupItemHeading>
            { collections.products.map((product, index) => {
              /**
               * check if this product has been changed (on the state)
               * and then update the dropdown value accordingly
               **/
              const changedProduct = productsOnState.find(p => p.productName === product.productName);
              return (
                <ListGroupItem key={index} className="border-0 border py-0">
                  <Row className="justify-content-between align-items-center">
                    <Col sm={4} className="py-2">
                      {product.productName}
                    </Col>
                    <Col sm={4} className="justify-end">
                      <OrganisationInput
                        option={permissionStatuses}
                        idName="products"
                        type="select"
                        onChange={(e) => handleProductChange(index, e)}
                        value={ changedProduct ? changedProduct.status : product.status }
                        validator={""}
                      />
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </Col>
      </Row> : ""
  );
};

export default Products;
