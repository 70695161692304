import React from 'react';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
} from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import config from '@xc-core/config';
import { utils } from 'utils';
import { OrganisationInput } from '../containers';

const AgencyTypes = ['Agency', 'Bank Agency'];

const RenderCommissionSetting = (props) => {
  const {
    commission,
    handleCommissionChange,
    isAgencySelected,
    validator,
    collections,
  } = props;

  const showPriceField = () => {
    return commission.type === "Percentage";
  };

  return (
    <Row className='align-items-center'>
      <div className='col-3'>
        <Label>All Sales</Label>
      </div>
      <div className='col-5 d-flex value-type'>
        <Row className='whole-width'>
          <div className='col-4 percentage-container'>
            <input
              type='text'
              name='value'
              data-id=''
              id='value'
              className='percentageInput px-1'
              placeholder='Enter a value'
              value={commission.value}
              onChange={handleCommissionChange}
            />
          </div>
          <div className='col-8 select-container pl-0'>
            <span className='d-flex align-items-center justify-content-end'>
              <Input
                className='blue-back'
                type='select'
                name='type'
                id='type'
                value={commission.type}
                onChange={handleCommissionChange}
              >
                <option selected disabled value=''>Select Option</option>
                {collections.commissionType.map((type, index) => {
                  return <option key={index} value={type.name}>{type.name}</option>;
                })}
              </Input>
              <i className='icon-downArrow text-primary px-2' />
            </span>
          </div>
          {validator.message(
            'number', commission.value,
            `${isAgencySelected ? 'required' : 'not_required'}`,
          )}
        </Row>
      </div>
      {
        config.showExtendedCommissionSettings === false ? <></>
          : (
            <div className='col-4 align-self-baseline'>
              {
                showPriceField() === false ? <></>
                  : (
                    <div className='col-12 select-container d-flex align-items-center justify-content-end'>
                      <Input
                        type='select'
                        name='priceType'
                        id='priceType'
                        value={commission.priceType}
                        onChange={handleCommissionChange}
                      >
                        <option selected disabled value=''>Select Option</option>
                        {collections.priceField.map((data) => {
                          return (
                            <option value={data.code} key={data.code}>
                              {data.name}
                            </option>
                          );
                        })}
                      </Input>
                      <i className='icon-downArrow text-primary px-2' />
                    </div>
                  )
              }
            </div>
          )
      }
    </Row>
  );
};
class CreateOrgForm extends React.Component {
  constructor(props) {
    super(props);

    const { collections } = this.props;
    this.state = {
      commissionTier: [],
      orgNameDuplicate: false,
      typeFilteredParentOrgs: [],
      isAgencySelected: false,
      commission: {
        type: collections && collections.commissionType.length > 0 ? collections.commissionType[0].code: '',
        value: '',
        priceType: config.defaultPriceField,
      },
    };

    this.validator = new SimpleReactValidator({
      element:(message, className) => <small className={`${className} text-danger`}>{message}</small>,
      className: 'text-danger',
      messages: {
        email: 'That is not an email.',
      },
      validators: {
        rootNode: {
          message: 'The :attribute must be a valid IP address.',
          rule: (val, params, validator) => false,
        },
        uniqueOrgName: { // organisation name should be unique
          message: 'Organisation already exists.',
          rule: () => (!this.state.orgNameDuplicate), // return false if there is another organisation with the same name
        },
        not_required: {
          rule: () => true,
        }
      }
    })
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      orgName,
      orgParent,
      orgType,
      orgUnitUserCode,
      orgCompanyName,
      commission,
    } = this.state;
    let orgValues = {
      name: orgName,
      description: '',
      parent: orgParent,
      status: true,
      extendedProperties: {
        type: orgType,
        orgUnitUserCode,
        companyName: orgCompanyName
      },
    };
    if (AgencyTypes.includes(orgType)) {
      orgValues = {
        ...orgValues,
        extendedProperties: {
          ...orgValues.extendedProperties,
          commission: {
            value: commission.value,
            type: commission.type,
            priceField: commission.priceType,
          },
          commissionTier: [],
        },
      };
    }

    if (this.validator.allValid()) {
      const createOrgResponse = await this.props.handleSubmmit(orgValues);
      if (createOrgResponse.error && createOrgResponse.message === 'Organization already exists') {
        this.setState({orgNameDuplicate: true});
        this.validator.showMessages();
        this.forceUpdate();
      }
      if (!createOrgResponse.error) {
        // get all organizations after creating org successfully
        await this.props.getList(0, 10);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInputChange = (event) => {
    const { target } = event;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    if (name === 'orgName') this.setState({ orgNameDuplicate: false });
    // if (name === 'commissionValue') {
    //   const { [name]: commissionValue } = this.state;
    //   value = (value === '' || utils.lib.isNumeric(value)) ? value : this.state.commissionValue;
    // }

    this.setState({ [name]: value });
    const { collections } = this.props;
    if (name === 'orgType') {
      const parentTypes = collections.orgUnitType.find(type=> (type.name === value)).extendedProperties.parentTypes;
      let parents = collections.parents.filter(parent => (parentTypes.includes(parent.extendedProperties.type)));
      this.setState({ typeFilteredParentOrgs: parents });
      if (AgencyTypes.includes(value)) {
        this.setState({ isAgencySelected: true });
      } else this.setState({ isAgencySelected: false });
    }
  }

  isParentOrgDisabled = () => {
    const { collections } = this.props;
    const { orgType } = this.state;
    if (orgType === undefined) {
      return false;
    }
    return collections.orgUnitType.find(type=> (type.name === orgType)).extendedProperties.parentTypes.includes('');
  }

  orgCodeChange = (e) => {
    const value = e.target.value;
    if (value === '' || utils.lib.isOnlyNumberOrAlphabet(value)) {
      return this.handleInputChange(e);
    }
  }

  handleCommissionChange = (e) => {
    const id = e && e.target && e.target.id;
    const value = e && e.target && e.target.value;

    this.setState((prevState) => (
      {
        ...prevState,
        commission: {
          ...prevState.commission,
          [id]: value,
        },
      }
    ));
  }

  render() {
    let { commissionTier, isAgencySelected } = this.state;

    const { collections } = this.props;
    const { orgUnitType } = collections;
    return (
      <form onSubmit={this.onSubmit}>
        <OrganisationInput
          idName='orgName'
          type='input'
          value={this.state['orgName']}
          onChange={(e) => this.handleInputChange(e)}
          title='Organisation Name'
          validator={this.validator}
          validationRules='uniqueOrgName'
        />
        <OrganisationInput
          idName='orgType'
          option={orgUnitType}
          type='select'
          title='Organisation Type'
          value={this.state['orgType'] ||  ''}
          onChange={(e) => this.handleInputChange(e)}
          validator={this.validator}
        />
        <OrganisationInput
          type='text'
          idName='orgUnitUserCode'
          title='Organisation Code'
          onChange={(e) => this.orgCodeChange(e)}
          value={this.state['orgUnitUserCode'] ||  ''}
          validator={this.validator}
          placeholder='Enter organisation code'
          // validationRules='min:6|max:6'
        />
        <OrganisationInput
          idName='orgParent'
          option={this.state.typeFilteredParentOrgs}
          type='select'
          title='Parent Organisation'
          value={this.state['orgParent'] || ''}
          disabled={this.isParentOrgDisabled()}
          onChange={(e) => this.handleInputChange(e)}
          validator={this.validator}
        />
        <div className={`commission ${isAgencySelected ? 'wide' : ''}`}>
          <FormGroup className={isAgencySelected ? '' : 'd-none'}>
            <Label for='exampleSelect'>Commission Settings</Label>
            { commissionTier && commissionTier.length
              ? ''
              : (
                <RenderCommissionSetting
                  commission={this.state.commission}
                  handleCommissionChange={this.handleCommissionChange}
                  isAgencySelected={isAgencySelected}
                  validator={this.validator}
                  collections={collections}
                />
              )}
          </FormGroup>
        </div>
        <OrganisationInput
          idName='orgCompanyName'
          type='input'
          value={this.state['orgCompanyName'] || ''}
          onChange={this.handleInputChange}
          title='Company Name'
          validator=''
        />
        <div className='row justify-content-end' id='modalButton'>
          <div className='col-2'>
            <Button onClick={this.props.toggle} className='text-info' color='link'>
              Cancel
            </Button>
          </div>
          <div className='col-3'>
            <Button
              color='primary'
              className='fullWidth rounded-xl'
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default CreateOrgForm;
