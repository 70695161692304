import React, {FC} from 'react';
import {Col, Row} from "reactstrap";
import RenderDetail from './RenderDetail'

type Props = {
    info: any,
    title: string
    subtitle?: string
}


export const RenderApplicantInfo: FC<Props> = ({info, title,subtitle}) => {
    return (
        <Row>
            <Col sm={12}>
                <h4 className='beneficiary-header my-3 p-3'>
                    {title}
                </h4>
                <Row className='wordBreak'>
                    <Col sm={12}>
                        <div className='custom-q-text-primary bold my-2'>{subtitle}</div>
                        <Row>
                            {
                                info ? Object.keys(info).map((item: any) => {
                                    return <RenderDetail key={item} name={item} value={info[item]}/>;
                                }) : <></>
                            }
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}