import React, {useState, useEffect} from 'react';
import lib from 'utils/lib';
import {ProductApi} from "../util/ProductApi";

export const AddNewProductLogic = (reload: any) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [nameOfProduct, setNameOfProduct] = useState<string>("")
    const [slugOfProduct, setSlugOfProduct] = useState<string>("")

    useEffect(() => {
        if (nameOfProduct && nameOfProduct.length > 1) {
            const slug = lib.slugify(nameOfProduct)
            setSlugOfProduct(slug)
        }
    }, [nameOfProduct])

    const saveProduct = () => {
        const payload = {
            id: 0,
            name: nameOfProduct,
            productId: slugOfProduct,
            processes: []
        }

        ProductApi().updateProcessListOfProduct(payload)
            .then(async (response: any) => {
                if (response.status === 200) {
                    alert("Added As well")
                    if (reload && typeof reload === "function") {
                        reload();
                        closeAndClear()
                    }
                }
            }).catch(() => {
        })
    }

    const closeAndClear = () => {
        setShowModal(false)
        setSlugOfProduct("")
        setNameOfProduct("")
    }

    return {
        showModal,
        setShowModal,
        nameOfProduct,
        slugOfProduct,
        setNameOfProduct,
        setSlugOfProduct,
        saveProduct
    }
}
export default AddNewProductLogic