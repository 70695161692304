import React from 'react';
import StepConnector from '@material-ui/core/StepConnector';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { withStyles } from '@material-ui/core/styles';
import { utils } from "utils"

const styles = (theme: any) => ({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    width: '90%',
  },
  removePadding: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: '#fcfcfc',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.grey[100],
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
    borderTopWidth: 3,
    borderRadius: 7,
  },
});

interface IProps { currentStep: IStep, steps: IStep[], classes: any}

const CustomStepper = (props: IProps) => {
  const { steps, currentStep, classes } = props;

  const getTitle = () => (`${currentStep.name} - Step ${currentStep.order} of ${steps.length} ${currentStep.khmerName ? `/ ${currentStep.khmerName} - ជំហានទី${utils.constants.khmerNumber[currentStep.order - 1]}នៃជំហានទាំង${utils.constants.khmerNumber[steps.length - 1]}` : ''}`);
  const show = () => currentStep.order < steps.length;
  // console.log(classes)
  const connector = (
    <StepConnector
      classes={{
        active: `${classes.connectorActive} step-connector-active`,
        completed: `${classes.connectorCompleted}  step-connector-completed`,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
      }}
    />
  );

  return (
    show() === false ? <></>
      : (
        <>
          <h5 className='text-medium'>{getTitle()}</h5>
          <Stepper
            className='stepper'
            orientation='horizontal'
            classes={{ horizontal: classes.removePadding }}
            activeStep={currentStep.order}
            connector={connector}
          >
            {steps.map((step:IStep) => (<Step key={step.name} />))}
          </Stepper>
        </>
      ));
};

export default withStyles(styles)(CustomStepper);
