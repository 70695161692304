import moment from "moment";
import {
  CONFIG_DATE_MOMENT_FORMAT,
  config,
} from "utils";
import constants from "./constants";
import * as momentTimeZone from "moment-timezone";
import env from "./../env";

const getCurrentDateTimeZone = (tz = config.timeZoneServer) => {
  const time = new Date().toLocaleString("en-US", { timeZone: tz });
  return new Date(time);
};
const getOffsetByTimeZoneName = (tz = config.timeZoneServer) => {
  const offset = parseInt(moment.tz(tz).utcOffset(), 10);
  const minutes = Math.abs(offset);
  const hours = Math.floor(minutes / 60);
  const prefix = offset < 0 ? "-" : "+";
  return prefix + hours;
};
const isAfter = (d1, d2) => {
  const d1Str = moment(d1).format("YYYY-MM-DD");
  const d2Str = moment(d2).format("YYYY-MM-DD");
  return moment(d1Str).isAfter(d2Str, "day");
};
const getAgeFromDob = (param) => {
  const dob = param instanceof Date ? param : moment(param, CONFIG_DATE_MOMENT_FORMAT)._d;
  const today = new Date();
  let age = today.getFullYear() - dob.getFullYear();
  const monthDif = today.getMonth() - dob.getMonth();
  if (monthDif < 0 || (monthDif === 0 && today.getDate() < dob.getDate())) {
    age = age - 1;
  }
  return age;
};
const getDateString = (date, format) => {
  const m = moment(date); // .format(format);
  return m.isValid() ? m.format(format) : "";
};

const applyConfigFormat = (date) => {
  const dm = moment(date, CONFIG_DATE_MOMENT_FORMAT);
  return dm.isValid() ? dm.format(CONFIG_DATE_MOMENT_FORMAT) : "";
};

const getDate = (str, format) => {
  const m = moment(str, format);
  return m.isValid() ? m._d : null;
};

const subtractTodayByAge = (age) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - age);
  return date;
};

const formatNumberInCommaSeparated = (n = 0) => {
  let s = Number(n).toLocaleString(); // to get the comma separators
  if (s.indexOf(".") === -1) {
    s = `${s}.00`;
  } else if (s.split(".")[1].length === 1) {
    s = `${s}0`;
  }
  return s;
};
const addPrefixCurrency = (n, format) => (n < 0 ? `-${format}${(-1) * n}` : `${format}${n}`);
const getGenderFromN = (n) => (Number(n) % 2 === 0
  ? constants.genders.FEMALE : constants.genders.MALE);

const isOnlyNumber = (value) => /^\d+$/.test(value);
const isArray = (variable) => variable && variable.constructor === Array;
const isOnlyNumberOrAlphabet = (value) => /^[a-z0-9]*$/i.test(value);
const exportResponseToFile = (response) => {
  if (!response.error) {
    const blob = response.blobResponse;
    const { fileName } = response;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const a = document.createElement("a");
      a.href = (window.URL || window.webkitURL).createObjectURL(blob);
      a.setAttribute("download", `${fileName}`);
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
    }
  }
};

const isEmpty = (value) => typeof value === "undefined" || value === null || value === false || value === "";
const isNumeric = (value) => !isEmpty(value) && !Number.isNaN(Number(value));
const isEdge = () => navigator.appVersion.indexOf('Edge') > 1;

const generateCsvFileName = (isQutation, pii, productName = '') => {
  let parsedProductName = productName;
  if (productName.indexOf('-') > 0) {
    const splitedProductName = productName.split('-');
    parsedProductName = String(splitedProductName[0]).charAt(0).toUpperCase() + String(splitedProductName[0]).slice(1)
    +"_"+String(splitedProductName[1]).charAt(0).toUpperCase() + String(splitedProductName[1]).slice(1);
  }

  const productType = isQutation ? 'Quotation' : 'Certificate';
  const piiType = pii ? 'AllFields' : 'NonPII';
  const date = moment().format('YYYYMMDD');
  return `${parsedProductName}_${productType}_${piiType}_${date}.csv`;
};

const isGreaterThan = (prevValue, current) => {
  const preN = parseFloat(String(prevValue).replace(',', ''), 10);
  const currentN = parseFloat(String(current).replace(',', ''), 10);
  return preN > currentN;
};

const resolveObjKey = (obj, path) => {
  if (!path) return null;

  return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);
};
const checkIsDemoSite = () => {
  let isDemoSite=false;
  if(
      env.tsd.search('https://test-demo-xc.xpresscover.io') >= 0 ||
      env.tsd.search('https://prod-demo-xc.xpresscover.io') >=0  ||
      env.tsd.search('https://test-demo-portal.coverplus.io') >=0  ||
      env.tsd.search('https://prod-demo-portal.coverplus.io') >=0  ||
      env.tsd.search('https://demo-xc.xpresscover.io') >=0)
  {
    isDemoSite=true
  }
  return !isDemoSite
}

const slugify = str =>
    str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

const lib = {
  isAfter,
  getAgeFromDob,
  getGenderFromN,
  getDateString,
  getDate,
  formatNumberInCommaSeparated,
  addPrefixCurrency,
  isOnlyNumber,
  isArray,
  exportResponseToFile,
  isOnlyNumberOrAlphabet,
  subtractTodayByAge,
  applyConfigFormat,
  isEmpty,
  isNumeric,
  getCurrentDateTimeZone,
  getOffsetByTimeZoneName,
  resolveObjKey,
  isEdge,
  generateCsvFileName,
  isGreaterThan,
  checkIsDemoSite,
  slugify
};

export default lib;
