
import { PolicyService } from '@xc-core/services';
import { policyConstants } from 'Products/shared/policyListing/constants';
import actionTypes from '@redux/actionTypes';
import {
  requestLib,
  filterLib,
  listLib,
  mediaLib,
} from '@xc-core/lib';
import BaseListAction from './baseListing';

class PolicyAction extends BaseListAction {
  constructor() {
    super();
    this.service = new PolicyService();
  }

  protected actions = actionTypes.policy;

  protected service: PolicyService;

  protected csvExportScope = 'Export Certificates to CSV';

  protected createScope = '';

  protected editScope = '';

  public getDashboard = () => async (dispatch: any) => {
    dispatch(this.createAction(this.actions.DASHBOARD_LOADING));
    try {
      const result = this.service.getDashboard();
      dispatch(this.createAction(actionTypes.policy.POLICY_DASHBOARD_GET, result));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(actionTypes.policy.POLICY_DASHBOARD_FORBIDDEN));
      }
      dispatch(this.createAction(actionTypes.policy.POLICY_DASHBOARD_FAILED, error));
    }
  }

  public changeViewType = (pair: IPair) => async (dispatch: any) => {
    const isFlat = pair.value === this.getViewTypeList()[1].value;
    dispatch(this.createAction<boolean>(this.actions.CHANGE_VIEW_TYPE, isFlat));
  }

  public setList = (params: IRequestParam) => async (dispatch: any, getState: any) => {
    dispatch(this.createAction(this.actions.LIST_LOADING));
    try {
      const { filters, viewTypeIsFlat, filterToday } = getState().policyList;
      const stringParams = requestLib.convertToRequestParams(params);
      let result = await this.service.getList(stringParams);
      const fields = listLib.convertToIdValueTypeList(result.fields, ['id', 'displayName', 'type']);
      let initialFilters = filterLib.parse(result.filters, params);
      if (filterToday) initialFilters = filterLib.applyTodayCreatedOn(initialFilters);
      result = {
        ...result,
        collections: this.prepareCollections(result.collections),
        list: this.applyListFormat(result.list.content, fields),
        fields,
        initialFilters,
        filters: filters.length > 0 ? filters : initialFilters,
        prevFilters: filters.length > 0 ? filters : initialFilters,
        pageInfo: this.createPageInfo(result.list),
        tab: this.createTabPair(params),
        searchKeyword: params.name ? params.name[0] : '',
        viewTypeIsFlat,
      };
      dispatch(this.createAction<IObject>(this.actions.LIST_GET, result));
      dispatch(this.createAction(this.actions.LIST_RESET_TODAY_FILTER));
    } catch (error) {
      if (error.status === 401) {
        dispatch(this.createAction(this.actions.LIST_SET_FORBIDDEN));
      }
      if (error.status === 404) {
        dispatch(this.createAction(this.actions.LIST_SET_NOT_FOUND));
      }
      dispatch(this.createAction(this.actions.LIST_FAILED, error));
    }
  }

  public getSearchParams = () => (dispatch: any, getState: any): IRequestParam => {
    const { policyList } = getState();
    return this.getSearchParamsByItem(policyList);
  }

  public setHasRecordsInitially= () => async (dispatch: any, getState: any) => {
    const { list } = getState().policyList;
    const hasRecords = list && list.length > 0;
    dispatch(this.createAction<boolean>(this.actions.LIST_SET_HAS_RECORDS_INITIALLY, hasRecords));
  }

  public setTodayFilter = () => (dispatch: any) => {
    const action = this.createAction(this.actions.LIST_SET_TODAY_FILTER);
    dispatch(action);
  }

  public download = (id: string) => async (dispatch: any) => {
    try {
      const result = await this.service.download(id);
      const newTab = true;
      mediaLib.downloadFile('certificate.pdf', result, newTab);
      // dispatch(this.createAction(actionTypes.policy.POLICY_DOWNLOAD));
    } catch (error) {
      dispatch(this.createAction(actionTypes.policy.POLICY_DOWNLOAD_FAILED, error));
    }
  }

  public setOccupationList = () => async (dispatch: any): Promise<void> => {
    const collectionId = 'occupation_gbsn';
    try {
      const result = await this.service.getCollectionList([collectionId]);

      // const prepared = this.prepareCollections(result);
      // console.log('setOccupationList,result', result);
      // console.log('setOccupationList,result', prepared);
      const occupationList = { occupation: result[collectionId] };
      // console.log('updatedCollections', occupationList);
      dispatch(this.createAction<IObject>(policyConstants.POLICY_UPDATE_COLLECTIONS, occupationList));
    } catch (error) {
      console.error('setOccupationList ', error);
    }
  }

  public getWitnessSignatureURL = (refId: string) => async (): Promise<any> => {
    try {
      // const { collections } = getState().quotationManagement;
      const result = await this.service.getWitnessSignature(refId);
      const url = URL.createObjectURL(result);
      return url;
    } catch (error) {
      console.error('getPolicyWitnessSignature ', error);
      return '';
    }
  }

  private getViewTypeList = (): IPair[] => [{ value: 'Nested View', id: 'Nested View' },
    { value: 'List View', id: 'List View' }];

  private prepareCollections = (obj: IObject): IObject => ({
    ...obj,
    status: this.addAllToList(this.getStatusList()),
    viewType: this.getViewTypeList(),
  })

  private getStatusList = (): IPair[] => ([
    { id: 'Not in Effect', value: 'Not in Effect' },
    { id: 'In Effect', value: 'In Effect' },
    { id: 'Expired', value: 'Expired' },
    { id: 'Cancelled', value: 'Cancelled' },
    { id: 'Archived', value: 'Archived' },
  ]);

  public getTemplate = (productSlug: string) => async (dispatch: any): Promise<any> => {
    try {
      const result = await this.service.getTemplate(productSlug);
      return result
    } catch (error) {
      return '';
    }
  }
  public downloadTemplate = (productSlug: string, templateName: string) => async (dispatch: any): Promise<any> => {
    try {
      const result = await this.service.downloadTemplate(productSlug, templateName);
      return result
    } catch (error) {
      return '';
    }
  }
  public updateTemplate = (productSlug: string,templateName: string, smsTemplate: string, emailTemplate: string, certificateTemplate: any) => async (dispatch: any): Promise<any> => {
    try {
      const result = await this.service.updateTemplate(productSlug,templateName,smsTemplate,emailTemplate,certificateTemplate)
      return result
    } catch (error) {
      throw error
      return '';
    }
  }
}

export default PolicyAction;
