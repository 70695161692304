import React from 'react';
import { Row, Col } from 'reactstrap';
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import Detail from './detail';

const Summary = (props: ILoadableComponentProps) => {
  const {
    model: {
      item,
      model,
    },
  } = props;
  return (
    <Row>
      <Col md='8'>
        <Detail
          item={item}
          mainModel={model.mainModel}
          beneficiaryModel={model.beneficiaryModel}
        />
      </Col>
      <Col md='4'>
        <SidebarCard.Quotation
          item={item}
          model={sidebarModel.quotation.travelCB}
          ext={true}
        />
      </Col>
    </Row>
  );
};

export default Summary;
