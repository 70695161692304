import React, {useEffect, useState} from 'react'
import {ProductApi} from './util/ProductApi'

export const ProductLogic = () => {
    const [isLoading, setIsLoading] = useState<Boolean>(false)
    const [products, setProducts] = useState<any>(undefined)
    const [currentProduct, setCurrentProduct] = useState<any>(undefined)
    const [isModalOpen, setModalOpen] = useState(false)
    
    const fields: Array<IPair> = [
        {id: "id", value: "ID"},
        {id: "name", value: "name"},
        {id: "createdAt", value: "Created At"},
        {id: "updatedAt", value: "Updated At"},
        {id: "deletedAt", value: "Deleted At"},
    ]


    useEffect(() => {
        getListOfProduct()
    }, [])

    const getListOfProduct = () => {
        setIsLoading(true)
        ProductApi()
            .getListOfProduct()
            .then(async (response) => {
                if (response.status == 200) {
                    const parsedResponse = await response.json();
                    setProducts(parsedResponse)
                }
                setIsLoading(false)
            })
    }
    const assignProcessesToProduct = (item: any) => {
        if (item) {
            setCurrentProduct(item)
            setModalOpen(true)
        }
    }

    return {
        isLoading,
        fields,
        products: products || [],
        assignProcessesToProduct,
        currentProduct,
        isModalOpen,
        setModalOpen,
        reload:getListOfProduct
    }
}

export default ProductLogic
