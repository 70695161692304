import env from 'env';

export const CamundaApi = () => {
    const baseUrl = env.camundaApiUrl

    const send = (path, params) => {
        return fetch(`${baseUrl}${path}`, params)
    }

    const deployXML = (options) => {

        const {fileName, tenantId, deploymentSource, xml} = options;

        const file = new File([xml], fileName, {type: "text/xml"})

        const formData = new FormData();

        formData.append("data", file, fileName);
        formData.append("deployment-source", deploymentSource);
        formData.append("deploy-changed-only", false);
        formData.append("enable-duplicate-filtering", false);

        if (tenantId) {
            formData.append("tenant-id", tenantId);
        }

        return send("/deployment/create", {
            method: "POST",
            body: formData
        })
    }
    const getProcessDefinition = (options) => {
        return send("/process-definition?latestVersion=true", {
            method: "GET"
        })
    }
    const getDecisionDefinitions = (latestVersion) => {
        return send(`/decision-requirements-definition?latestVersion=${latestVersion || false}`, {
            method: "GET"
        })
    }

    const loadProcessXmlBykey = (key) => {
        return send(`/process-definition/key/${key}/xml`, {
            method: "GET"
        })
    }
    const loadDecisionDefinitionByKey=(key)=>{
        return send(`/decision-requirements-definition/key/${key}/xml`,{
            method: "GET"
        })
    }



    return {

        deployXML,
        getProcessDefinition,
        loadProcessXmlBykey,
        getDecisionDefinitions,
        loadDecisionDefinitionByKey
    }
}