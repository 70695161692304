import React,{useState} from 'react';
import {FormEdit, FormBuilder as FormIOBuilder, Formio} from '@formio/react';
import {FormBuilderLogic} from "./FormBuilder.logic";
import bannerImg from "../../images/bannerGraphicImages/organisations.png";
import {Header} from "../../@xc-core/components/core";
import FormListModal from "./FormList";
import customComponents from './CustomComponent';
import './style.css'
import { FormBuildOptions } from './Constant/constant';

Formio.use({
  components: {
     ekyc: customComponents.Ekyc
  }
});

export const FormBuilder = () => {
    const [showModal, setShowModal] = useState(false);
    const {isLoading, saveForm, getCurrentForm,getFormList,formList} = FormBuilderLogic();

    if (isLoading) {
        return <h1>Loading...</h1>
    }

    const formListModalHandler=(status)=>{
        setShowModal(status || !showModal);
    }

    return (
        <>
            <Header
                title='Forms'
                bannerGraphic={bannerImg}
                withBorderBottom
            />
            <div className={"container-form-builder"}>
                <div
                    style={{
                        width:'15%',
                        paddingBottom:10,
                    }}
                >
                    <button
                        className={'form-builder-button'}
                        onClick={()=>{
                            getFormList().then(()=>{
                                formListModalHandler()
                            })
                        }}
                    >
                        List of Form
                    </button>
                </div>
                <FormEdit saveForm={saveForm}
                    form={getCurrentForm()} 
                    options={FormBuildOptions}
                    saveText="Save">
                    <FormIOBuilder onChange={(schema) => console.log(schema)} />
                </FormEdit>
            </div>
            <FormListModal
                items={formList}
                isOpen={showModal}
                openHandler={(status)=>formListModalHandler(status)}
            />
        </>
    )

}
export default FormBuilder