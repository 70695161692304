import React from 'react';
import {Route, Switch} from "react-router-dom";
import ProcessModelExecutor from './index'
import ProcessModelExecutorLoadForm from "./ProcessModelExecutorLoadForm";

export const ModelExecutorStack=(props:any) =>{
    return (
        <Switch>
            <Route exact path='/process-model-executor' component={ProcessModelExecutor} />
            <Route exact path='/process-model-executor/:taskKey' component={ProcessModelExecutorLoadForm} />
        </Switch>
    );
}
export default ModelExecutorStack