import {Formio} from '@formio/react';
const FieldComponent = Formio.Components.components.field;

class Ekyc extends FieldComponent {
    constructor(component, options, data) {
      super(component, options, data);
      const getProperty = (key, defaultValue) => (component.properties && component.properties[key]) || defaultValue;
      
      this.inputUrl = getProperty('url', 'http://localhost:3002/');
      this.inputWidth = getProperty('width', '100%');
      this.inputHeight = getProperty('height', '300px');

      this.ekycResponse = '';
    }
    static schema() {
      return FieldComponent.schema({
        type: 'ekyc',
      });
    }
  
    static builderInfo() {
      return {
        title: 'Ekyc',
        schema: Ekyc.schema(),
      };
    }

  init() {
    window.addEventListener('message', (event) => {      
      // Check the origin to ensure it's the expected one
          this.ekycResponse = event.data;
        // Handle the received message here
          switch(this.ekycResponse){
            case 'ekycResponseSuccess':
              this.setValue(this.ekycResponse);
              this.showSuccessMessage();
              break;
            case 'ekycResponseFailed':
              console.warn('failed')
              break;
          }
      });
    super.init();
    }

    render(children) {
      return super.render('<div ref="ekyc"></div>');     
    }
  
    attach(element) {
      const refs = {};
      
      refs[this.component.key] = "ekyc"
      this.loadRefs(element, refs);

      this.ekyc = Array.prototype.slice.call(this.refs[this.component.key], 0)[0];      

      if (!this.isEditMode() && this.ekyc)  {
        // render iframe
        this.ekyc.innerHTML = `<iframe src="${this.inputUrl}" allow="camera" width="${this.inputWidth}" height="${this.inputHeight}" frameborder="0"></iframe>`;
      }
      return super.attach(element);
    }

    isEditMode() {      
      const currentPath = window.location.href;             
      if (currentPath.includes('form-builder'))  {        
        return true;
       }
       else {
        return false;
       } 
    }

    getValue() {
      var value = [];
      return value;

    }
  
    setValue(value) {
      return super.setValue(value);
    }


    showSuccessMessage() {
      this.ekyc.innerHTML = `<span>eKYC Success</span>`
    }
}

export default Ekyc;