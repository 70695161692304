import React from 'react';
import { userMessages } from '@xc-core/constants';
import { connect } from 'react-redux';
import * as routes from '@xc-core/routes';
import {
  Message, Tab, Loader,
  Table, Filter, ExportButton,Header
} from '@xc-core/components/core';
import { MultiProductHeader } from '@xc-core/components/core/header';
import { Col } from 'reactstrap';
import { mediaLib } from '@xc-core/lib';
import { actions } from '@redux';
import bannerImg from '../../images/bannerGraphicImages/get-quote.png';
import BaseListingContainer from '../baseListingContainer';
import ListViewType from './listViewType';

const policyActions = new actions.PolicyActions();

interface IPolicyListProps extends IBaseListingComponentProps {
  products: IProductListState,
  item: IPolicyListState,
  initProduct: Function,
  changeProduct: (pair: IProduct) => void,
  exportCsv: (param: IRequestParam, fileName: string) => void,
  changeTab: (pair: IPair) => void,
  changeViewType: (pair: IPair) => void,
}

class PolicyList extends BaseListingContainer<IPolicyListProps, {}> {
  onViewTypeChange = async (pair: IPair) => {
    const { changeViewType } = this.props;
    await changeViewType(pair);
    this.setList();
  }

  onPolicyExport = (pii: boolean) => {
    const { item } = this.props;
    let fileName = '';
    if (item && item.product) {
      fileName = mediaLib.generateCsvFileName(true, pii, item.product.orgUnit);
    }
    this.onExport(pii, fileName);
  }

  getTabCollection = (item: IPolicyListState) => (item
    && item.collections && item.collections.status);

  render() {
    const { products, item } = this.props;
    let status = ''
    if(products.error || item.error){
      status = 'error'
    } else if(products.accessIsForbidden || item.accessIsForbidden){
      status = 'forbidden'
    } else if(products.isLoading || item.product === null) {
      status = 'loading'
    } else{
      status = 'ready'
    }

    if (products.isLoading || item.product === null) return (
      <>
        <Header
          title='Certificates'
          bannerGraphic={bannerImg}
          withBorderBottom
        />
      </>
    );

    return (
      <>
        <div className={status === 'error' ? 'd-block' : 'd-none'}><Message message={userMessages.ERROR} /></div>
        <div className={status === 'forbidden' ? 'd-block' : 'd-none'}><Message message={userMessages.ACCESS_DENIED} /></div>
        <div className={status === 'loading' ? 'd-block' : 'd-none'}><Loader /></div>
        <div data-testid="containter" className={status === 'ready' ? 'd-block' : 'd-none'}>
          {item.product &&
            <MultiProductHeader
            title='Certificates'
            bannerGraphic={bannerImg}
            collections={products.list || []}
            selectedProduct={item.product}
            handler={(product: IProduct) => this.onProductChange(product)}/>
          }
          <div data-testid="containter-inner" className='container-fluid px-0'>
            <Tab
              collections={this.getTabCollection(item)}
              active={item.tab}
              handler={(pair: IPair) => this.onChangeTab(pair)}
            />
            <div className='row d-flex justify-content-center my_30'>
              <Col sm='12' className='my-2 filter-viewType-container'>
                <Filter
                  isLoading={item.isLoading}
                  filterOptions={item.filters}
                  onCheckChange={
                    (newFilters: IListingFilter[]) => this.onFilterCheckChange(newFilters)
                  }
                  applyFilter={(newFilters: IListingFilter[]) => this.onApplyFilter(newFilters)}
                  cancelFilter={() => this.onCancelFilter(item.prevFilters)}
                />
                {item.tab.id === '' ? (
                  <ListViewType
                    collections={item.collections ? item.collections.viewType : []}
                    isFlat={item.viewTypeIsFlat}
                    onChange={(pair: IPair) => this.onViewTypeChange(pair)}
                  />
                ) : <></> }
              </Col>
              <div className='col-sm-12'>
                <Table
                  titles={item.fields}
                  records={item.list}
                  hasRecords={item.hasRecordsInitially}
                  image={bannerImg}
                  onRowClicked={(row: IObject) => this.onRowClicked(routes.policy.base, row.id)}
                  onRowButtonClicked={(row: IObject) => this.onRowClicked(routes.policy.base, row.id)}
                  handleSearchKeyword={(pair: IPair) => this.onSearchChange(pair)}
                  searchKeyword={item.searchKeyword}
                  handlePageClick={(pair: IPair) => this.onPageClick(pair)}
                  handlePageSizeChange={(pair: IPair) => this.onPageSizeChange(pair)}
                  pagination={item.pageInfo}
                  buttonIcon='file'
                >
                  <ExportButton
                    exportToCsv={(pii: boolean) => this.onPolicyExport(pii)}
                  />
                </Table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  products: state.productList,
  item: state.policyList,
});
const mapDispatchToProps = (dispatch: any) => ({
  initProduct: () => dispatch(policyActions.initProduct()),
  changeProduct: (product: IProduct) => dispatch(policyActions.setProduct(product)),
  setList: (params: IRequestParam) => dispatch(policyActions.setList(params)),
  getSearchParams: () => dispatch(policyActions.getSearchParams()),
  changeTab: (pair: IPair) => dispatch(policyActions.changeTab(pair)),
  changeSearchKey: (keyword: string) => dispatch(policyActions.changeSearchKey(keyword)),
  changePage: (value: number) => dispatch(policyActions.changePage(value)),
  changePageSize: (value: number) => dispatch(policyActions.changePageSize(value)),
  exportCsv: (params: IRequestParam, fileName: string) => (
    dispatch(policyActions.getCsvFile(params, fileName))),
  changeViewType: (pair: IPair) => (dispatch(policyActions.changeViewType(pair))),
  applyFilters: (filters: IListingFilter[]) => (
    dispatch(policyActions.applyFilters(filters))),
  changeFilters: (filters: IListingFilter[]) => (
    dispatch(policyActions.changeFilters(filters))),
  cancelFilters: (prevFilters: IListingFilter[]) => (
    dispatch(policyActions.cancelFilters(prevFilters))),
  resetFilters: () => dispatch(policyActions.resetFilters()),
  setHasRecordsInitially: () => dispatch(policyActions.setHasRecordsInitially()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PolicyList);
