export const Util = {
    decorateFormVariables: (data: any) => {
        let variables: any = {}
        Object.keys(data).map((item) => {
            variables[item] = {value: data[item]}
        })
        return variables;
    },
    populateVariablesToFormIO: (variables: any, formInfo: any) => {
        return new Promise((resolve, reject) => {
            if (formInfo.components) {
                formInfo.components.map((formItem: any, formItemIndex: number) => {
                    if (formItem.type === "columns") {
                        formItem.columns.map(async (item: any) => {
                            const data = await Util.populateVariablesToFormIO(variables, item)
                        })
                    } else {
                        variables.map((variableItem: any, variableItemIndex: number) => {
                            if (variableItem.type === "Object") {
                                const parsedValue = JSON.parse(variableItem.value)
                                try {
                                    //read value from bpmn diagram and populate dropdown element
                                    if (formItem.dataSrc === "custom") {
                                        if (formItem.valueProperty === variableItem.name) {
                                            formInfo.components[formItemIndex].data.values = variableItem.value;
                                            formInfo.components[formItemIndex].dataSrc = "values";
                                        }
                                    } else {
                                        Object.keys(parsedValue).map((parsedItemKey) => {
                                            if (parsedItemKey === formItem.key) {
                                                if (formItem.type === "htmlelement") {
                                                    formInfo.components[formItemIndex].content = parsedValue[parsedItemKey]?.toString()
                                                } else {
                                                    formInfo.components[formItemIndex].defaultValue = parsedValue[parsedItemKey]?.toString()
                                                }
                                            }
                                        })
                                    }
                                } catch (e) {
                                }
                            } else {
                                if (formItem.key === variableItem.name) {
                                    if (formItem.type === "htmlelement") {
                                        formInfo.components[formItemIndex].content = variableItem.value?.toString()
                                    } else {
                                        formInfo.components[formItemIndex].defaultValue = variableItem.value?.toString()
                                    }
                                }
                            }
                        })
                    }
                })
            }
            resolve(formInfo)
        })
    },
}