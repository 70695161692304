import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {FormBuilderApi} from '@containers/formBuilder/util/FormBuilderApi'
import {CamundaApi} from './util/CamundaApi'
import {CamundaApi as processModelerApi} from '@containers/processModeller/util/CamundaApi'
import {Util} from './util/util';
import {BPMNXmlParser} from '@containers/processModeller/util/BPMNXmllParser';
import {useDispatch} from "react-redux";
import NotificationAction from "../../@redux/actions/notification";

const notificationAction = new NotificationAction();

const TypeOfActivity = {
    "callActivity": "callActivity",
    "userTask": "userTask"
}
const StateOfProcess = {
    "ACTIVE": "ACTIVE",
    "COMPLETED": "COMPLETED",
}
export const ProcessModelExecutorLoadFormLogic = () => {
    const [formInfo, setFormInfo] = useState<any>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [taskId, setTaskId] = useState(undefined)
    const [processId, setProcessId] = useState<string>("");
    const [keepHistory, setKeepHistory] = useState<any>([])
    const [diagramOfTask, setDiagramOfTask] = useState<string | undefined>(undefined)
    let {taskKey} = useParams();
    const history = useHistory()
    const dispatch = useDispatch()
    const loadXmlOfTask = () => {
        processModelerApi().loadProcessXmlBykey(taskKey)
            .then(async (responseData) => {
                const parsedData = await responseData.json()
                setDiagramOfTask(parsedData?.bpmn20Xml)
            })
    }
    useEffect(() => {
        startProcess(taskKey)
    }, [])

    const startProcess = (key: string) => {
        CamundaApi().startProcess({key})
            .then(async (response) => {
                const data = await response.json()
                setProcessId(data.id);
            }).catch((error) => {
            setIsLoading(false)
            const {title, message} = error
            dispatch(notificationAction.error('error', title, message))
        })
    }
    const getHistoryOfProcess = (processId: string) => {
        CamundaApi()
            .getHistoryOfProcess(processId)
            .then(async (response) => {
                const parsedData = await response.json();
                const {state} = parsedData;
                console.log(state)
                switch (state) {
                    case StateOfProcess.ACTIVE:
                        getProcessActivityInstance(processId)
                        break;
                    case StateOfProcess.COMPLETED:
                        history.push("/process-model-executor")
                        break;
                }
            }).catch((error)=>{
            const {title, message} = error
            dispatch(notificationAction.error('error', title, message))
        })
    }

    useEffect(() => {
        if (processId) {
            getHistoryOfProcess(processId)
        }
    }, [processId])

    const checkProcessInstance = (processId: string, superProcessInstance: boolean | undefined) => {
        return CamundaApi().getProcessInstance(processId, superProcessInstance)
    }

    const getProcessActivityInstance = (processId: string) => {
        CamundaApi().getProcessActivity(processId)
            .then(async (response) => {
                const parsedData = await response.json();
                const typeOfActivity = parsedData.childActivityInstances[0].activityType;
                switch (typeOfActivity) {
                    case TypeOfActivity.callActivity:
                        checkProcessInstance(processId, true)
                            .then(async (processInstanceResponse) => {
                                const parsedProcessInstanceResponse = await processInstanceResponse.json();
                                getProcessActivityInstance(parsedProcessInstanceResponse[0].id)
                            })
                        break;
                    case TypeOfActivity.userTask:
                        getNextTask(processId)
                        break;
                }
            }).catch((error)=>{
            const {title, message} = error
            dispatch(notificationAction.error('error', title, message))
        })
    }

    const getNextTask = (processId: string) => {
        CamundaApi()
            .getTaskByProcessId(processId)
            .then(async (taskResponse) => {
                const taskResponseData = await taskResponse.json()
                if (taskResponseData && taskResponseData.length > 0) {
                    const {formKey, id} = taskResponseData[0];
                    setHistoryOfSteps(processId)
                    setTaskId(id)
                    const variablesInstance = await getVariableInstance(processId)
                    if (formKey) {
                        loadForm(formKey, variablesInstance)
                    }
                    setIsLoading(false)
                } else {
                    CamundaApi().getProcessActivity(processId)
                        .then(async (response) => {
                            const parsedData = await response.json()
                            const activityInfo = BPMNXmlParser(diagramOfTask).getActivityInfo(parsedData.childActivityInstances[0].activityId)
                            if (response) {
                                startProcess(activityInfo?.keyOfTask)
                            }
                        })
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                const {title, message} = error
                dispatch(notificationAction.error('error', title, message))
            })
    }

    const setHistoryOfSteps = (processId: string) => {
        CamundaApi().getProcessActivity(processId)
            .then(async (result) => {
                const parsedData = await result.json()
                const activityId = parsedData.childActivityInstances[0].activityId
                setKeepHistory([...keepHistory, {
                    activityId: activityId
                }])
            }).catch((error)=>{
            const {title, message} = error
            dispatch(notificationAction.error('error', title, message))
        })
    }

    const loadForm = (name: string, variablesInstance: any) => {
        setIsLoading(true)
        if (formInfo !== undefined) {
            setFormInfo(undefined)
        }
        FormBuilderApi()
            .loadForm(name)
            .then(async (response) => {
                const parsedFormResponse = await response.json()

                const formInfo = await Util.populateVariablesToFormIO(variablesInstance, parsedFormResponse);
                setIsLoading(false)
                setFormInfo(formInfo)
            })
            .catch((error) => {
                const {title, message} = error
                dispatch(notificationAction.error('error', title, message))
                setIsLoading(false)
            })
    }

    const onSubmit = (data: any) => {
        setIsLoading(true)
        const payload = {variables: Util.decorateFormVariables(data.state !== undefined && data.state === "submitted" ? data.data : data), withVariablesInReturn: true};
        CamundaApi()
            .doCompleteTask(taskId, payload)
            .then(async (response) => {
                const parsedResponseData = await response.json()
                if (response.status === 200) {
                    getHistoryOfProcess(processId)
                } else {
                    alert(parsedResponseData.message)
                    setIsLoading(false)
                }

            })
            .catch((error) => {
                const {title, message} = error
                dispatch(notificationAction.error('error', title, message))
                setIsLoading(false)
            })
    }

    const customEventHandler = (event: any) => {
        const {type, data} = event;
        switch (type) {
            case 'next':
                onSubmit(data)
                break;
            case "prev":
                prev()
                break;
            default:
                break;
        }
    }

    const prev = () => {
        return;
        CamundaApi().getProcessActivity(processId)
            .then(async (response) => {
                const parsedData = await response.json()
                if (keepHistory.length > 1) {
                    CamundaApi().doBackProcess(processId, keepHistory[keepHistory.length - 2].activityId, parsedData.childActivityInstances[0].id)
                        .then((response) => {
                            console.log("response", response)
                            // checkProcessInstance(processId);
                        })
                        .catch(() => {

                        })
                } else {
                    alert("back operation not permitted")
                }
            })
    }

    const getVariableInstance = (processId: string) => {
        return new Promise((resolve, reject) => {
            if (processId.length > 1) {
                CamundaApi()
                    .getVariableInstance(processId)
                    .then(async (response) => {
                        const parsedData = await response.json()
                        return resolve(parsedData)
                    }).catch((error)=>{
                    const {title, message} = error
                    dispatch(notificationAction.error('error', title, message))
                })
            } else {
                resolve([])
            }
        })

    }

    return {
        isLoading,
        formInfo,
        onSubmit,
        customEventHandler
    }
}