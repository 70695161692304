import React, {FC} from "react";
import {Col} from "reactstrap";

type RenderDetailProps={
    name:string,
    value:string
}
const RenderDetail: FC<RenderDetailProps> = ({name, value}) => {
    return (
        <>
            <Col className='pt-2 summary-label' sm={6}>{name}</Col>
            <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                {value}
            </Col>
        </>
    );
};
export default RenderDetail