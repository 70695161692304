import React from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';
import BaseComponent from '@xc-core/components/baseComponent';

interface IProps { properties: any, status: string }

export default class RemarksCard extends BaseComponent<IProps> {
  // constructor(props: any) {
  //   super(props);
  // }

  render() {
    const { properties } = this.props;
    if(!properties['Cancel Reason'] && !properties['Cancel Remarks'] && !properties['Endorse Remarks']) return null;
    return (
      <div className='sidebarCard'>
        <div>
          <Card className='mt-4 shadow-md border-0' style={{ borderRadius: '10px' }}>
            <CardBody className='px-3'>
              <div className='pt-2 text-primary'>
                <Row>
                  <Col sm={9} className='payment-title-qc font-size-normal text-secondary mt-2 mb-2'>
                   Remarks
                  </Col>
                </Row>
              </div>
              <hr className='my-2' />
              <div className='pt-2'>
                {(properties['Cancel Remarks'] || properties['Cancel Reason']) ?
                  <div>
                    <Row>
                      <Col sm={6}>Cancellation Reason</Col>
                      <Col className='text-right summary-label-3' sm={6}>
                        { properties['Cancel Reason'] }
                      </Col>
                    </Row>
                    <Row>
                      <Col className='pt-3' sm={6}>Remarks</Col>
                      <Col className='text-right py-3 summary-label-3' sm={6}>
                        { properties['Cancel Remarks'] }
                      </Col>
                    </Row>
                  </div> :
                    <Row>
                      <Col sm={6}>Reason</Col>
                      <Col className='text-right summary-label-3' sm={6}>
                        { properties['Endorse Remarks'] }
                      </Col>
                    </Row>
                }
                {/* { (status === 'Cancelled' && properties['Cancel Reason']) &&
                <Row>
                  <Col sm={6}>Cancellation Reason</Col>
                  <Col className='text-right summary-label-3' sm={6}>
                    { properties['Cancel Reason'] }
                  </Col>
                </Row>
                }
                { (status === 'In Effect' && properties['Endorse Remarks']) &&
                <Row>
                  <Col sm={6}>Reason</Col>
                  <Col className='text-right summary-label-3' sm={6}>
                    { properties['Endorse Remarks'] }
                  </Col>
                </Row>
                }
                { (status === 'Cancelled' && properties['Cancel Remarks']) &&
                <Row>
                  <Col className='pt-3' sm={6}>Remarks</Col>
                  <Col className='text-right py-3 summary-label-3' sm={6}>
                    { properties['Cancel Remarks'] }
                  </Col>
                </Row>
                } */}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
