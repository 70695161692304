import React from 'react';
import { Col } from 'reactstrap';
import Search from './search';
import Pagination from './pagination';
import Header from './header';
import Body from './body';

interface IProps {
  pagination: any,
  handlePageClick: Function,
  handlePageSizeChange: Function,
  titles: IPair[] | null,
  handleSearchKeyword: Function,
  searchKeyword: string,
  activeSearch?: boolean,
  records: any[],
  hasRecords: boolean,
  image: string,
  onRowClicked:(item: IObject) => void,
  onRowButtonClicked: (item: IObject) => void,
  children?: any,
  buttonIcon?: string,
  onDeleteRow?:(record: IObject)=>void
  onExportRow?:(record: IObject)=>void
}

const Table = (props: IProps) => {
  const {
    children,
    pagination,
    handlePageClick,
    handlePageSizeChange,
    titles,
    handleSearchKeyword,
    searchKeyword,
    activeSearch,
    records,
    hasRecords,
    image,
    onRowClicked,
    onRowButtonClicked,
    buttonIcon,
    onDeleteRow,
    onExportRow
  } = props;
  return (
    <>
      <div className='xc-table col-sm-12'>
        <div
          className={`card shadow-sm ${children ? 'additionalHeader' : ''}`}
          style={{ minHeight: 'auto' }}
        >
          <div className='card-header'>
            { activeSearch !== false &&
            <Search
              keyword={searchKeyword}
              onKeywordChange={handleSearchKeyword}
            >
              {children}
            </Search>
            }
            <div className='card-body p-0'>
              <table id='mainTable' className='table'>
                <Header fields={titles} />
                <Body
                  image={image}
                  titles={titles}
                  records={records}
                  hasRecords={hasRecords}
                  onRowClicked={(item: IObject) => onRowClicked(item)}
                  onRowButtonClicked={(item: IObject) => onRowButtonClicked(item)}
                  buttonIcon={buttonIcon}
                  onDeleteRow={onDeleteRow}
                  onExportRow={onExportRow}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
      { pagination &&
      <Col sm='11' className='mt-3'>
        <Pagination
          pagination={pagination}
          pageHandler={(pair: IPair) => handlePageClick(pair)}
          pageSizeHandler={(pair: IPair) => handlePageSizeChange(pair)}
        />
      </Col>
      }
    </>
  );
};
export default Table;
