import React,{FC} from "react";
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

type Props={

}
const Actions:FC<any> = (props) => {
    const {
        policy,
        downloadPolicy,
        reissuePolicy,
        toggleCancelModal,
        ableToDownload,
        ableToReissue,
        ableToCancel,
        toggleDropDown,
        dropdownOpen,
        ableToEndorse,
        toggleEndorseMode,
    } = props;
    return (
        <>
            <Dropdown className='mb-3 text-right' isOpen={dropdownOpen} toggle={toggleDropDown}>
                <DropdownToggle caret className='btn-action btn-action-primary'>
                    Actions
                </DropdownToggle>
                <DropdownMenu>
                    { ableToDownload(policy) ? <DropdownItem onClick={() => downloadPolicy(policy.id)}>Download Certificate</DropdownItem> : <></>}
                    { ableToDownload(policy) === false ? <DropdownItem onClick={() => downloadPolicy(policy.id)}>Download Cancellation Confirmation</DropdownItem> : <></>}
                    { ableToReissue(policy) ? <DropdownItem onClick={reissuePolicy}>Reissue Certificate</DropdownItem> : <></> }
                    { ableToEndorse(policy) ? <DropdownItem onClick={toggleEndorseMode}>Endorse Certificate</DropdownItem> : <></>}
                    { ableToCancel(policy) ? <DropdownItem className='text-danger' onClick={toggleCancelModal}>Cancel Certificate</DropdownItem> : <></>}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};
export default Actions