import React from 'react';
import {Route, Switch} from "react-router-dom";
import FormBuilder from './index'

export const FormBuilderStack=(props) =>{
    return (
        <Switch>
            <Route exact path='/form-builder/:formName?' component={FormBuilder} />
        </Switch>
    );
}
export default FormBuilderStack