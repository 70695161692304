/* eslint-disable react/require-default-props */
import React from 'react';
import { connect } from 'react-redux';
import { TitleComponent } from '_components';
import { quotationActions } from 'Products/shared/quotationListing/actions';
import Notifications from 'react-notification-system-redux';
import { showForbiddenMessage } from '_actions';
import { checkPermission } from '_services';

import { Row, Col, Button } from 'reactstrap';
import { isDatePassed } from 'utils';

import { sidebarModel } from 'models';
import { DetailTitle, SidebarCard } from '@xc-core/components';
import getQuoteGraphic from '../../images/bannerGraphicImages/get-quote.png';
import RenderInsuredDetails from './renderInsuredDetail';

// const RenderDetails = (props) => {
//   const { model, quotation } = props;
//   return (
//     <>
//       {
//         model ? model.items.map((item) => {
//           return <RenderDetail key={item.id} model={item} quotation={quotation} />;
//         }) : <></>
//       }
//     </>
//   );
// };
// const RenderDetail = (props) => {
//   const { quotation, model } = props;
//   const { lib: { resolveObjKey } } = utils;
//   return (
//     <>
//       <Col className='pt-2 summary-label' sm={6}>{model.name}</Col>
//       <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
//         { quotation ? resolveObjKey(quotation, model.id) : '' }
//       </Col>
//     </>
//   );
// };
// const RenderInsuredDetails = (props) => {
//   const { model, quotation } = props;
//   return (
//     <Row>
//       <Col sm={12}>
//         <h4 className='beneficiary-header my-3 p-3'>
//           {/* { `${main ? `${insuredType} 1 (Main Applicant)` : `${data.Type} ${index}`}`} */}
//           Adult 1 (Main Applicant)
//         </h4>
//         <Row className='wordBreak'>
//           <Col sm={6}>
//             <div className='custom-q-text-primary bold my-2'>Insured Person Details</div>
//             <Row>
//               <RenderDetails model={model.insured} quotation={quotation} />
//             </Row>
//           </Col>
//           <Col sm={6}>
//             <div className='custom-q-text-primary bold my-2'>Beneficiary Details</div>
//             <Row>
//               <RenderDetails model={model.beneficiary} quotation={quotation} />
//             </Row>
//           </Col>
//         </Row>
//       </Col>
//     </Row>
//   );
// };
// const RenderHeader = (props) => {
//   const { quotation, quotation: { common } } = props;
//   return (
//     <>
//       <h5 className='quowtationId text-medium-qc font-size-large'>{ `Quotation ID ${quotation.id}` }</h5>
//       <span>{ `Created by ${common.createdByName}, at ` }</span>
//       <br />
//       <span>{ `Updated by ${common.userUpdatedByName ? common.userUpdatedByName : ''}, at ` }</span>
//       {formatDate(common.userUpdatedOn, { withTime: true })}
//       <p className='m-0'>
//         {`Status: ${quotation.state === 'Completed' ? 'Certificate Issued' : 'Incomplete Quotation'}`}
//       </p>
//     </>
//   );
// };
const RenderBody = (props) => {
  const { model, quotation } = props;
  return (
    <>
      <RenderInsuredDetails
        model={{ insured: model.items.main, beneficiary: model.items.beneficiary }}
        item={quotation}
      />
      { /** rendering other insured */ }
      {/* {
        otherModel.items.map((item))
      } */}
    </>
  );
};
const EditButton = (props) => {
  const { quotation, editQuotation } = props;
  return (
    <div className='mb-3 text-right'>
      <Button
        className={quotation.state === 'Completed' ? 'customPrimarybtn d-none' : 'customPrimarybtn btn-primary'}
        onClick={() => editQuotation(quotation)}
      >
        Edit Quotation
      </Button>
    </div>
  );
};
export class QuotationDetail extends React.PureComponent {
  componentWillMount() {
    const backPath = this.props.history.location.pathname.split('/');
    this.setState({ backPath: [...backPath.slice(0, 2)].join('/') });
  }

  componentWillReceiveProps(newProps) {
    const { notification, notificationType } = newProps;
    if (notification) {
      this.props.notify(notification, notificationType);
    }
  }

  componentWillUnmount() {
    this.props.clearQuotationDetail();
  }

  editQuotation = async (quotation) => {
    const startDate = quotation.properties['Start Date'];
    if (isDatePassed(startDate)) {
      this.props.showForbiddenMessage('This quotation is outdated and cannot be edited.');
    } else {
      const { status } = await checkPermission('Edit Quotation');
      if (status === 'permitted') {
        const urlData = {
          pathname: `/editQuotation/${quotation.productCode}/${quotation.id}`,
          state: 'reset',
        };
        this.props.history.push(urlData);
      } else this.props.showForbiddenMessage();
    }
  }

  render() {
    const { model, quotation } = this.props;
    return (
      <div className='container-fluid px-0'>
        <TitleComponent
          title='Quotation Details'
          bannerGraphic={getQuoteGraphic}
          backTitle='Back to listing'
          backPath={this.state.backPath}
          history={this.props.history}
        />
        <div className='row d-flex justify-content-center my_50' id='quotation-detail'>
          <Col sm={11} className='quotation-detail-wrapper'>
            <Row>
              <Col sm={8}>
                <DetailTitle.Quotation quotation={quotation} />
                <RenderBody quotation={quotation} model={model.body} />
              </Col>
              <Col sm={4}>
                <EditButton quotation={quotation} editQuotation={this.editQuotation} />
                <SidebarCard.Quotation
                  item={quotation}
                  model={sidebarModel.quotation.travelCB}
                />
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    quotation,
    notification,
    notificationType,
  } = state.quotationManagement;

  return {
    quotation,
    notification,
    notificationType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetail: (quotationId) => dispatch(quotationActions.getDetail(quotationId)),
    notify: (message, type) => dispatch(Notifications.show({ message }, type)),
    showForbiddenMessage: (message) => dispatch(showForbiddenMessage(message)),
    clearQuotationDetail: () => dispatch(quotationActions.clearQuotationDetail()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetail);
