import { BaseComponent } from '@xc-core/components';
import { objLib, regLib, numberLib } from '@xc-core/lib';
import { QuotationScriptValidator } from '@xc-core/validation';

// import { userMessages } from '@xc-core/constants';
// import { Header, Message, Loader } from '@xc-core/components/core';
// import Validator from '@xc-core/validation';
// import bannerImg from '../../../images/bannerGraphicImages/get-quote.png';
// import Stepper from './stepper';
// import Footer from './footer';
// import { ComponentLoader } from '../loadableComponents';


abstract class BaseWizard<P extends IBaseWizardProps,
  S extends IBaseWizardState> extends BaseComponent<P, S> {
  protected abstract defaultCountry: string;

  protected abstract stateListParam: string;

  protected abstract model: IObject;

  protected abstract prepareSteps: (steps: IStep[]) => IStep[];

  protected abstract getStepMetadata: (order: number) => { componentType: string,
    formContainer: IFormContainer };

  protected scriptValidator: IScriptValidator;

  constructor(props: P) {
    super(props);
    this.scriptValidator = new QuotationScriptValidator();
  }

  componentDidMount = async () => {
    const {
      getQuote: { product, item },
      init,
      setSteps,
      history,
    } = this.props;
    if (product) {
      /* TO-D0: check for payment gateway flag instead of the product id */
      if (item.state === 'Completed' && product.id !== 2339002 && product.id !== 619031) {
        history.push('/getQuote');
      } else if (this.isEditMode()) {
        this.setStateInCollections();
      } else {
        init(product.id, this.defaultCountry, this.stateListParam);
      }

      const steps = this.prepareSteps(product.steps);
      setSteps(steps);
      let currentItemState = '';
      if (item.state === '') currentItemState = steps[0].name;
      this.setState((prevState: IBaseWizardState) => ({
        ...prevState,
        item: {
          ...item,
          state: currentItemState,
        },
      }));
    }
  }

  componentWillUnmount = () => {
    const { reset } = this.props;
    reset();
  }

  updateStateItem = (newItem: IObject): void => {
    this.setState((prevState) => ({
      ...prevState,
      item: {
        ...newItem,
      },
    }));
  }

  setStateInCollections = () => {
    const { setStateInCollections, getQuote: { item } } = this.props;
    const country = objLib.getValueWithLodash(item, this.model.fieldsModel.country);
    setStateInCollections(country || this.defaultCountry, this.stateListParam);
  }

  next = async () => {
    const { getQuote: { item, product }, getItemOnNext } = this.props;

    if (product && item) {
      this.setState({ sendingRequest: true });
      const properties = { ...item.properties, ...this.state.item.properties };
      const validationErrors = this.scriptValidator.validate(this.state.item, item.state || this.state.item.state);
      if (validationErrors === null) {
        this.setState({ invalidForm: true, sendingRequest: false });
        return;
      }
      this.setState((prevState: IBaseWizardState) => ({ ...prevState, validationErrors }));
      if (validationErrors.length === 0) {
        const updatedItem = await getItemOnNext(product.id, item.id, properties);
        this.handlePaymentGatewayStep(updatedItem)
        this.updateStateItem(updatedItem);
        this.setState({ invalidForm: false, sendingRequest: false });
      } else {
        this.setState({ invalidForm: true, sendingRequest: false });
      }
    }
  }

  previous = async () => {
    const { getQuote: { item, product }, getItemOnPrevious } = this.props;
    if (product && item) {
      this.resetErrorsAndFooterOnPrev();
      const updatedItem = await getItemOnPrevious(product.id, item.id);
      this.updateStateItem(updatedItem);
    }
  }

  skip = async () => {
    const { getQuote: { item, product }, getItemOnSkip } = this.props;
    if (product && item) {
      const properties = { ...item.properties, ...this.state.item.properties };
      const updatedItem = await getItemOnSkip(product.id, item.id, properties);
      this.handlePaymentGatewayStep(updatedItem)
      this.updateStateItem(updatedItem);
      this.setState({ invalidForm: false, sendingRequest: false });
    }
  }

  protected handlePaymentGatewayStep = (item:IObject) =>{
    if(item.state === 'Payment Details' && item.properties.usePaymentGateway){
      this.setState((prevState: IBaseWizardState) => ({
        ...prevState,
        redirectToGateway: true
      }));
    }
  }

  protected onChange = async (handledValue: IHandledValue) => {
    if (this.state.item.state === "Beneficiaries") {
      this.resetErrorsAndFooterOnPrev()
    } else {
      await this.updateValidationErrorsOnChange(handledValue.pairs);
    }
    this.tryResetDisabledFooter();
    this.setValues(handledValue.pairs);
  }

  protected setValues = (pairs: IPair[]): void => {
    pairs.forEach((pair: IPair) => {
      this.setState((prevState: IBaseWizardState) => ({
        ...prevState,
        item: {
          ...prevState.item,
          ...objLib.setValueWithLodash(prevState.item, pair.id, pair.value),
        },
      }));
    });
  }

  protected getCurrentStep = (): IStep => {
    const { getQuote: { item, steps } } = this.props;
    if (item.state === '') {
      return steps[0];
    }
    const current = steps.find((step: IStep) => step.name === item.state);
    return current || steps[0];
  }

  protected downloadCertificate= (): void => {
    const { downloadCertificate, getQuote: { item } } = this.props;
    downloadCertificate(item.policyRefCode);
  }

  protected getAnotherQuote = async (): Promise<void> => {
    const { history } = this.props;
    history.push('/getQuote');
  }

  protected defaultFieldHandler = (pair: IPair): IPair[] => ([pair]);

  protected constants = {
    genders: { FEMALE: 'Female', MALE: 'Male' },
  };

  protected defaultFieldDisabled = (): boolean => false;

  protected defaultFieldStyle = { col: '6' };

  protected handleFullName = (pair: IPair, item: IObject): IPair[] => {
    const preValue = objLib.getValueWithLodash(item, pair.id);
    const value = pair.value === '' || regLib.fullNameCertainChars(pair.value) ? pair.value : preValue;
    return [{ id: pair.id, value }];
  }

  protected handleNumber = (pair: IPair, item: IObject): IPair[] => {
    const preValue = objLib.getValueWithLodash(item, pair.id);
    const value = this.restrictToNumber(pair.value, preValue);
    return [{ id: pair.id, value }];
  }

  protected handleAlphanumeric = (pair: IPair, item: IObject): IPair[] => {
    const preValue = objLib.getValueWithLodash(item, pair.id);
    const value = this.restrictToAlphanumeric(pair.value, preValue);
    return [{ id: pair.id, value }];
  }

  protected restrictToNumber = (current: string, pre: string): string => {
    const value = current === '' || regLib.numberOnly(current) ? current : pre;
    return value;
  };

  protected restrictToAlphanumeric = (current: string, pre: string): string => {
    const value = current === '' || regLib.alphanumeric(current) ? current : pre;
    return value;
  };

  protected getGenderFromValue = (value: string): string => (
    numberLib.isEven(value) ? this.constants.genders.FEMALE : this.constants.genders.MALE);

  private updateValidationErrorsOnChange = (pairs: IPair[]) => {
    const { validationErrors } = this.state;
    let errors = validationErrors;
    pairs.forEach((pair: IPair) => {
      errors = this.scriptValidator.removeFromErrors(errors, Array.isArray(pair.id) ? pair.id.join('.') : pair.id);
    });
    this.setState({ validationErrors: errors });
  }

  private resetErrorsAndFooterOnPrev = () => {
    this.setState({ validationErrors: [] }, () => this.tryResetDisabledFooter());
  };

  private tryResetDisabledFooter = () => {
    const { validationErrors } = this.state;
    if (validationErrors && validationErrors.length === 0) {
      this.setState({ sendingRequest: false, invalidForm: false });
    }
  };

  protected removeFromStateItemById = async (id: string) => {
    const { item } = this.state;
    const properties = objLib.deleteKeyWithLodash(item, id);
    return this.setState({ ...item, properties });
  }

  private isEditMode = () => {
    const { history: { location: { pathname } } } = this.props;
    return pathname.includes('editQuotation');
  }
}

export default BaseWizard;
