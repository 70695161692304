
const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    total: { id: 'properties.Price.Total', name: 'Total Price', config: {} },
    config: {},
  },
  body: {
    items: [
      {
        id: 0,
        title: { id: 'Price Breakdown', name: 'Price Breakdown' },
        items: [
          { id: 'properties.Price.Main Policy', name: 'Gross Premium', config: { currency: true } },
          { id: 'properties.Price.Admin Fee', name: 'Admin Fee', config: { currency: true } },
          { id: 'properties.Price.Tax', name: 'Tax', config: { currency: true } },
        ],
        config: {},
      },
      {
        id: 1,
        title: { id: 'Commission Breakdown', name: 'Commission Breakdown' },
        items: [
          { id: 'properties.Commission Amount', name: 'Commission Amount', config: { currency: true } },
        ],
        config: {},
      },
      {
        id: 2,
        title: { id: 'Other Details', name: 'Other Details' },
        items: [
          { id: 'properties.Plan', name: 'Plan', config: {} },
          { id: 'properties.Individual Occupation', name: 'Individual Occupation', config: {} },
          { id: 'properties.Spouse Occupation', name: 'Spouse Occupation', config: {} },
        ],
        config: {},
      },
      {
        id: 3,
        title: { id: 'Payment Details', name: 'Payment Details' },
        items: [
          { id: 'properties.Mode of Payment', name: 'Mode of Payment', config: {} },
          { id: 'properties.Receipt No', name: 'Receipt No', config: {} },
          { id: 'properties.Payment Received By', name: 'Payment Received By', config: {} },
        ],
        config: {},
      },
    ],
  },
};

export default model;
